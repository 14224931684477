import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetDocumentQuery } from 'common/api/backend/document-api';
import CommonOutlet from './common-outlet';

const DocumentOutlet = (errorMessage) => {
  const { projectSysId, documentSysId } = useParams();

  const { isSuccess, isLoading, isError } = useGetDocumentQuery({ projectSysId, documentSysId });

  return <CommonOutlet {...{ isSuccess, isLoading, isError, errorMessage }} />;
};

export default DocumentOutlet;
