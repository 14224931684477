export default {
  ISOLATE_ITEMS: 'ISOLATE_ITEMS',
  EMPTY_ITEMS: 'EMPTY_ITEMS',
  SET_CUT_PLANES: 'SET_CUT_PLANES',
  EMPTY_CUT_PLANES: 'EMPTY_CUT_PLANES',
  SET_VIEWER_LOADING: 'SET_VIEWER_LOADING',
  TOGGLE_GHOSTING: 'TOGGLE_GHOSTING',
  TOGGLE_REVERSE_ZOOM: 'TOGGLE_REVERSE_ZOOM',
  SET_SELECTED_ITEMS: 'SET_SELECTED_ITEMS',
  SET_SELECTED_ITEMS_FROM_TREE: 'SET_SELECTED_ITEMS_FROM_TREE',
  RESET_SELECTED_ITEMS: 'RESET_SELECTED_ITEMS',
  SET_SELECTED_IDS_BY_MODEL: 'SET_SELECTED_IDS_BY_MODEL',
  SET_HIDDEN_IDS_BY_MODEL: 'SET_HIDDEN_IDS_BY_MODEL',
  RESET_HIDDEN_IDS_BY_MODEL: 'RESET_HIDDEN_IDS_BY_MODEL',
  SET_BIM_ELEMENT_DATA: 'SET_BIM_ELEMENT_DATA',
  RESET_BIM_ELEMENT_DATA: 'RESET_BIM_ELEMENT_DATA',
  ADD_ARTICLE: 'ADD_ARTICLE',
};
