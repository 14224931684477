import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom/';
import configureAppStore from 'common/store';
import Loader from 'components/loader';
import { ThemeProvider } from 'styled-components';
import '@datasoluce/ui-design-system-ts/lib/public-assets/style.css';
import theme from 'theme';
import Snackbar from 'components/snackbar';
import { PersistGate } from 'redux-persist/integration/react';
import GlobalStyle from './global-styles';
import router from './routes/routes';

const { store, persistor } = configureAppStore();

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Suspense fallback={<Loader />}>
            <RouterProvider router={router}>
              <Snackbar />
            </RouterProvider>
          </Suspense>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
