import { BE_TYPE, backendServiceApi } from 'common/api/backend-service-api';
import { dispatchMessageConfiguration, mapSysIdAsTags } from 'common/api/utils';
import {
  deleteFolderFromDocumentFailedMessage,
  linkSingleDocumentToFolderFailedMessage,
  linkManyDocumentToFolderFailedMessage,
  createFolderSuccessMessage,
  createFolderDuplicateFailedMessage,
  createFolderFailedMessage,
  deleteFolderFailedMessage,
  updateFolderDuplicateFailedMessage,
  updateFolderFailedMessage,
  fetchFoldersFailedMessage,
  getFolderFailedMessage,
} from 'common/message/error-messages';
import {
  deleteFolderFromDocumentSuccessMessage,
  addFolderToDocumentsSuccessMessage,
  deleteFolderFromDocumentsSuccessMessage,
} from 'common/message/success-messages';

const folderApi = backendServiceApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getFolders: build.query({
      query: ({ projectSysId, from, size, search, order, sort, folderSysIds }) => {
        return {
          url: `/project/${projectSysId}/folders`,
          method: 'POST',
          body: {
            from,
            size,
            ...(search ? { search } : {}),
            order,
            sort,
            folderSysIds,
          },
        };
      },
      transformResponse: (response, meta, arg) => {
        return {
          ...response,
          offset: arg.from,
        };
      },
      ...dispatchMessageConfiguration({
        error: () => ({
          message: fetchFoldersFailedMessage,
        }),
      }),
      providesTags: (response) => mapSysIdAsTags(response?.folders, BE_TYPE.FOLDER),
    }),
    getFolder: build.query({
      query: ({ projectSysId, folderSysId }) => {
        return {
          url: `/project/${projectSysId}/folder/${folderSysId}`,
          method: 'GET',
        };
      },
      ...dispatchMessageConfiguration({
        error: () => ({
          message: getFolderFailedMessage,
        }),
      }),
      providesTags: (response) => [{ id: response?.sysId, type: BE_TYPE.FOLDER }],
    }),
    // MUTATION
    createFolder: build.mutation({
      query: ({ projectSysId, name }) => {
        return {
          url: `/project/${projectSysId}/folder`,
          method: 'POST',
          body: name,
        };
      },
      ...dispatchMessageConfiguration({
        success: ({ organizationSysId, projectSysId, res }) => ({
          message: createFolderSuccessMessage(res.name),
          option: {
            action: {
              label: 'Accéder au dossier',
              path: `/organization/${organizationSysId}/project/${projectSysId}/folder/${res.sysId}`,
            },
          },
        }),
        error: ({ err, name }) => ({
          message:
            err.error?.data === 'This folder name already exist'
              ? createFolderDuplicateFailedMessage(name.name)
              : createFolderFailedMessage,
        }),
      }),
      invalidatesTags: [{ id: 'LIST', type: BE_TYPE.FOLDER }],
    }),
    updateFolder: build.mutation({
      query: ({ projectSysId, folderSysId, name }) => {
        return {
          url: `/project/${projectSysId}/folder/${folderSysId}`,
          method: 'PATCH',
          body: { name },
        };
      },
      ...dispatchMessageConfiguration({
        error: ({ err, name }) => ({
          message:
            err.error?.data === 'This folder name already exist'
              ? updateFolderDuplicateFailedMessage(name)
              : updateFolderFailedMessage,
        }),
      }),
      invalidatesTags: (response, meta) => (meta === undefined ? [{ type: BE_TYPE.FOLDER, id: 'LIST' }] : []),
    }),
    deleteFolder: build.mutation({
      query: ({ projectSysId, folderSysId }) => {
        return {
          url: `/project/${projectSysId}/folder/${folderSysId}`,
          method: 'DELETE',
        };
      },
      ...dispatchMessageConfiguration({
        error: () => ({
          message: deleteFolderFailedMessage,
        }),
      }),
      invalidatesTags: [{ id: 'LIST', type: BE_TYPE.FOLDER }],
    }),
    addFolderToDocs: build.mutation({
      query: ({ projectSysId, folder, documents }) => {
        const data = documents.map(({ sysId }) => sysId);
        return {
          url: `/project/${projectSysId}/folder/${folder.sysId}/documents`,
          method: 'POST',
          body: { sysId: data },
        };
      },
      ...dispatchMessageConfiguration({
        success: ({ folder }) => ({
          message: addFolderToDocumentsSuccessMessage(folder.name),
          option: { action: { label: `Fermer` } },
        }),
        error: ({ folder, documents }) => ({
          message:
            documents.length > 1
              ? linkManyDocumentToFolderFailedMessage(folder.name)
              : linkSingleDocumentToFolderFailedMessage(documents[0].title, folder.name),
        }),
      }),
      invalidatesTags: (response, meta, arg) => [...mapSysIdAsTags(arg.documents, BE_TYPE.DOCUMENT)],
    }),
    deleteFolderFromDocs: build.mutation({
      query: ({ projectSysId, folder, documents }) => {
        const data = documents.map(({ sysId }) => sysId);
        return {
          url: `/project/${projectSysId}/folder/${folder.sysId}/documents`,
          method: 'DELETE',
          body: { sysId: data },
        };
      },
      ...dispatchMessageConfiguration({
        error: ({ folder }) => ({ message: deleteFolderFromDocumentFailedMessage(folder.name) }),
        success: ({ alert, folder, documents }) => ({
          message: alert
            ? deleteFolderFromDocumentSuccessMessage(documents[0].title, folder.name)
            : deleteFolderFromDocumentsSuccessMessage(folder.name),
          option: { action: { label: `Fermer` } },
        }),
      }),
      invalidatesTags: (response, meta, arg) =>
        arg.alert
          ? [...mapSysIdAsTags(arg.documents, BE_TYPE.DOCUMENT), { type: BE_TYPE.FOLDER, id: arg.folder.sysId }]
          : [...mapSysIdAsTags(arg.documents, BE_TYPE.DOCUMENT)],
    }),
  }),
});

export const {
  useGetFoldersQuery,
  useGetFolderQuery,
  useCreateFolderMutation,
  useUpdateFolderMutation,
  useDeleteFolderMutation,
  useAddFolderToDocsMutation,
  useDeleteFolderFromDocsMutation,
} = folderApi;

export default folderApi;
