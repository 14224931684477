import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import qs from 'qs';

export const BE_TYPE = {
  // DOCUMENT
  DOCUMENT: 'Document',
  REVISION: 'Revision',
  FOLDER: 'Folder',
  EVENT: 'Event',
  // METADATA
  METADATA: 'Metadata',
  // GROUP
  GROUP: 'Group',
  // ORGANIZATION
  ORGANIZATION_USERS: 'OrganizationUsers',
  // PROJECT
  PROJECT: 'Project',
  PROJECT_CONFIG: 'ProjectConfig',
  PROJECT_USERS: 'ProjectUsers',
  PROJECT_CONTRIBUTORS: 'ProjectContributors',
  PROJECT_ARTICLE_EXTRACTIONS: 'ProjectArticleExtractions',
  // CURRENT USER
  CURRENT_USER: 'CurrentUser',
};

// FIXME [f_laf] Restore RTKQ 1.x behavior on tag invalidation, impact ged refresh on batch upload
export const backendServiceApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }),
  reducerPath: 'backendServiceApi',
  tagTypes: Object.keys(BE_TYPE).map((key) => BE_TYPE[key]),
  invalidationBehavior: 'immediate',
  endpoints: () => ({}),
});

export default backendServiceApi;
