import { Layout } from '@datasoluce/ui-design-system-ts';
import useUserPreferences from 'hooks/use-user-preferences';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Navigation from './navigation';

const LayoutComponent = ({ navEntries, headerTitleSlot, headerRightSlot, entryPath }) => {
  const { menuExpanded, toggleSideMenuMutation } = useUserPreferences();

  return (
    <Layout
      menuExpanded={menuExpanded}
      menuSlot={<Navigation entries={navEntries} />}
      headerSlot={
        <Header
          expanded={menuExpanded}
          menuTooltip={menuExpanded ? 'Cacher le menu' : 'Déployer le menu'}
          onExpand={() => toggleSideMenuMutation()}
          titleSlot={headerTitleSlot}
          rightSlot={headerRightSlot}
        />
      }>
      <Outlet context={{ entryPath }} />
    </Layout>
  );
};

LayoutComponent.propTypes = {
  navEntries: PropTypes.array.isRequired,
  headerTitleSlot: PropTypes.any,
  headerRightSlot: PropTypes.any,
  entryPath: PropTypes.string,
};

LayoutComponent.defaultProps = {
  headerTitleSlot: '',
  headerRightSlot: undefined,
  entryPath: undefined,
};

export default LayoutComponent;
