import styled from 'styled-components';
import { spinAnimation } from 'utils/common/animation-utils';
import { VALID_COLOR, DEFAULT_COLOR, ERROR_COLOR } from 'utils/common/style-utils';
import { NextButton } from '@datasoluce/ui-design-system-ts';

const Container = styled.div`
  box-sizing: border-box;
  line-height: ${({ lineHeight }) => lineHeight};
  width: ${({ fullWidth, fitContent }) => (fullWidth ? '100%' : fitContent || '256px')};
  height: ${({ height }) => height};
  display: inline-block;
  position: relative;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fontFamily};
  transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  padding-right: ${({ paddingRight }) => paddingRight || '25px'};
  margin-bottom: ${({ theme, readOnly }) => (readOnly ? theme.margin.xl : theme.margin.s)};
`;

const TextContainer = styled(Container)`
  cursor: ${({ disabled, readOnly }) => (disabled || readOnly ? 'default' : 'text')};
`;

const Underline = styled.hr`
  display: block;
  border-style: inset;
  border-width: 1px;
  unicode-bidi: isolate;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  overflow: hidden;
`;

const GrayUnderline = styled(Underline)`
  border-top: none #e0e0e0;
  border-left: none #e0e0e0;
  border-right: none #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  bottom: 8px;
  box-sizing: content-box;
  margin: 0;
  position: absolute;
  width: 100%;
`;

const ColoredUnderline = styled(Underline)`
  border-top: none ${({ error, theme }) => (error ? theme.colors.darkRed : DEFAULT_COLOR)};
  border-left: none ${({ error, theme }) => (error ? theme.colors.darkRed : DEFAULT_COLOR)};
  border-right: none ${({ error, theme }) => (error ? theme.colors.darkRed : DEFAULT_COLOR)};
  border-bottom: 2px solid ${({ error, theme }) => (error ? theme.colors.darkRed : DEFAULT_COLOR)};
  bottom: 8px;
  box-sizing: content-box;
  margin: 0;
  position: absolute;
  width: 100%;
  transform: scaleX(${({ focus, error }) => (focus || error ? 1 : 0)});
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`;

const getColor = ({ focus, error, valid }, defaultColor) => {
  if (error) {
    return ERROR_COLOR;
  }
  if (focus) {
    return DEFAULT_COLOR;
  }
  if (valid) {
    return VALID_COLOR;
  }
  return defaultColor;
};

const ColoredBox = styled.div`
  height: 100px;
  background: none;
  border: 0;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px #979797;
  color: #979797;
  font-size: inherit;
  font-weight: 700;
  position: relative;
  vertical-align: middle;
  transition: color 0.25s;
  border-radius: 20px 20px 0 20px;

  &::before,
  &::after {
    box-sizing: inherit;
    content: '';
    position: absolute;
    border: 2px solid transparent;
    border-radius: 20px 20px 0 20px;
    width: ${({ focus, error, valid }) => (focus || error || valid ? '100%' : 0)};
    height: ${({ focus, error, valid }) => (focus || error || valid ? '100%' : 0)};
    color: ${(props) => getColor(props, 'transparent')};
  }

  &::before {
    bottom: 0;
    right: 0;
    border-top-color: ${(props) => getColor(props, 'transparent')};
    border-right-color: ${(props) => getColor(props, 'transparent')};
    transition: width 0.15s ease-out 0.15s, height 0.15s ease-out;
  }

  &::after {
    bottom: 0;
    right: 0;
    border-bottom-color: ${(props) => getColor(props, 'transparent')};
    border-left-color: ${(props) => getColor(props, 'transparent')};
    transition: height 0.15s ease-out 0.15s, width 0.15s ease-out;
  }
`;

const getTransformAndColor = ({ focus, filled, error, disabled, color, theme }) => {
  const activeColor = color || DEFAULT_COLOR;

  if (disabled) {
    return 'color: #b7b7b7;';
  }
  if (focus) {
    return `
      transform: scale(0.80) translate(0px, -28px);
      color: ${error ? ERROR_COLOR : activeColor};
    `;
  }
  if (filled) {
    return `
      transform: scale(0.80) translate(0px, -28px);
      color: ${theme.color.filledGrey}; 
    `;
  }
  return `
    transform: scale(1) translate(0px, 0px);
    color: ${color || '#707070'};
  `;
};

const Label = styled.div`
  position: absolute;
  font-size: 16px;
  line-height: 22px;
  min-height: 22px;
  top: ${({ top }) => top || '38px'};
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  transform-origin: left top 0;
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => getTransformAndColor(props)};
`;

const ErrorMsg = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  bottom: 15px;
  font-size: 12px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.darkRed};
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`;

const Loader = styled.div`
  border: 3px solid transparent;
  border-radius: 50%;
  border-top: 5px solid #ffffff;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  animation: ${spinAnimation} 1s linear infinite;
`;

const LoginErrorMsg = styled.div`
  font-size: ${({ theme }) => theme.fontSize.lg};
  line-height: ${({ theme }) => theme.fontSize.lg};
  color: ${({ theme }) => theme.colors.red};
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
`;

const LoginWrapper = styled.div`
  display: flex;
  flex: 1 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xl};

  > svg:first-child {
    width: 170px;
    height: 111px;
  }
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
`;

const LoginButton = styled(NextButton)`
  margin-top: 20px;
  width: 210px;
`;

const LoginLink = styled.div`
  color: ${({ theme }) => theme.color.grey};

  a:hover {
    color: ${({ theme }) => theme.color.filledGrey};
  }
`;

export {
  ColoredBox,
  Container,
  TextContainer,
  GrayUnderline,
  ColoredUnderline,
  Label,
  ErrorMsg,
  Loader,
  LoginErrorMsg,
  LoginWrapper,
  LoginForm,
  LoginButton,
  LoginLink,
};
