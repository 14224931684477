import { formatTree } from 'utils/extract/tree';
import actionTypes from './actionTypes';

export const defaultState = {
  trees: {},
  currentView: '',
  views: [],
  selectedTreeNodeId: null,
  selectedNodes: {},
  forgeIdMapping: {},
  elevations: [],
  configs: {},
};

function treeReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.INIT_TREES: {
      const { selectedNodes, forgeIdMapping, trees } = action;
      return {
        ...state,
        trees,
        selectedNodes,
        forgeIdMapping,
      };
    }
    case actionTypes.SET_VIEWS:
      return { ...state, views: action.views, configs: action.configs };
    case actionTypes.SET_ELEVATIONS:
      return { ...state, elevations: action.elevations };
    case actionTypes.SET_TREE: {
      const { view, parentID, result, selectedItems } = action;
      const { trees, forgeIdMapping } = state;

      const { treeView, newForgeToNode } = formatTree(
        parentID,
        result,
        { ...trees[view] },
        { ...forgeIdMapping[view] },
        { ...selectedItems }
      );

      return {
        ...state,
        trees: {
          ...state.trees,
          [view]: {
            ...state.trees[view],
            ...treeView,
          },
        },
        forgeIdMapping: {
          ...state.forgeIdMapping,
          [view]: { ...state.forgeIdMapping[view], ...newForgeToNode },
        },
      };
    }
    case actionTypes.UPDATE_TREE_NODE: {
      const { id, view, folded } = action;
      return {
        ...state,
        trees: {
          ...state.trees,
          [view]: {
            ...state.trees[view],
            [id]: {
              ...state.trees[view][id],
              folded,
            },
          },
        },
      };
    }
    case actionTypes.UPDATE_ISOLATED_TREE_NODE: {
      const { id, isolated } = action;
      const updatedTree = {
        ...state.trees[state.currentView],
        [id]: {
          ...state.trees[state.currentView][id],
          isolated,
        },
      };
      return {
        ...state,
        trees: {
          ...state.trees,
          [state.currentView]: updatedTree,
        },
      };
    }
    case actionTypes.TOGGLE_SELECT_TREE_NODE: {
      const { ids, selected, selectedNodes } = action;
      const { currentView, trees, selectedNodes: oldSelectedNodes } = state;
      const newTrees = { ...trees };
      ids.forEach((id) => {
        newTrees[currentView] = {
          ...newTrees[currentView],
          [id]: { ...newTrees[currentView][id], selected },
        };
      });
      const newSelectedNodes = { ...oldSelectedNodes, [currentView]: selectedNodes };

      return { ...state, trees: newTrees, selectedNodes: newSelectedNodes };
    }
    case actionTypes.UNSELECT_ALL: {
      const { currentView, selectedNodes, trees } = state;
      if (!currentView) {
        return state;
      }

      const unselectedTree = { ...trees[currentView] };
      selectedNodes[currentView].forEach((nodeSysId) => {
        unselectedTree[nodeSysId] = { ...unselectedTree[nodeSysId], selected: false };
      });

      return {
        ...state,
        selectedNodes: { ...selectedNodes, [currentView]: [] },
        trees: { ...trees, [currentView]: unselectedTree },
      };
    }
    case actionTypes.SET_COLOR: {
      const { currentView, trees } = state;
      const { id, color } = action;

      const updatedTree = {
        ...trees[currentView],
        [id]: { ...trees[currentView][id], color: { ...color } },
      };

      return { ...state, trees: { ...trees, [currentView]: updatedTree } };
    }
    case actionTypes.SET_COLOR_PROPAGATE: {
      const { view, tree } = action;
      return { ...state, trees: { ...state.trees, [view]: { ...tree } } };
    }
    case actionTypes.SET_CURRENT_VIEW:
      return { ...state, currentView: action.view };
    case actionTypes.SET_SELECTED_NODE_ID:
      return { ...state, selectedTreeNodeId: action.nodeSysId };
    default:
      return state;
  }
}

export default treeReducer;
