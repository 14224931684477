import React from 'react';
import { BasicMenu } from '@datasoluce/ui-design-system-ts';
import PropTypes from 'prop-types';

const MultiActionButton = ({
  actions,
  disabled,
  icon,
  variant,
  size,
  title,
  onRowClick = () => {},
  alignment = 'bottom-left',
}) => {
  const alignments = {
    'bottom-right': {
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      transformOrigin: { horizontal: 'right', vertical: -5 },
    },
    'bottom-left': {
      anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      transformOrigin: { horizontal: 'left', vertical: -5 },
    },
  };

  return (
    <BasicMenu
      {...alignments[alignment]}
      variant={variant}
      size={size}
      icon={icon}
      title={title}
      disabled={disabled}
      menuEntries={actions}
      onOpen={onRowClick}
    />
  );
};

MultiActionButton.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
};

MultiActionButton.defaultProps = {
  disabled: false,
  icon: 'more-vert',
  variant: 'neutral',
  size: 'medium',
  title: '',
};

export default MultiActionButton;
