import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pdfjs, Document, Page } from 'react-pdf';
import { ButtonIcon } from '@datasoluce/ui-design-system-ts';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { pdfjsWorker } from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const StyledPdfContainer = styled.div`
  display: flex;
  justify-content: center;

  > div {
    margin: 20px;
    padding: 20px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  }
`;

const StyledActions = styled.div`
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 25px;
  position: absolute;
  bottom: 10px;
  left: 30px;
  z-index: 50;
  justify-content: space-between;
  padding: 4px;
`;

const StyledPaginator = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding-left: 10px;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 90%;
`;

const StyledInput = styled.input`
  width: 20px;
  background: ${({ theme }) => theme.colors.black};
  border: none;
  padding: 2px;
  text-align: center;
  outline: none;
`;

const options = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps`,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const PdfViewerComponent = ({ uri }) => {
  const [numPages, setNumPages] = useState(0);
  const [scale, setScale] = useState(6);
  const [pageNumber, setPageNumber] = useState(1);
  const [requestedPage, setRequestedPage] = useState(1);

  const contentRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setRequestedPage(pageNumber);
  }, [pageNumber]);

  const handleResize = useCallback(() => {
    if (contentRef.current) {
      setWidth(contentRef.current.clientWidth);
    }
  }, []);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const onZoomIn = useCallback(() => {
    if (scale < 10) {
      setScale(scale + 2);
    }
  }, [scale]);

  const onZoomOut = useCallback(() => {
    if (scale > 2) {
      setScale(scale - 2);
    }
  }, [scale]);

  const onPrevious = useCallback(() => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }, [pageNumber]);

  const onNext = useCallback(() => {
    if (numPages > pageNumber) {
      setPageNumber(pageNumber + 1);
    }
  }, [numPages, pageNumber]);

  const sendRequestedPage = () => {
    if (!Number.isNaN(Number(requestedPage)) && requestedPage > 0 && requestedPage <= numPages) {
      setPageNumber(Number(requestedPage));
    } else {
      setRequestedPage(pageNumber);
    }
  };

  return (
    <>
      <StyledPdfContainer ref={contentRef}>
        <Document file={uri} onLoadSuccess={onDocumentLoadSuccess} options={options} loading="Chargement en cours">
          <Page
            key={`page_${pageNumber}`}
            pageNumber={pageNumber}
            width={width}
            scale={scale / 10}
            loading="Chargement en cours"
          />
        </Document>
      </StyledPdfContainer>

      <StyledActions>
        <div>
          <ButtonIcon
            title="Zoom arrière"
            icon="remove"
            variant="selfhold"
            onClick={onZoomOut}
            disabled={scale === 0}
          />
          <ButtonIcon title="Zoom avant" icon="add" variant="selfhold" onClick={onZoomIn} disabled={scale === 10} />
        </div>
        {numPages > 1 && (
          <StyledPaginator>
            <ButtonIcon
              title={pageNumber === 1 ? 'Précédent' : undefined}
              icon="collapse-left"
              variant="selfhold"
              onClick={onPrevious}
              disabled={pageNumber === 1}
            />
            Page
            <StyledInput
              type="text"
              value={requestedPage}
              onChange={(e) => setRequestedPage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  sendRequestedPage();
                }
              }}
              onBlur={sendRequestedPage}
            />
            / {numPages}
            <ButtonIcon
              title="Suivant"
              icon="collapse-right"
              variant="selfhold"
              onClick={onNext}
              disabled={pageNumber === numPages}
            />
          </StyledPaginator>
        )}
      </StyledActions>
    </>
  );
};

PdfViewerComponent.propTypes = {
  uri: PropTypes.string.isRequired,
};

export default PdfViewerComponent;
