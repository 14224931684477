import actionTypes from './actionTypes';

const defaultState = {
  projectsTable: {
    name: 0,
    status: 0,
    address: 0,
    typology: 0,
    nature: 0,
    usage: 0,
    basementNumber: 0,
    floorNumber: 0,
    parkingNumber: 0,
  },
  edmTable: {
    title: 0,
    review: 0,
    phase: 0,
    emitter: 0,
    speciality: 0,
    zone: 0,
    level: 0,
    batch: 0,
    type: 0,
    link: 0,
    date: 0,
    visibility: 0,
  },
  uploadTable: {
    title: 0,
    phase: 0,
    emitter: 0,
    speciality: 0,
    zone: 0,
    level: 0,
    batch: 0,
    type: 0,
    visibility: 0,
  },
  reqDefTable: {
    code: 0,
    name: 0,
    category: 0,
    label: 0,
    format: 0,
  },
  menuExpanded: true,
  gridView: true,
};

function userPreferencesReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.UPDATE_COLUMNS_SIZES:
      if (action.tableName && state[action.tableName]) {
        return { ...state, [action.tableName]: { ...state[action.tableName], ...action.columnsSizes } };
      }
      return state;
    case actionTypes.TOGGLE_MENU:
      return { ...state, menuExpanded: !state.menuExpanded };
    case actionTypes.CHANGE_GRID_VIEW:
      return { ...state, gridView: action.gridView };
    default:
      return state;
  }
}

export default userPreferencesReducer;
