import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetFolderQuery } from 'common/api/backend/folder-api';
import CommonOutlet from './common-outlet';

const FolderOutlet = () => {
  const { projectSysId, folderSysId } = useParams();

  const { isSuccess, isLoading, isError } = useGetFolderQuery({ projectSysId, folderSysId });

  return <CommonOutlet {...{ isSuccess, isLoading, isError }} />;
};

export default FolderOutlet;
