import styled from 'styled-components';
import TextFormField from 'components/form-fields/text-form-field';
import LoginImage from 'assets/login-image.jpg';
import { scrollbar } from '../utils/common/style-utils';
import SearchField from './fields/search-field';

export const StyledSharedContainer = styled.div`
  display: flex;
  min-height: 0;
  min-width: 0;
  flex: 0 0 100%;
  flex-direction: column;
`;

export const StyledSharedMain = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  width: 100%;
  height: 100%;
`;

export const StyledSharedSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  margin-bottom: ${({ theme }) => `${theme.margin.lg}`};
  min-height: 0;
`;

export const StyledSharedIconSection = styled.section`
  flex: 0 1 35%;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.margin.xl};
`;

export const StyledSharedDescription = styled.div`
  padding: ${({ theme }) => `0 0 ${theme.padding.xl} 0`};
  width: 100%;
`;

export const StyledSharedContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 0;
  flex-grow: 1;
`;

export const StyledSharedFooter = styled.footer`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  button {
    margin-left: 10px;
  }
`;

export const StyledSharedMessage = styled.div`
  color: ${({ theme }) => theme.color.darkGrey};
  font-size: ${({ theme }) => theme.fontSize.l};
  padding: ${({ theme }) => `${theme.padding.xs} 0`};
`;

export const StyledSharedRightSection = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 0 65%;
  min-height: 0;
  min-width: 400px;
  height: 100%;
`;

export const StyledSharedFormDescription = styled.div`
  padding: ${({ theme }) => `0 0 ${theme.padding.xl} 0`};
`;

export const StyledSharedProjectFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  min-height: 0;
  min-width: 0;

  > article {
    padding-bottom: ${({ theme }) => theme.padding.s};
  }
`;

export const StyledSharedProjectFormRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.margin.s};
  flex: 0 0 auto;
  min-height: 75px;

  ${({ grid }) =>
    grid &&
    `
      > div:first-child {
        max-width: 255px;
      }
  `};

  & > * + * {
    margin-left: ${({ theme }) => theme.margin.xl};
  }
`;

export const StyledSharedProjectTextFormField = styled(TextFormField)`
  flex: 0 1 ${({ width }) => (width ? `${width}px` : '')};
  min-width: 0;
`;

export const StyledSharedFormButtonContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  min-width: 0;
  justify-content: flex-end;
  flex: 0 0 auto;

  > button {
    flex: 0 0 auto;
  }

  button + button {
    margin-left: ${({ theme }) => theme.margin.m};
  }
`;

export const StyledSharedErrorMessage = styled.div`
  color: ${({ theme }) => theme.color.red};
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: ${({ theme }) => theme.fontWeight.semi};
  margin-bottom: ${({ theme }) => theme.margin.m};
`;

export const StyledSharedModuleContainer = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.radius.l};
  display: flex;
  flex-direction: column;
  width: 540px;
  height: 425px;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
  padding: 20px 20px 10px 20px;
`;

export const StyledSharedUndefined = styled.span`
  color: ${({ theme }) => theme.color.darkGrey};
`;

export const StyledSharedNoContent = styled.span`
  color: ${({ theme }) => theme.color.darkGrey};
`;

export const StyledSharedActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;

export const StyledSharedCartridgeList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
  justify-content: center;
  padding: ${({ theme }) => theme.padding.lg};
  align-items: center;
`;

export const StyledSharedCartridge = styled.section`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.padding.md};
  border-radius: ${({ theme }) => `${theme.radius.l} ${theme.radius.l} 0 ${theme.radius.l}`};
  background-color: ${({ theme }) => theme.color.white};
  margin: ${({ theme }) => `0 ${theme.padding.xs} 0 0`};
  max-width: 840px;
  width: 100%;
`;

export const StyledSharedCartridgeSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #b8b8b8;
`;

export const StyledSharedCartridgeName = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.l};
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: ${({ theme }) => `${theme.margin.m} 0`};
  ${(props) => (props.color ? `color: ${props.color}` : '')};
`;

export const StyledSharedModalButtonContainer = styled.div`
  padding-top: ${({ theme }) => theme.padding.xs};
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledSharedCheckboxContainer = styled.div`
  > div {
    margin: 0 ${({ theme }) => theme.margin.m};
  }
`;

export const StyledSharedForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 0;
`;

export const StyledSharedList = styled.div`
  display: flex;
  height: 200px;
  padding-left: ${({ theme }) => theme.padding.s};
`;

export const StyledSharedFloating = styled.div`
  z-index: 45;
  background-color: white;
  width: 659px;
  height: 250px;
  padding: ${({ theme }) => `${theme.padding.m} ${theme.padding.s} 0 ${theme.padding.s}`};
  border-radius: ${({ theme }) => `${theme.radius.m} ${theme.radius.m} 0 ${theme.radius.m}`};
  box-shadow: -4px 4px 20px 0 rgba(0, 0, 0, 0.25), 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  text-align: left;
`;

export const StyledSharedTitleError = styled.h1`
  color: ${({ theme }) => theme.color.darkGreyIcon};
  font-size: 52px;
  margin: 106px 0 56px 0;
`;

export const StyledSharedWrapperError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 ${({ theme }) => theme.margin.xl};
`;

export const StyledSharedListError = styled.ul`
  max-width: 895px;
  margin: 56px 0 71px 20px;

  li {
    color: ${({ theme }) => theme.color.darkGreyIcon};
    font-size: 24px;
    line-height: 36px;
  }
`;

export const StyleSharedEllipsisSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
`;

export const StyledSharedFilterContainer = styled.div`
  display: flex;
  min-height: 0;
  flex-direction: column;
  user-select: none;
  flex: 1;
`;

export const StyledSharedCheckboxFilterContainer = styled(StyledSharedFilterContainer)`
  flex: 0 0 auto;
  min-width: 0;
`;

export const StyledSharedVirtualCheckboxContainer = styled.div`
  display: flex;
  min-height: 0;
  flex: 0 0 auto;
  margin: ${({ theme }) => theme.margin.s} 0;
  padding: ${({ theme }) => ` ${theme.margin.xs} 0`};

  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.color.veryLightBlue};
  }
`;

export const StyledSharedSearchField = styled(SearchField)`
  input {
    padding: ${({ theme }) => theme.padding.s};
    border-bottom: none;
    height: 22px;
    font-size: ${({ theme }) => theme.fontSize.l};
  }

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    height: 75%;
    width: 1px;
    border-left: ${({ theme }) => theme.color.gray} 1px solid;
  }
`;

export const StyledSharedBreakWordTitleTwo = styled.h2`
  font-weight: ${({ theme }) => theme.fontWeight.semi};
  font-size: ${({ theme }) => theme.fontSize.h2};
  word-break: break-word;
  color: ${({ theme }) => theme.color.blue};
  margin: ${({ theme }) => `${theme.margin.sm} 0 ${theme.margin.m} 0`};
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledSharedMultiSelectBarWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledSharedSplitViewMultiEditUploadModal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  min-height: 0;
  height: 440px;
  min-width: 0;
  header {
    height: 36px;
  }
`;

export const StyledSharedStackMultiEditUploadModal = styled.div`
  overflow: scroll;
`;

export const StyledSharedLeftPanelMultiEditUploadModal = styled.div`
  display: flex;
  min-width: 0;
  flex-direction: column;
  flex-basis: 40%;
`;

export const StyledSharedRightPanelMultiEditUploadModal = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  width: 100%;
  min-height: 0;
  min-width: 0;
`;

export const StyledSharedLoginFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledSharedLoginText = styled.div`
  margin: ${({ theme }) => theme.margin.xxl};
  user-select: none;
  color: ${({ theme }) => theme.colors.white};

  h1 {
    font-size: 28px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    line-height: 38px;
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.xxl};
    font-weight: ${({ theme }) => theme.fontWeight.semi};
    line-height: 27px;
  }
`;

export const StyledSharedLoginImageContainer = styled.div`
  display: flex;
  flex: 0 50%;
  background-size: cover;
  background-image: url(${LoginImage});
`;

export const StyledSharedScrollPanel = styled.div`
  display: flex;
  min-width: 0;
  min-height: 0;
  overflow-y: auto;
  ${scrollbar('rgba(40, 86, 178, 0.14)')};
  flex-direction: column;
  flex: 1 1 100%;
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  min-height: 0;
  background: white;
  padding: 20px;
  flex-direction: column;
`;

export const StyledSharedGridCardContainer = styled.div`
  width: 540px;
`;

export const StyledSharedClickDragDropSection = styled.section`
  display: flex;
  flex-direction: column;

  ${({ compacted }) => !compacted && 'height: 100%'};

  > div:first-child {
    ${({ compacted }) => !compacted && 'height: 100%'};
  }
`;

export const StyledSharedUnderlineBold = styled.b`
  text-decoration: underline;
`;
