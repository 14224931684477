import React, { useMemo } from 'react';
import useSearch from 'hooks/use-search';
import { useLocation, useParams } from 'react-router-dom';
import { useGetMetadataQuery } from 'common/api/backend/metadata-api';
import TabContentItem from './components/tab-content-item';
import config from '../../../../app-config';

const { METADATA } = config;

export const TabContentComponent = () => {
  const { search, onSearch, resetSearch } = useSearch();
  const { projectSysId } = useParams();
  const { pathname } = useLocation();
  const { data: metadata, isSuccess } = useGetMetadataQuery({ projectSysId });

  const path = pathname.split('/').pop();
  const activeMetadataType = METADATA.TYPE[path.toUpperCase()];

  const activeMetadata = useMemo(
    () =>
      isSuccess && activeMetadataType !== '' && metadata.length > 0
        ? metadata.find((md) => md.type === activeMetadataType).children
        : [],
    [isSuccess, activeMetadataType, metadata]
  );

  return (
    isSuccess && (
      <TabContentItem
        projectSysId={projectSysId}
        metadata={activeMetadata}
        onSearch={onSearch}
        searchValue={search}
        currentCategory={activeMetadataType}
        resetSearch={resetSearch}
      />
    )
  );
};

export default TabContentComponent;
