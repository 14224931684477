export default {
  SET_ASSEMBLIES: 'SET_ASSEMBLIES',
  UPDATE_ASSEMBLIES: 'UPDATE_ASSEMBLIES',
  ADD_ASSEMBLY: 'ADD_ASSEMBLY',
  REMOVE_ASSEMBLY: 'REMOVE_ASSEMBLY',
  SET_ASSEMBLY_MODELS: 'SET_ASSEMBLY_MODELS',
  SET_CURRENT_ASSEMBLY: 'SET_CURRENT_ASSEMBLY',
  TOGGLE_ASSEMBLY_MODELS: 'TOGGLE_ASSEMBLY_MODELS',
  RESET_ASSEMBLY_MODELS: 'RESET_ASSEMBLY_MODELS',
};
