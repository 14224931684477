import { DEFAULT_COLOR, CUSTOM_PROPAGATE, DEFAULT, INHERITED } from 'common/tree/colors';
import { NOT_ISOLATED, ISOLATED, ISOLATE_SELF } from 'common/tree/isolate';
import _ from 'lodash';
import { nanoid } from 'nanoid';

// const NOT_LOADED = 0;
// const PARTIALLY_LOADED = 1;
// const FULLY_LOADED = 2;

function getColor(levelId, tree) {
  let id = levelId;
  let status = tree[levelId].color.status;
  while (status !== CUSTOM_PROPAGATE && status !== INHERITED && tree[id].levelId !== null) {
    id = tree[id].levelId;
    status = tree[id].color.status;
  }
  if (status === CUSTOM_PROPAGATE || status === INHERITED) {
    return {
      value: tree[id].color.value,
      status: INHERITED,
    };
  }
  return {
    value: DEFAULT_COLOR,
    status: DEFAULT,
  };
}

function isSelected(selectedItems, data) {
  const {
    revisionSysId,
    forgeIds: { defaults, rooms },
  } = data;
  let selected = false;
  if (defaults.length) {
    selected = defaults.every((id) => {
      if (!selectedItems.main || !selectedItems.main[revisionSysId]) {
        return false;
      }

      return Object.values(selectedItems.main[revisionSysId]).some((forgeIds) => forgeIds.includes(id));
    });
  } else if (rooms.length) {
    selected = rooms.every((id) => {
      if (!selectedItems.rooms || !selectedItems.rooms[revisionSysId]) {
        return false;
      }

      return Object.values(selectedItems.rooms[revisionSysId]).some((forgeIds) => forgeIds.includes(id));
    });
  }
  return selected;
}

export const formatTree = (levelId, children, tree, forgeToNode, selectedItems) => {
  if (levelId === '#' && tree[levelId] && tree[levelId].children) {
    return {};
  }

  let color = {
    value: DEFAULT_COLOR,
    status: DEFAULT,
  };

  // theses two const must be clone/reassign to avoid immutable redux error
  const newTree = _.cloneDeep(tree);
  // eslint-disable-next-line prefer-object-spread
  const newForgeToNode = Object.assign({}, { rooms: [...forgeToNode.rooms], defaults: [...forgeToNode.defaults] });

  if (!newTree[levelId]) {
    newTree[levelId] = {
      levelId: null,
      id: levelId,
      children: null,
      data: {},
      loaded: false,
      folded: false,
      isolated: NOT_ISOLATED,
      selected: false,
      color,
    };
  }

  color = { ...getColor(levelId, newTree) };
  if (newTree[levelId].data.forgeIds) {
    newTree[levelId].data.forgeIds.children = [];
  }
  const childrenArray = [];

  children.forEach((n) => {
    const canIsolateChildren = n.data.forgeIds.children.length > 0 ? ISOLATED : ISOLATE_SELF;
    const isolated = newTree[levelId].isolated === ISOLATED ? canIsolateChildren : NOT_ISOLATED;
    const selected = isSelected(selectedItems, n.data.forgeIds);
    const node = {
      id: nanoid(),
      ...n,
      children: null,
      loaded: false,
      folded: true,
      selected,
      isolated,
      levelId,
      color,
      canIsolateChildren,
    };

    childrenArray.push(node.id);

    // newTree[levelId] must be a new object
    newTree[levelId] = {
      ...newTree[levelId],
      children: childrenArray,
      loaded: true,
      folded: false,
    };
    // fill array of forgeId to nodeId
    newTree[node.id] = node;
    n.data.forgeIds.forgeIds.rooms.forEach((forgeId) => {
      newForgeToNode.rooms[forgeId] = node.id;
    });
    n.data.forgeIds.forgeIds.defaults.forEach((forgeId) => {
      newForgeToNode.defaults[forgeId] = node.id;
    });
  });
  // replace children null by empty array
  // so the component knows it got the results, even though they're empty
  if (!children.length) {
    newTree[levelId] = {
      ...newTree[levelId],
      children: [],
      loaded: true,
      folded: false,
    };
  }
  return { treeView: newTree, newForgeToNode };
};

export default formatTree;
