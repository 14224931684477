import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { Button } from '@datasoluce/ui-design-system-ts';

const MessageContainer = styled.div`
  font-size: ${({ theme, subMessage }) => (subMessage ? theme.fontSize.m : theme.fontSize.l)};
  margin-bottom: ${({ subMessage }) => (subMessage ? '10px' : '')};
  color: ${({ theme, error }) => (error ? theme.color.red : '')};
`;

const SubMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-bottom: 0.2em;

  li {
    margin-left: 1em;
  }
`;

const ElementContainer = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ConfirmDeleteModal = ({ buttonName, onCancel, onDelete, isOpen, message, subMessage, subElementList, error }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onCancel}
    size="sm"
    closeButton={
      <Button variant="outline" onClick={onCancel}>
        Annuler
      </Button>
    }
    primaryButton={
      <Button variant="danger" onClick={onDelete} disabled={error}>
        {buttonName}
      </Button>
    }>
    <div>
      <MessageContainer>{message}</MessageContainer>
      {subElementList && subElementList.length > 0 && (
        <SubMessageContainer>
          <MessageContainer subMessage>{subMessage}</MessageContainer>
          <ul>
            {subElementList.map((element, index) => (
              <li key={index + element}>
                <ElementContainer>{element}</ElementContainer>
              </li>
            ))}
          </ul>
        </SubMessageContainer>
      )}
      {error && (
        <SubMessageContainer>
          <MessageContainer error>{error}</MessageContainer>
        </SubMessageContainer>
      )}
    </div>
  </Modal>
);

ConfirmDeleteModal.propTypes = {
  buttonName: PropTypes.string,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  isOpen: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  subMessage: PropTypes.string,
  subElementList: PropTypes.arrayOf(PropTypes.string),
};

ConfirmDeleteModal.defaultProps = {
  isOpen: false,
  message: 'Supprimer le document ?',
  buttonName: 'Supprimer',
  subMessage: '',
  subElementList: null,
  onCancel: undefined,
  onDelete: undefined,
};

export default ConfirmDeleteModal;
