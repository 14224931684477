import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { TitleBar, NavTabs, TitleH1 } from '@datasoluce/ui-design-system-ts';
import ScrollablePanel from '../scrollable-panel';
import Breadcrumbs from './components/breadcrumbs';

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  height: ${({ theme }) => theme.height.page};
  width: 100%;
  align-items: center;
  padding: 0;
  padding-right: ${({ theme, showInformationPanel }) => showInformationPanel && `${theme.width.informationPanel}px`};
  transition: padding-right 200ms ease-out;

  @media only screen and (max-width: 1850px) {
    ${({ showInformationPanel }) =>
      showInformationPanel
        ? `
          .MuiGrid-grid-xs-12.MuiGrid-grid-sm-4 { 
            display: none; 
          }
          .MuiGrid-grid-sm-4 {
            flex-basis: 50%;
            max-width: 50%; 
            > div {justify-content: start} 
            > div > div > div {width: auto }
          }
          .MuiGrid-grid-xs-4 > div {
            justify-content: end
          } 
        `
        : ''};
  }

  @media only screen and (max-width: 1350px) {
    ${({ showInformationPanel }) =>
      showInformationPanel
        ? `.MuiGrid-grid-xs-4 {
          display: none;
        }
        .MuiGrid-grid-xs-8 {
          flex-basis: 100%;
          max-width: 100%;
          > div {justify-content: center} 
        }`
        : ''};
  }
`;

const StyledPageHeader = styled.div`
  width: 100%;
  padding-top: 13px;
  ${({ theme, withTabs }) => (withTabs ? `border-bottom: 1px solid ${theme.colors.grey};` : 'padding-bottom: 13px;')}

  > * {
    padding: 0 20px;
  }

  .active {
    pointer-events: none;
  }
`;

const StyledMain = styled.main`
  flex: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 0;
`;

const DisplayedButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ withTabs }) => !withTabs && 'flex-direction: row-reverse;'}
  > div:first-child {
    align-self: flex-end;
  }
  > div:nth-child(2) {
    padding: 12px 0;
  }
`;

const PageContainer = ({ title, children, tabs, viewModule, subModule, searchBar, showInformationPanel }) => {
  const location = useLocation();

  return (
    <StyledPage showInformationPanel={showInformationPanel}>
      <ScrollablePanel>
        {title && (
          <StyledPageHeader withTabs={tabs?.length}>
            <Breadcrumbs />
            <TitleBar title={<TitleH1>{title}</TitleH1>} subModule={subModule} searchBar={searchBar} />
            <DisplayedButtonContainer withTabs={tabs?.length}>
              {tabs && tabs.find((t) => t.path === location.pathname) && (
                <NavTabs LinkComponent={NavLink} tabs={tabs} activeTab={location.pathname} variant="scrollable" />
              )}
              {viewModule}
            </DisplayedButtonContainer>
          </StyledPageHeader>
        )}
        <StyledMain>{children}</StyledMain>
      </ScrollablePanel>
    </StyledPage>
  );
};

PageContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  subModule: PropTypes.any,
  viewModule: PropTypes.node,
  searchBar: PropTypes.object,
  showInformationPanel: PropTypes.bool,
};

PageContainer.defaultProps = {
  title: undefined,
  children: undefined,
  subModule: undefined,
  viewModule: undefined,
  searchBar: undefined,
  showInformationPanel: false,
};

export default PageContainer;
