import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import theme from 'theme';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useOnClickOutside from 'hooks/use-on-click-outside';
import { clearUploads, removeUpload } from 'common/upload/actions';
import IconContainer from 'components/icon-container';
import { scrollbar } from 'utils/common/style-utils';
import { Badge, CircularProgressWithLabel } from '@datasoluce/ui-design-system-ts';
import UploadProgress from '../upload-progress';

const Wrapper = styled.div`
  height: 100%;
  margin-right: ${theme.margin.md};
  display: flex;
  align-items: center;
  position: relative;
  align-self: flex-end;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.white};
  cursor: pointer;
`;

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    top: 16px;
    right: 8px;
  }
`;

const StyledUploadList = styled.div`
  box-sizing: inherit;
  z-index: 50;
  height: ${({ visible }) => (visible ? 'auto' : 0)};
  transition: height 300ms ease-in-out;
  position: absolute;
  top: 60px;
  right: 0px;
  background-color: ${theme.color.white};
  width: 310px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  cursor: default;
`;

const StyledRemoveAll = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  padding: 15px;
  justify-content: center;
  color: ${theme.color.tooltipText};
  background-color: ${theme.color.lightGrey};
  font-size: ${theme.fontSize.s};
  cursor: pointer;
`;

const UploadContainer = styled.div`
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  ${scrollbar('rgba(40, 86, 178, 0.14)')};
`;

const StyledText = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  padding: 10px;
  color: ${theme.color.tooltipText};
  font-size: ${theme.fontSize.s};
`;

const StyledBrowserWarning = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${theme.color.orange};
  padding: ${`${theme.padding.s} 0`};
  font-size: ${theme.fontSize.l};
`;

export const UploadMenuComponent = ({
  filesUpload,
  isLoading,
  projectSysId,
  removeItemAction,
  removeAllItemAction,
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const [filesUploadLength, setFilesUploadLength] = useState(0);
  const [projectFileList, setFilesList] = useState([]);

  useEffect(() => {
    if (filesUpload && filesUpload[projectSysId]) {
      setFilesUploadLength(Object.keys(filesUpload[projectSysId]).length);
      setFilesList(Object.entries(filesUpload[projectSysId]));
    }
  }, [projectSysId, filesUpload]);

  const [show, setShow, ref] = useOnClickOutside();

  const overallProgress = useMemo(() => {
    const runningProjectFileList = projectFileList.filter((file) => file[1].status !== 'previous');
    if (runningProjectFileList.length > 0) {
      return Math.round(
        runningProjectFileList.reduce((acc, file) => {
          return acc + (file[1].progress ?? 0);
        }, 0) / runningProjectFileList.length
      );
    }

    return 0;
  }, [projectFileList]);

  const hasError = useMemo(() => {
    if (projectFileList.length > 0) {
      return projectFileList.find((file) => file[1].status === 'error') !== undefined;
    }

    return false;
  }, [projectFileList]);

  useEffect(() => {
    if (!show) {
      setShowWarning(false);
    }
  }, [show]);

  const beforeUnloadHandler = (event) => {
    if (isLoading.length > 0) {
      setShowWarning(true);
      setShow(true);
      event.preventDefault();
      event.returnValue = ''; // eslint-disable-line no-param-reassign
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadHandler);
    return () => window.removeEventListener('beforeunload', beforeUnloadHandler);
  });

  return (
    <Wrapper ref={ref}>
      <Container onClick={() => setShow(!show)}>
        {filesUploadLength > 0 && isLoading.length > 0 && isLoading.some((projects) => projects === projectSysId) ? (
          <CircularProgressWithLabel value={overallProgress} color={hasError ? 'error' : 'primary'} />
        ) : (
          <StyledBadge
            color="error"
            variant="dot"
            invisible={!hasError}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <IconContainer key="file" icon="document-uploaded" fontSize="30px" color={theme.colors.grey} />
          </StyledBadge>
        )}
      </Container>
      <StyledUploadList visible={show}>
        <UploadContainer>
          {filesUploadLength > 0 ? (
            <>
              <StyledRemoveAll key={projectSysId} onClick={() => removeAllItemAction(projectSysId)}>
                Retirer tous les téléchargements de la liste
              </StyledRemoveAll>
              {showWarning && (
                <StyledBrowserWarning>
                  <IconContainer icon="error" noPointer color="#f9a74e" fontSize="30px" margin="0 13px 0 0" />
                  <div>
                    Ne pas fermer le navigateur
                    <br />
                    Téléchargement en cours...
                  </div>
                </StyledBrowserWarning>
              )}
              {projectFileList.map(([key, file]) => (
                <UploadProgress
                  key={key}
                  progress={file.progress || 0}
                  title={file.fileName}
                  status={file.status}
                  onClickRemove={() => removeItemAction(projectSysId, key)}
                />
              ))}
            </>
          ) : (
            <StyledText>Aucun téléchargement récent</StyledText>
          )}
        </UploadContainer>
      </StyledUploadList>
    </Wrapper>
  );
};

UploadMenuComponent.propTypes = {
  filesUpload: PropTypes.shape({
    fileName: PropTypes.string,
    status: PropTypes.string,
    progress: PropTypes.number,
  }).isRequired,
  isLoading: PropTypes.array.isRequired,
};

const mapStateToProps = ({ upload }) => ({
  filesUpload: upload.files,
  isLoading: upload.isLoading,
});

export default connect(mapStateToProps, {
  removeItemAction: removeUpload,
  removeAllItemAction: clearUploads,
})(UploadMenuComponent);
