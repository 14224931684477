import { Sidemenu } from '@datasoluce/ui-design-system-ts';
import PropTypes from 'prop-types';

const NavigationComponent = ({ entries }) => {
  return <Sidemenu entries={entries} />;
};

NavigationComponent.propTypes = {
  entries: PropTypes.array.isRequired,
};

export default NavigationComponent;
