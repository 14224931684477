import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useModal from 'hooks/use-modal';
import config from 'app-config';
import { useRestoreProjectMutation } from 'common/api/backend/project-api';

const { PROJECT_MODALS } = config;

const useProjectMenuEntries = ({ organizationSysId, project }) => {
  const navigate = useNavigate();
  const [restoreProject] = useRestoreProjectMutation();
  const { showModalMutation } = useModal();

  const menuEntries = useMemo(() => {
    if (!project || !project.capabilities) {
      return [];
    }

    return [
      {
        children: <>Restaurer</>,
        onClick: () => restoreProject({ projectSysId: project.sysId }),
        icon: 'restore',
        hasAuthorization: project.capabilities.canRestore,
      },
      {
        children: <>Archiver</>,
        onClick: () => showModalMutation(PROJECT_MODALS.PROJECT_ARCHIVE, { project }),
        icon: 'box',
        hasAuthorization: project.capabilities.canArchive,
      },
      {
        children: <>Éditer</>,
        onClick: () => navigate(`/organization/${organizationSysId}/project/${project.sysId}/update-project`),
        icon: 'pencil',
        hasAuthorization: project.capabilities.canUpdate,
      },
      {
        children: <>Modifier l’image du projet</>,
        onClick: () => navigate(`/organization/${organizationSysId}/project/${project.sysId}/update-project-image`),
        icon: 'camera',
        hasAuthorization: project.capabilities.canUpdateImage,
      },
    ]
      .filter((a) => a.hasAuthorization)
      .map((a) => {
        const { hasAuthorization, ...b } = a;
        return b;
      });
  }, [project, restoreProject, showModalMutation, navigate, organizationSysId]);

  return [menuEntries];
};

export default useProjectMenuEntries;
