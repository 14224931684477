import React from 'react';
import PropTypes from 'prop-types';
import { fadeInAnimation } from 'utils/common/animation-utils';
import { Modal as ModalDesignSystem } from '@datasoluce/ui-design-system-ts';
import ReactModal from 'react-modal';

const ModalStyles = {
  overlay: {
    zIndex: 100,
    backgroundColor: 'rgba(29, 29, 29, 0.8)',
    animation: `${fadeInAnimation} 0.5s`,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: 'none',
  },
};

const Modal = ({
  onRequestClose,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  isOpen,
  children,
  title,
  size,
  closeButton,
  secondaryButton,
  primaryButton,
}) => (
  <ReactModal
    isOpen={isOpen}
    ariaHideApp={false}
    onRequestClose={onRequestClose}
    shouldCloseOnEsc={shouldCloseOnEsc}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    contentLabel="Modal"
    style={ModalStyles}>
    <ModalDesignSystem
      size={size}
      titleLabel={title}
      closeButton={closeButton}
      secondaryButton={secondaryButton}
      primaryButton={primaryButton}>
      {children}
    </ModalDesignSystem>
  </ReactModal>
);

Modal.propTypes = {
  onRequestClose: PropTypes.func,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  isOpen: PropTypes.bool,
  children: PropTypes.any,
  size: PropTypes.string,
  closeButton: PropTypes.element,
  secondaryButton: PropTypes.element,
  primaryButton: PropTypes.element,
  title: PropTypes.string,
};

Modal.defaultProps = {
  onRequestClose: () => {},
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
  isOpen: false,
  children: null,
  size: null,
  closeButton: null,
  secondaryButton: null,
  primaryButton: null,
  title: null,
};

export default Modal;
