import { backendServiceApi, BE_TYPE } from 'common/api/backend-service-api';
import { dispatchMessageConfiguration, mapSysIdAsTags } from 'common/api/utils';
import { addError, addSuccess } from 'common/slice/message';
import {
  fetchProjectConfigurationFailedMessage,
  fetchProjectFailedMessage,
  fetchProjectsFailedMessage,
  fetchUsersFailedMessage,
  getProjectArticleExtractionsFailedMessage,
  getProjectContributorsFailedMessage,
  inviteUserFailedMessage,
  inviteUserSuccessMessage,
  removeUserFailedMessage,
  removeUsersFailedMessage,
  updateProjectArchiveFailedMessage,
  updateProjectFailedMessage,
  updateProjectRestoreFailedMessage,
  updateUserRoleFailedMessage,
  updateUsersRoleFailedMessage,
} from 'common/message/error-messages';

const projectApi = backendServiceApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getProject: build.query({
      query: ({ projectSysId }) => ({
        url: `project/${projectSysId}`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchProjectFailedMessage }) }),
      providesTags: (response, meta, arg) => [{ type: BE_TYPE.PROJECT, id: arg.projectSysId }],
    }),
    updateProject: build.mutation({
      query: ({ projectSysId, data }) => {
        return {
          url: `/project/${projectSysId}`,
          method: 'PATCH',
          body: data,
        };
      },
      ...dispatchMessageConfiguration({ error: () => ({ message: updateProjectFailedMessage }) }),
      invalidatesTags: (response) => [
        { type: BE_TYPE.PROJECT, id: response.sysId },
        { type: BE_TYPE.PROJECT, id: 'LIST' },
      ],
    }),
    updateProjectImage: build.mutation({
      query: ({ projectSysId, data }) => {
        return {
          url: `/project/${projectSysId}/image`,
          method: 'POST',
          body: data,
          headers: { 'Content-Type': data.type },
        };
      },
      ...dispatchMessageConfiguration({ error: () => ({ message: updateProjectFailedMessage }) }),
      invalidatesTags: (response, meta, arg) => [
        { type: BE_TYPE.PROJECT, id: arg.projectSysId },
        { type: BE_TYPE.PROJECT, id: 'LIST' },
      ],
    }),
    getProjectConfig: build.query({
      query: ({ projectSysId, label }) => ({
        url: `project/${projectSysId}/configuration`,
        params: { label },
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchProjectConfigurationFailedMessage }) }),
      providesTags: (response, meta, arg) => [{ type: BE_TYPE.PROJECT_CONFIG, id: arg.projectSysId }],
    }),
    updateProjectConfig: build.mutation({
      query: ({ projectSysId, data }) => {
        return {
          url: `/project/${projectSysId}/configuration`,
          method: 'PUT',
          body: data,
        };
      },
      ...dispatchMessageConfiguration({ error: () => ({ message: updateProjectFailedMessage }) }),
      invalidatesTags: (response, meta, arg) =>
        response ? [{ type: BE_TYPE.PROJECT_CONFIG, id: arg.projectSysId }] : [],
    }),
    getProjectUsers: build.query({
      query: ({ projectSysId, from, size, search, criteria, sort, order }) => ({
        url: `project/${projectSysId}/users`,
        params: {
          from,
          size,
          search: search || undefined,
          criteria: criteria || undefined,
          sort,
          order,
        },
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchUsersFailedMessage }) }),
      providesTags: (response) => mapSysIdAsTags(response?.users, BE_TYPE.PROJECT_USERS),
    }),
    getProjectContributors: build.query({
      query: ({ projectSysId }) => ({
        url: `project/${projectSysId}/contributor`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: getProjectContributorsFailedMessage }) }),
      providesTags: () => [BE_TYPE.PROJECT_CONTRIBUTORS],
    }),
    updateProjectContributors: build.mutation({
      query: ({ projectSysId, data }) => {
        return {
          url: `/project/${projectSysId}/contributor`,
          method: 'PUT',
          body: data,
        };
      },
      ...dispatchMessageConfiguration({ error: () => ({ message: updateProjectFailedMessage }) }),
      invalidatesTags: (response) => (response ? [{ type: BE_TYPE.PROJECT_CONTRIBUTORS, id: response.sysId }] : []),
    }),
    getProjects: build.query({
      query: ({ organizationSysId, from, size, order, sort, search, isArchived = false }) => ({
        // [f_laf] need to handle differents API endpoints for the same thing...
        url: organizationSysId ? `/platform/${organizationSysId}/projects` : 'projects',
        params: { from, size, order, sort, search: search || undefined, isArchived },
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchProjectsFailedMessage }) }),
      providesTags: (response) => mapSysIdAsTags(response?.projects, BE_TYPE.PROJECT),
    }),
    archiveProject: build.mutation({
      query: ({ projectSysId }) => ({
        url: `/project/${projectSysId}/archive`,
        method: 'POST',
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: updateProjectArchiveFailedMessage }) }),
      invalidatesTags: (response, meta, arg) => [
        { type: BE_TYPE.PROJECT, id: arg.projectSysId },
        { type: BE_TYPE.PROJECT, id: 'LIST' },
      ],
    }),
    restoreProject: build.mutation({
      query: ({ projectSysId }) => ({
        url: `/project/${projectSysId}/restore`,
        method: 'POST',
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: updateProjectRestoreFailedMessage }) }),
      invalidatesTags: (response, meta, arg) => [
        { type: BE_TYPE.PROJECT, id: arg.projectSysId },
        { type: BE_TYPE.PROJECT, id: 'LIST' },
      ],
    }),
    getProjectArticleExtractions: build.query({
      query: ({ projectSysId, from, size, creationDate }) => ({
        url: `/project/${projectSysId}/article/extractions`,
        params: { from, size, creationDate },
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: getProjectArticleExtractionsFailedMessage }) }),
      transformResponse: (response, meta, arg) => {
        return {
          ...response,
          from: arg.from, // this arg, serializeQueryArgs, merge, and forceRefetch are used for infinite scroll
        };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const { projectSysId, size, creationDate } = queryArgs;
        return { projectSysId, size, creationDate };
      },
      merge: (currentCache, newItems) => {
        if (newItems.from > 1) {
          currentCache.extractions.splice(newItems.from, newItems.extractions.length, ...newItems.extractions);
          return currentCache;
        }
        return newItems;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (response, meta, { projectSysId }) => [
        { type: BE_TYPE.PROJECT, id: projectSysId },
        ...mapSysIdAsTags(response?.extractions, BE_TYPE.PROJECT_ARTICLE_EXTRACTIONS),
      ],
    }),
    getProjectRoles: build.query({
      query: ({ projectSysId }) => ({
        url: `project/${projectSysId}/roles`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchUsersFailedMessage }) }),
    }),
    createProjectUsers: build.mutation({
      query: ({ projectSysId, data }) => ({
        url: `project/${projectSysId}/users`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data: res } = await queryFulfilled;
          const nbError = res.createdUser.fail.length + res.updatedUser.fail.length;
          // TODO LLT: add warning message in case of multiple invitation with some errors
          if (nbError === 0) {
            dispatch(addSuccess({ message: inviteUserSuccessMessage }));
          } else {
            dispatch(addError({ message: inviteUserFailedMessage }));
          }
        } catch (err) {
          // `onError` side-effect
          dispatch(addError({ message: inviteUserFailedMessage }));
        }
      },
      invalidatesTags: () => [BE_TYPE.PROJECT_USERS],
    }),
    deleteProjectUsers: build.mutation({
      query: ({ projectSysId, data }) => ({
        url: `project/${projectSysId}/users`,
        method: 'DELETE',
        body: data,
      }),
      ...dispatchMessageConfiguration({
        error: (arg) => ({
          message: arg.data.length === 1 ? removeUserFailedMessage : removeUsersFailedMessage,
        }),
      }),
      invalidatesTags: () => [BE_TYPE.PROJECT_USERS],
    }),
    updateProjectUsersRole: build.mutation({
      query: ({ projectSysId, data }) => ({
        url: `project/${projectSysId}/users/role`,
        method: 'PUT',
        body: data,
        responseHandler: (response) => response.text(),
      }),
      ...dispatchMessageConfiguration({
        error: (arg) => ({
          message: arg.data.length === 1 ? updateUserRoleFailedMessage : updateUsersRoleFailedMessage,
        }),
      }),
      invalidatesTags: () => [BE_TYPE.PROJECT_USERS],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useLazyGetUserProjectsQuery,
  useGetProjectQuery,
  useGetProjectConfigQuery,
  useGetProjectUsersQuery,
  useGetProjectContributorsQuery,
  useGetProjectArticleExtractionsQuery,
  useArchiveProjectMutation,
  useRestoreProjectMutation,
  useUpdateProjectMutation,
  useUpdateProjectImageMutation,
  useUpdateProjectContributorsMutation,
  useUpdateProjectConfigMutation,
  useGetProjectRolesQuery,
  useCreateProjectUsersMutation,
  useDeleteProjectUsersMutation,
  useUpdateProjectUsersRoleMutation,
} = projectApi;

export default projectApi;
