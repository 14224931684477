import { connect, useDispatch } from 'react-redux';
import { Header, Logo, MenuAvatar } from '@datasoluce/ui-design-system-ts';
import { useLogoutMutation } from 'common/api/backend/session-api';
import Loader from 'components/loader';
import useUserSession from 'hooks/use-user-session';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { backendServiceApi } from 'common/api/backend-service-api';

const HeaderComponent = ({ expanded, onExpand, isLoading, menuTooltip, titleSlot, rightSlot }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: user } = useUserSession();

  const [logout] = useLogoutMutation();

  return (
    user && (
      <>
        <Header
          menuTooltip={menuTooltip}
          titleSlot={titleSlot}
          homeTrigger={
            <Link to="/">
              <Logo />
            </Link>
          }
          menuExpanded={expanded}
          onMenuTrigger={onExpand}
          rightSlot={
            <>
              {rightSlot !== undefined && rightSlot}
              <MenuAvatar
                title={user.fullName || user.email}
                menuEntries={[
                  {
                    children:
                      user.fullName && user.fullName.length > 24
                        ? user.fullName.slice(0, 24).concat('...')
                        : user.fullName || user.email,
                    icon: 'user',
                    onClick: () => navigate('/user-settings'),
                    disabled: !user.isComplete || user.status !== 'ACTIVE',
                  },
                  {
                    children: <>Termes et conditions</>,
                    onClick: () => window.open(`${origin}/cgu`, '_blank'),
                    icon: 'inventory',
                  },
                  {
                    children: <>Déconnexion</>,
                    disabled: isLoading.length > 0,
                    onClick: () =>
                      logout().then(() => {
                        navigate('/login');
                        dispatch(backendServiceApi.util.resetApiState());
                      }),
                    icon: 'logout',
                  },
                ]}
              />
            </>
          }
        />
        <Loader />
      </>
    )
  );
};

HeaderComponent.propTypes = {
  expanded: PropTypes.bool,
  onExpand: PropTypes.func,
  menuTooltip: PropTypes.string,
  titleSlot: PropTypes.any,
  rightSlot: PropTypes.any,
};

HeaderComponent.defaultProps = {
  expanded: false,
  onExpand: undefined,
  menuTooltip: '',
  titleSlot: '',
  rightSlot: undefined,
};

const mapStateToProps = ({ upload }) => ({
  isLoading: upload.isLoading,
});

export default connect(mapStateToProps, {})(HeaderComponent);
