export function getRevisionDownloadName(docTitle, revision) {
  if (!revision) {
    return docTitle;
  }
  const version = parseInt(revision.version, 10);
  return version === 0 ? `${docTitle}${revision.ext}` : `${docTitle}-${version + 1}${revision.ext}`;
}

export function sliceExtension(title) {
  return title.substring(0, title.lastIndexOf('.')) || title;
}

export function getExtension(title) {
  return title.substring(title.lastIndexOf('.'));
}

export function hasExtension(title) {
  const dotIndex = title?.indexOf('.');
  return dotIndex > 0 && dotIndex !== title.length - 1;
}

export function octetsBetterDisplay(bytes, dec) {
  switch (true) {
    case bytes >= 1024 ** 6:
      return `${(bytes / 1024 ** 6).toFixed(dec)} Eo`;
    case bytes >= 1024 ** 5:
      return `${(bytes / 1024 ** 5).toFixed(dec)} Po`;
    case bytes >= 1024 ** 4:
      return `${(bytes / 1024 ** 4).toFixed(dec)} To`;
    case bytes >= 1024 ** 3:
      return `${(bytes / 1024 ** 3).toFixed(dec)} Go`;
    case bytes >= 1024 ** 2:
      return `${(bytes / 1024 ** 2).toFixed(dec)} Mo`;
    case bytes >= 1024:
      return `${(bytes / 1024).toFixed(dec)} ko`;
    default:
  }

  return `${bytes} o`;
}

export function convertToCO2Emission(sizeInBytes) {
  // convert into To
  const sizeInTerabytes = sizeInBytes / 1024 ** 4;

  // 1To = 170 kg Eq CO2 / year
  const co2Emission = sizeInTerabytes * 170;
  if (co2Emission < 1) {
    return `${(co2Emission * 1000).toFixed(0)}g EqCO2 / an`;
  }
  return `${co2Emission.toFixed(2)}Kg EqCO2 / an`;
}
