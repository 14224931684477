import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Loading = styled.div`
  height: 2px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  margin-top: -2px;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 2px;
    background-color: ${({ theme }) => theme.color.green};
    animation: loading 1s linear infinite;
  }

  @keyframes loading {
    from {
      left: 50%;
      width: 0;
    }
    33.3333% {
      left: 0;
      width: 100%;
    }
    to {
      left: 0;
      width: 100%;
    }
  }
`;

const Loader = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);
  const isSomeQueryPending = useSelector((state) =>
    Object.values(state.backendServiceApi.queries).some((query) => query.status === 'pending')
  );
  return (isLoading || isSomeQueryPending) && <Loading />;
};

export default Loader;
