import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { extractActiveList } from 'utils/common/utils';
import { searchMetadata } from 'utils/metadata/metadata';
import { useParams } from 'react-router-dom';
import { useGetProjectQuery } from 'common/api/backend/project-api';
import MetadataTree from './components/metadata-tree';

const MetadataListComponent = ({ currentCategory, metadata, searchValue }) => {
  const { projectSysId } = useParams();
  const searchedMetadata = useMemo(() => (searchValue ? searchMetadata(searchValue, metadata) : metadata), [
    metadata,
    searchValue,
  ]);

  const { data: project, isSuccess: isSuccessProject } = useGetProjectQuery({ projectSysId });

  return (
    isSuccessProject && (
      <MetadataTree
        metadata={searchedMetadata}
        currentCategory={currentCategory}
        parent={null}
        activeMetadataCount={extractActiveList(metadata).length}
        disableDrop={!!searchValue || !project.capabilities.canUpdateMetadata}
      />
    )
  );
};

MetadataListComponent.propTypes = {
  currentCategory: PropTypes.string.isRequired,
  metadata: PropTypes.array.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default MetadataListComponent;
