import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import yup from 'utils/common/yup';
import config from 'app-config';

const { ORDER } = config.METADATA;
const initialState = {
  documentHolders: {},
};

const docHolderEmpty = {
  name: '',
  isValid: false,
  error: '',
  selectedFileExtension: '',
  visibility: {
    isPrivate: false,
    groups: [],
    groupNames: [],
    flat: '+',
  },
  ...ORDER.reduce((acc, item) => {
    acc[item] = { code: '' };
    return acc;
  }, {}),
};

const isValidHolder = (docHolder) =>
  docHolder.error === '' &&
  docHolder.selectedFileExtension !== '' &&
  ORDER.every((category) => docHolder[category].code);

const unselectAll = (state, action) => ({
  ...state,
  documentHolders: {
    ...state.documentHolders,
    [action.payload.projectSysId]: state.documentHolders[action.payload.projectSysId].map((doc) => ({
      ...doc,
      selected: false,
    })),
  },
});
const selectAll = (state, action) => ({
  ...state,
  documentHolders: {
    ...state.documentHolders,
    [action.payload.projectSysId]: state.documentHolders[action.payload.projectSysId].map((doc) => ({
      ...doc,
      selected: true,
    })),
  },
});

const toggleSome = (state, action) => {
  const previousSelection = state.documentHolders[action.payload.projectSysId]
    .filter((doc) => doc.selected)
    .map((doc) => doc.key);
  const newSelection = action.payload.selectedDocumentHolderKeys.reduce(
    (acc, current) =>
      acc.find((item) => item === current) === undefined ? [...acc, current] : acc.filter((d) => current !== d),
    previousSelection
  );

  return {
    ...state,
    documentHolders: {
      ...state.documentHolders,
      [action.payload.projectSysId]: state.documentHolders[action.payload.projectSysId].map((doc) => ({
        ...doc,
        selected: newSelection.includes(doc.key),
      })),
    },
  };
};

const slice = createSlice({
  name: 'documentHolderSlice',
  initialState,
  reducers: {
    cleanupHolder: (state, action) => ({
      ...state,
      documentHolders: {
        ...state.documentHolders,
        [action.payload.projectSysId]: undefined,
      },
    }),
    insertDocumentHolder: (state, action) => ({
      ...state,
      documentHolders: {
        ...state.documentHolders,
        [action.payload.projectSysId]: [
          ...(state.documentHolders[action.payload.projectSysId] ?? []),
          { ...docHolderEmpty, key: nanoid() },
        ],
      },
    }),
    updateDocumentHolder: (state, action) => ({
      ...state,
      documentHolders: {
        ...state.documentHolders,
        [action.payload.projectSysId]: state.documentHolders[action.payload.projectSysId].map((holder) => {
          if (holder.key === action.payload.docHolder.key) {
            const checkedDocumentHolder = {
              ...holder,
              ...action.payload.docHolder,
              error: yup.validate(yup.label, action.payload.docHolder.name || holder.name),
            };
            return {
              ...checkedDocumentHolder,
              isValid: isValidHolder(checkedDocumentHolder),
            };
          }

          return holder;
        }),
      },
    }),
    updateDocumentHoldersMetadata: (state, action) => ({
      ...state,
      documentHolders: {
        ...state.documentHolders,
        [action.payload.projectSysId]: state.documentHolders[action.payload.projectSysId].map((doc) =>
          action.payload.fileKeys.find((f) => f === doc.key)
            ? {
                ...doc,
                isValid:
                  doc.error === '' &&
                  doc.name !== '' &&
                  doc.selectedFileExtension !== '' &&
                  ORDER.every(
                    (category) =>
                      doc[category].code ||
                      (action.payload.categories.some((c) => c === category) &&
                        action.payload.updatedMetadata[category].code)
                  ),
                ...action.payload.updatedMetadata,
              }
            : doc
        ),
      },
    }),
    updateDocumentHoldersVisibility: (state, action) => ({
      ...state,
      documentHolders: {
        ...state.documentHolders,
        [action.payload.projectSysId]: state.documentHolders[action.payload.projectSysId].map((doc) =>
          action.payload.fileKeys.find((f) => f === doc.key)
            ? {
                ...doc,
                visibility: action.payload.visibility,
              }
            : doc
        ),
      },
    }),
    removeDocumentHolders: (state, action) => ({
      ...state,
      documentHolders: {
        ...state.documentHolders,
        [action.payload.projectSysId]: state.documentHolders[action.payload.projectSysId].filter(
          (holder) => !action.payload.docKeys.includes(holder.key)
        ),
      },
    }),
    selectDocumentHolders: (state, action) => {
      if (action.payload.selectedDocumentHolderKeys.length === 0) {
        return unselectAll(state, action);
      }
      if (
        action.payload.selectedDocumentHolderKeys.length ===
          state.documentHolders[action.payload.projectSysId].length &&
        state.documentHolders[action.payload.projectSysId].length !== 1
      ) {
        return selectAll(state, action);
      }
      return toggleSome(state, action);
    },
  },
});

export const {
  cleanupHolder,
  insertDocumentHolder,
  updateDocumentHolder,
  updateDocumentHoldersMetadata,
  updateDocumentHoldersVisibility,
  removeDocumentHolders,
  selectDocumentHolders,
} = slice.actions;

export default slice.reducer;
