import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledSharedScrollPanel } from 'components/styled-template';
import { StyledContainer, StyledLoader } from 'components/scrollable-panel-next-gen';
import useInfiniteScroll from '../hooks/use-infinite-scroll';
import LoaderEllipsis from './icons/loader-ellipsis';

const ScrollablePanelComponent = ({
  total,
  children,
  onInfiniteScroll,
  scrollReset,
  scrollLength,
  isLoadingScroll,
}) => {
  const [hasMore, setHasMore] = useState(false);

  const [page, loaderRef, scrollerRef] = useInfiniteScroll({ hasMore, reset: scrollReset });

  const onInfiniteScrollCb = useCallback(
    (size) => {
      if (onInfiniteScroll) {
        onInfiniteScroll(size);
      }
    },
    [onInfiniteScroll]
  );

  useEffect(() => {
    setHasMore(false);
    if (onInfiniteScroll) {
      setHasMore(page * scrollLength < total || page === 0);
    } else {
      setHasMore(false);
    }
  }, [page, total, onInfiniteScroll, scrollLength]);

  useEffect(() => {
    if (onInfiniteScroll) {
      onInfiniteScrollCb(page * scrollLength);
    }
  }, [onInfiniteScroll, onInfiniteScrollCb, page, scrollLength, total]);

  useEffect(() => {
    scrollerRef.current.scrollTop = 0;
  }, [scrollerRef, total]);

  return (
    <StyledContainer>
      <StyledSharedScrollPanel ref={scrollerRef}>
        {children}
        {!!onInfiniteScroll && (
          <>
            <StyledLoader aria-label="scroll loader" visible={isLoadingScroll}>
              <LoaderEllipsis height="10" width="40" />
            </StyledLoader>
            <StyledLoader aria-label="ref scroll loader" ref={loaderRef} visible={hasMore && !isLoadingScroll}>
              <LoaderEllipsis height="10" width="40" />
            </StyledLoader>
          </>
        )}
      </StyledSharedScrollPanel>
    </StyledContainer>
  );
};

ScrollablePanelComponent.propTypes = {
  total: PropTypes.number,
  onInfiniteScroll: PropTypes.func,
  isLoadingScroll: PropTypes.bool,
  scrollReset: PropTypes.bool,
  scrollLength: PropTypes.number,
};

ScrollablePanelComponent.defaultProps = {
  total: undefined,
  onInfiniteScroll: undefined,
  isLoadingScroll: false,
  scrollReset: false,
  scrollLength: 20,
};

export default ScrollablePanelComponent;
