import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetDocumentQuery } from 'common/api/backend/document-api';
import { useGetProjectConfigQuery } from 'common/api/backend/project-api';
import CommonOutlet from './common-outlet';

const DocumentDetailsOutlet = () => {
  const { projectSysId, documentSysId } = useParams();

  const { isSuccess, isLoading, isError } = useGetDocumentQuery({ projectSysId, documentSysId });
  const { isSuccess: isSuccessConfig } = useGetProjectConfigQuery({ projectSysId });

  return <CommonOutlet {...{ isSuccess: isSuccess && isSuccessConfig, isLoading, isError }} />;
};

export default DocumentDetailsOutlet;
