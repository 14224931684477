import React from 'react';
import { Breadcrumbs as DsBreadcrumbs } from '@datasoluce/ui-design-system-ts';
import { useMatches } from 'react-router-dom';
import config from 'app-config';

const { HIDE_CRUMBS } = config;

const Breadcrumbs = () => {
  const matches = useMatches();
  if (matches.some((match) => match.handle?.hideCrumbs === HIDE_CRUMBS.ALL)) {
    return null;
  }
  const crumbs = matches.filter((match) => Boolean(match.handle?.crumb)).map((match) => match.handle.crumb(match.data));
  if (matches.some((match) => match.handle?.hideCrumbs === HIDE_CRUMBS.LAST)) {
    crumbs.pop();
  }

  return <DsBreadcrumbs crumbs={crumbs} />;
};

export default Breadcrumbs;
