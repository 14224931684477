import actionTypes from './actionTypes';

export function setLoading(isLoading) {
  return {
    type: actionTypes.SET_LOADING,
    isLoading,
  };
}

export default { setLoading };
