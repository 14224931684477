import config from 'app-config';
import IconContainer from 'components/icon-container';
import ProjectModalRoot from 'components/project/modals/index';
import ProjectStatus from 'components/project/project-status';
import useModal from 'hooks/use-modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'theme';

const StyledProjectHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const { PROJECT_MODALS } = config;

const ProjectHeader = ({ project }) => {
  const { showModalMutation, modalType } = useModal();

  return (
    <>
      <StyledProjectHeaderContainer>
        {project.name}
        <IconContainer
          icon={modalType === PROJECT_MODALS.PROJECT_INFO ? 'info-contained' : 'info-outlined'}
          margin={theme.margin.m}
          fontSize={theme.fontSize.xxl}
          color={theme.color.veryDarkGreen}
          onClick={() => showModalMutation(PROJECT_MODALS.PROJECT_INFO, { project })}
          tooltip="Afficher les informations du projet"
        />
        <ProjectStatus project={project} />
      </StyledProjectHeaderContainer>
      <ProjectModalRoot />
    </>
  );
};

ProjectHeader.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectHeader;
