import React, { Suspense } from 'react';
import lazyWithRetry from 'utils/common/lazy-with-retry';
import Loader from 'components/loader';
import ProjectLayoutComponent from 'components/layout/project-layout';
import assemblyService from 'services/api/assembly';
import config from 'app-config';
import {
  fetchDocumentFailedMessage,
  getFolderFailedMessage,
  fetchProjectFailedMessage,
  getRoutesFailedMessage,
} from 'common/message/error-messages';
import DocumentOutlet from 'components/outlet/document-outlet';
import FolderOutlet from 'components/outlet/folder-outlet';
import TreeOutlet from 'components/outlet/tree-outlet';
import { Outlet, Link, Navigate, useOutletContext } from 'react-router-dom';
import DocumentDetailsOutlet from 'components/outlet/document-details-outlet';
import ProjectCrumb from 'components/project-crumb';
import MetadataTabContent from '../pages/manage-metadata/components/tab-content';

const ErrorPage = lazyWithRetry(() => import('pages/error'));

const ManageMetadataPage = lazyWithRetry(() => import('pages/manage-metadata'));
const ManageUsersPage = lazyWithRetry(() => import('pages/manage-users'));
const ManageProjectPage = lazyWithRetry(() => import('pages/manage-project'));
const CreateLinkConfigurationPage = lazyWithRetry(() => import('pages/create-link-configuration'));
const EdmPage = lazyWithRetry(() => import('pages/edm'));
const DocumentPanelDetailPage = lazyWithRetry(() => import('pages/document-panel-detail'));
const FolderPage = lazyWithRetry(() => import('pages/folder'));
const ManageFoldersPage = lazyWithRetry(() => import('pages/manage-folders'));
const AddDocumentHolderPage = lazyWithRetry(() => import('pages/add-doc-holder'));
const LinksPage = lazyWithRetry(() => import('pages/links'));
const AssemblyPage = lazyWithRetry(() => import('pages/assembly'));
const ExtractPage = lazyWithRetry(() => import('pages/extract'));
const DashboardPage = lazyWithRetry(() => import('pages/dashboard'));
const UpdateProjectPage = lazyWithRetry(() => import('pages/update-project'));
const UpdateProjectImagePage = lazyWithRetry(() => import('pages/update-project-image'));
const UpdateBuildingSummaryPage = lazyWithRetry(() => import('pages/update-building-summary'));
const UpdateBuildingSitePage = lazyWithRetry(() => import('pages/update-building-site'));
const UpdateBuildingContributors = lazyWithRetry(() => import('pages/update-building-contributors'));
const UpdateLinkConfigurationPage = lazyWithRetry(() => import('pages/update-link-configuration'));
const MultiUploadPage = lazyWithRetry(() => import('pages/multi-upload'));
const MemberListTab = lazyWithRetry(() => import('pages/manage-users/components/member-list'));
const GroupListTab = lazyWithRetry(() => import('pages/manage-users/components/group-list'));
const ExtractMetadataManagerTab = lazyWithRetry(() =>
  import('pages/manage-project/components/extract-metadata-manager')
);
const ExtractArticlesManagerTab = lazyWithRetry(() =>
  import('pages/manage-project/components/extract-articles-manager')
);
const EdmTab = lazyWithRetry(() => import('pages/edm/components/edm-tab'));

const { HIDE_CRUMBS } = config;

const DefaultProjectPage = () => {
  const { entryPath } = useOutletContext();
  return entryPath ? <Navigate to={entryPath} /> : null;
};

const projectRoutes = [
  {
    path: 'project/:projectSysId',
    element: <ProjectLayoutComponent />,
    children: [
      { index: true, element: <DefaultProjectPage /> },
      {
        element: <Outlet />,
        errorElement: <ErrorPage errorMessage={fetchProjectFailedMessage} />,
        handle: {
          crumb: () => <ProjectCrumb />,
        },
        children: [
          {
            index: true,
            path: 'dashboard',
            element: (
              <Suspense fallback={<Loader />}>
                <DashboardPage />
              </Suspense>
            ),
            handle: {
              hideCrumbs: HIDE_CRUMBS.LAST,
            },
          },
          {
            path: 'update-project',
            element: (
              <Suspense fallback={<Loader />}>
                <UpdateProjectPage />
              </Suspense>
            ),
            handle: { hideCrumbs: HIDE_CRUMBS.ALL },
          },
          {
            path: 'update-project-image',
            element: (
              <Suspense fallback={<Loader />}>
                <UpdateProjectImagePage />
              </Suspense>
            ),
            handle: { hideCrumbs: HIDE_CRUMBS.ALL },
          },
          {
            path: 'update-building-summary',
            element: (
              <Suspense fallback={<Loader />}>
                <UpdateBuildingSummaryPage />
              </Suspense>
            ),
            handle: { hideCrumbs: HIDE_CRUMBS.ALL },
          },
          {
            path: 'update-building-site',
            element: (
              <Suspense fallback={<Loader />}>
                <UpdateBuildingSitePage />
              </Suspense>
            ),
            handle: { hideCrumbs: HIDE_CRUMBS.ALL },
          },
          {
            path: 'update-building-contributors',
            element: (
              <Suspense fallback={<Loader />}>
                <UpdateBuildingContributors />
              </Suspense>
            ),
            handle: { hideCrumbs: HIDE_CRUMBS.ALL },
          },
          {
            path: 'update-link-configuration/:encodedLinkConfiguration',
            element: (
              <Suspense fallback={<Loader />}>
                <UpdateLinkConfigurationPage />
              </Suspense>
            ),
          },
          {
            path: 'edm',
            element: (
              <Suspense fallback={<Loader />}>
                <EdmPage />
              </Suspense>
            ),
            children: [
              { index: true, element: <Navigate to="current" replace /> },
              {
                path: 'current',
                element: (
                  <Suspense fallback={<Loader />}>
                    <EdmTab />
                  </Suspense>
                ),
              },
              {
                path: 'archive',
                element: (
                  <Suspense fallback={<Loader />}>
                    <EdmTab />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'folder',
            element: (
              <Suspense fallback={<Loader />}>
                <ManageFoldersPage />
              </Suspense>
            ),
          },
          {
            path: 'folder/:folderSysId',
            element: <FolderOutlet />,
            errorElement: <ErrorPage errorMessage={getFolderFailedMessage} />,
            handle: {
              crumb: () => (
                <Link to="../../folder" relative="path">
                  Dossiers
                </Link>
              ),
            },
            children: [
              {
                index: true,
                element: (
                  <Suspense fallback={<Loader />}>
                    <FolderPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'edm/document/:documentSysId',
            element: <DocumentOutlet errorMessage={fetchDocumentFailedMessage} />,
            children: [
              // navigate to edm
              { index: true, element: <Navigate to="../.." option={{ replace: true }} /> },
              {
                path: 'links',
                element: (
                  <Suspense fallback={<Loader />}>
                    <LinksPage />
                  </Suspense>
                ),
                handle: { hideCrumbs: HIDE_CRUMBS.ALL },
              },
            ],
          },
          {
            path: 'edm/add-document-holder',
            element: (
              <Suspense fallback={<Loader />}>
                <AddDocumentHolderPage />
              </Suspense>
            ),
            handle: {
              hideCrumbs: HIDE_CRUMBS.ALL,
            },
          },
          {
            path: 'edm/upload',
            element: (
              <Suspense fallback={<Loader />}>
                <MultiUploadPage />
              </Suspense>
            ),
            handle: { hideCrumbs: HIDE_CRUMBS.ALL },
          },
          {
            path: 'manage-project',
            errorElement: <ErrorPage />,
            element: (
              <Suspense fallback={<Loader />}>
                <ManageProjectPage />
              </Suspense>
            ),
            children: [
              { index: true, element: <Navigate to="settings-metadata" option={{ replace: true }} /> },
              {
                path: 'settings-metadata',
                element: (
                  <Suspense fallback={<Loader />}>
                    <ExtractMetadataManagerTab />
                  </Suspense>
                ),
              },
              {
                path: 'settings-articles',
                element: (
                  <Suspense fallback={<Loader />}>
                    <ExtractArticlesManagerTab />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'create-link-configuration',
            element: (
              <Suspense fallback={<Loader />}>
                <CreateLinkConfigurationPage />
              </Suspense>
            ),
            handle: { hideCrumbs: HIDE_CRUMBS.ALL },
          },
          {
            path: 'manage-metadata',
            element: (
              <Suspense fallback={<Loader />}>
                <ManageMetadataPage />
              </Suspense>
            ),
            children: [
              { index: true, element: <Navigate to="phase" /> },
              {
                path: 'phase',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MetadataTabContent />
                  </Suspense>
                ),
              },
              {
                path: 'emitter',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MetadataTabContent />
                  </Suspense>
                ),
              },
              {
                path: 'speciality',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MetadataTabContent />
                  </Suspense>
                ),
              },
              {
                path: 'zone',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MetadataTabContent />
                  </Suspense>
                ),
              },
              {
                path: 'level',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MetadataTabContent />
                  </Suspense>
                ),
              },
              {
                path: 'batch',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MetadataTabContent />
                  </Suspense>
                ),
              },
              {
                path: 'type',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MetadataTabContent />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'manage-users',
            element: (
              <Suspense fallback={<Loader />}>
                <ManageUsersPage />
              </Suspense>
            ),
            children: [
              { index: true, element: <Navigate to="members" option={{ replace: true }} /> },
              {
                path: 'members',
                element: (
                  <Suspense fallback={<Loader />}>
                    <MemberListTab />
                  </Suspense>
                ),
              },
              {
                path: 'members-groups',
                element: (
                  <Suspense fallback={<Loader />}>
                    <GroupListTab />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'bim/assembly',
            element: (
              <Suspense fallback={<Loader />}>
                <AssemblyPage />
              </Suspense>
            ),
          },
          {
            path: 'bim/:assemblySysId',
            element: <TreeOutlet />,
            loader: ({ params: { projectSysId, assemblySysId } }) =>
              assemblyService.getRoutes(projectSysId, assemblySysId),
            errorElement: <ErrorPage errorMessage={getRoutesFailedMessage} />,
            children: [
              // navigate to project
              { index: true, element: <Navigate to="../.." option={{ replace: true }} /> },
              {
                path: 'extract',
                element: (
                  <Suspense fallback={<Loader />}>
                    <ExtractPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    // this route is out of the edm/document routing because it has no project layout
    path: 'project/:projectSysId/edm/document/:documentSysId/details',
    element: <DocumentDetailsOutlet />,
    errorElement: <ErrorPage errorMessage={fetchDocumentFailedMessage} />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loader />}>
            <DocumentPanelDetailPage />
          </Suspense>
        ),
      },
    ],
  },
];

export default projectRoutes;
