import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconContainer from 'components/icon-container';

const SearchFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
`;

const SearchFieldInput = styled.input`
  box-sizing: border-box;
  height: ${({ theme }) => theme.height.l};
  width: 100%;
  border-radius: 0;
  padding: 0 ${({ theme }) => theme.padding.l} 0 0;
  color: ${({ theme }) => theme.color.text};
  font-size: ${({ theme }) => theme.fontSize.xs};
  background-color: ${({ theme }) => theme.color.white};
  outline: none;
  border: none;
  border-bottom: ${({ theme }) => theme.color.gray} 1px solid;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.color.gray};
  }
  ::-webkit-search-cancel-button {
    display: none;
  }
  &:focus {
    border-color: ${({ theme }) => theme.color.primary};
  }
  transition: all 200ms;
`;

const StyledIconWrapper = styled.div`
  margin-left: -18px;
  transform: rotate(${({ focused }) => (focused ? '0' : '90deg')});
  transition: all 200ms;
  span {
    color: ${({ theme, focused }) => (focused ? theme.color.primary : theme.color.gray)};
  }
`;

const StyledCrossButton = styled.div`
  margin-top: 5px;
  margin-left: -18px;
  span {
    color: ${({ theme }) => theme.color.gray};
  }
`;

const SearchField = ({
  placeholder,
  className,
  value,
  onSearch,
  resetSearch,
  ariaLabel,
  autoFocus,
  resetAutoFocus,
}) => {
  const [focused, setFocused] = useState(false);
  const searchFieldInput = useRef(null);

  useEffect(() => {
    if (searchFieldInput.current) {
      searchFieldInput.current.focus();
    }
  }, [resetAutoFocus]);

  return (
    <SearchFieldWrapper className={className}>
      <SearchFieldInput
        autoFocus={autoFocus}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={value}
        onChange={(event) => onSearch(event.target.value)}
        placeholder={placeholder}
        type="search"
        aria-label={ariaLabel}
        ref={searchFieldInput}
      />
      {!value ? (
        <StyledIconWrapper focused={focused}>
          <IconContainer icon="search" fontSize="16px" noPointer />
        </StyledIconWrapper>
      ) : (
        <StyledCrossButton>
          <IconContainer icon="cross" fontSize="16px" onClick={resetSearch} />
        </StyledCrossButton>
      )}
    </SearchFieldWrapper>
  );
};
SearchField.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onSearch: PropTypes.func,
  resetSearch: PropTypes.func,
  value: PropTypes.string,
  ariaLabel: PropTypes.string,
  autoFocus: PropTypes.bool,
  resetAutoFocus: PropTypes.string,
};

SearchField.defaultProps = {
  placeholder: null,
  className: null,
  onSearch: () => {},
  resetSearch: () => {},
  value: undefined,
  ariaLabel: 'search field',
  autoFocus: false,
  resetAutoFocus: undefined,
};

export default SearchField;
