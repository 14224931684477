export default {
  START_UPLOAD: 'START_UPLOAD',
  SET_UPLOAD_PROGRESS: 'SET_UPLOAD_PROGRESS',
  SET_UPLOAD_SUCCESS: 'SET_UPLOAD_SUCCESS',
  SET_UPLOAD_PREVIOUS: 'SET_UPLOAD_PREVIOUS',
  SET_UPLOAD_ERROR: 'SET_UPLOAD_ERROR',
  SET_UPLOAD_LOADING: 'SET_UPLOAD_LOADING',
  UPDATE_UPLOAD_LOADING: 'UPDATE_UPLOAD_LOADING',
  SET_UPLOAD_FILES: 'SET_UPLOAD_FILES',
};
