import actionTypes from './actionTypes';

const defaultState = {
  assemblies: [],
  currentAssembly: {},
};

function assemblyReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_ASSEMBLIES:
      return {
        ...state,
        assemblies:
          action.keepAttributes && state.assemblies.length
            ? action.assemblies.map((assembly) => ({
                ...assembly,
                isToggled: state.assemblies.find((oldAssembly) => oldAssembly.sysId === assembly.sysId)?.isToggled,
                models: state.assemblies.find((oldAssembly) => oldAssembly.sysId === assembly.sysId)?.models,
              }))
            : action.assemblies,
      };
    case actionTypes.SET_ASSEMBLY_MODELS:
      return {
        ...state,
        assemblies: state.assemblies.map((assembly) =>
          assembly.sysId === action.assemblySysId ? { ...assembly, models: action.models } : assembly
        ),
      };
    case actionTypes.RESET_ASSEMBLY_MODELS:
      return defaultState;
    case actionTypes.UPDATE_ASSEMBLIES:
      return {
        ...state,
        assemblies: state.assemblies.map((assembly) =>
          assembly.sysId === action.assembly.sysId ? { ...action.assembly, isToggled: assembly.isToggled } : assembly
        ),
      };
    case actionTypes.ADD_ASSEMBLY:
      return {
        ...state,
        assemblies: [action.assembly, ...state.assemblies],
      };
    case actionTypes.REMOVE_ASSEMBLY:
      return {
        ...state,
        assemblies: state.assemblies.filter((assembly) => assembly.sysId !== action.assemblySysId),
      };
    case actionTypes.SET_CURRENT_ASSEMBLY:
      return { ...state, currentAssembly: action.assembly };
    case actionTypes.TOGGLE_ASSEMBLY_MODELS:
      return {
        ...state,
        assemblies: state.assemblies.map((assembly) =>
          assembly.sysId === action.assemblySysId ? { ...assembly, isToggled: !assembly.isToggled } : assembly
        ),
      };
    default:
      return state;
  }
}

export default assemblyReducer;
