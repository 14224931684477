import ProjectInfoModal from 'components/project/modals/project-info-modal';
import useModal from 'hooks/use-modal';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ArchiveProjectModal from './archive-project-modal';
import PreviewModal from './preview-modal';

const PROJECT_MODAL_COMPONENTS = {
  PROJECT_INFO: ProjectInfoModal,
  PROJECT_ARCHIVE: ArchiveProjectModal,
  DOCUMENT_PREVIEW: PreviewModal,
};

const ProjectModalRoot = () => {
  const { modalType, modalProps, hideModalMutation } = useModal();
  const location = useLocation();

  // Close the modal when hash is cleared by back button for instance
  useEffect(() => {
    if (location.hash === '') {
      hideModalMutation();
    }
  }, [location, hideModalMutation]);

  if (!modalType) {
    return null;
  }

  const SpecificModal = PROJECT_MODAL_COMPONENTS[modalType];
  return SpecificModal ? <SpecificModal {...modalProps} /> : null;
};

export default ProjectModalRoot;
