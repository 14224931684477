import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import TextField from 'components/fields/text-field';

const TextFormField = ({
  name,
  type,
  className,
  placeholder,
  fullWidth,
  readOnly,
  onChange,
  autofilled,
  autoFocus,
  fitContent,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [field, { error, touched }, { setTouched }] = useField({ name, type });

  const onFieldFocus = () => {
    setFocus(true);
  };

  const onFieldBlur = () => {
    setTouched(true);
    setFocus(false);
  };

  const onFieldChange = (e) => {
    setTouched(true);
    field.onChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <TextField
      className={className}
      name={name}
      type={type}
      placeholder={placeholder}
      fullWidth={fullWidth}
      readOnly={readOnly}
      focus={focus}
      error={error}
      touched={touched}
      onChange={onFieldChange}
      onFocus={onFieldFocus}
      onBlur={onFieldBlur}
      autofilled={autofilled}
      value={field.value}
      autoFocus={autoFocus}
      fitContent={fitContent}
      {...props}
    />
  );
};

TextFormField.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  autofilled: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  fitContent: PropTypes.string,
};

TextFormField.defaultProps = {
  className: '',
  type: undefined,
  placeholder: null,
  fullWidth: false,
  readOnly: false,
  autofilled: false,
  autoFocus: false,
  onChange: undefined,
  fitContent: '',
};

export default TextFormField;
