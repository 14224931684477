import config from 'app-config';
import { DateTime, Interval } from 'luxon';

function buildDate(date) {
  let currentDate = DateTime.fromISO(date);
  if (Number.isInteger(date)) {
    currentDate = DateTime.fromMillis(date);
  }

  return currentDate.setLocale(config.LOCALE).setZone(config.TIMEZONE);
}
export function toDate(dateString = Date.now()) {
  return buildDate(dateString).toLocaleString(DateTime.DATE_SHORT);
}

export function getLocalOffset() {
  return DateTime.local().offset / 60;
}

export function toDateTime(dateString = Date.now()) {
  return buildDate(dateString).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
}

export function toUtcDate(dateString) {
  return DateTime.fromFormat(dateString, 'dd/MM/yyyy', { zone: 'utc' });
}

export function toStartOf(period = 'day', date) {
  if (date) {
    return DateTime.fromFormat(date, 'yyyy-MM-dd').startOf(period).toUTC();
  }

  return DateTime.now().startOf(period).toUTC();
}

export function toEndOf(period = 'day', date) {
  if (date) {
    return DateTime.fromFormat(date, 'yyyy-MM-dd').endOf(period).toUTC();
  }

  return DateTime.now().endOf(period).toUTC();
}

export function formatForDateInput(date) {
  return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
}

export const toTimeSpent = (date) => {
  if (!date) {
    return '';
  }
  const luxonDate = DateTime.fromISO(date, { setZone: true }).setLocale(config.LOCALE).setZone(config.TIMEZONE);
  const inter = (delay) => Math.trunc(Interval.fromDateTimes(luxonDate, DateTime.now()).length(delay));
  if (inter('weeks') >= 1) {
    return `Le ${luxonDate.toLocaleString(DateTime.DATETIME_MED)}`;
  }
  if (inter('days') >= 2) {
    return `Il y a ${inter('days')} jours à ${luxonDate.toFormat('HH:mm')}`;
  }
  if (inter('days') === 1) {
    return `Hier à ${luxonDate.toFormat('HH:mm')}`;
  }
  if (inter('hours') >= 1) {
    return `Il y a ${inter('hours')} heure${inter('hours') !== 1 ? 's' : ''}`;
  }
  if (inter('minutes') >= 1) {
    return `Il y a ${inter('minutes')} minute${inter('minutes') !== 1 ? 's' : ''}`;
  }
  if (inter('seconds') > 1) {
    return `Il y a ${inter('seconds')} secondes`;
  }
  return `Il y a 1 seconde`;
};

export const toDateSpent = (date) => {
  if (!date) {
    return '';
  }
  const luxonDate = DateTime.fromISO(date, { setZone: true }).toLocal();
  const inter = (delay) => Math.trunc(Interval.fromDateTimes(luxonDate, DateTime.now()).length(delay));

  if (inter('weeks') >= 1) {
    return `${luxonDate.toFormat('dd/MM/yyyy')}`;
  }
  if (inter('days') >= 2) {
    return `Il y a ${inter('days')} jours`;
  }
  if (inter('days') === 1) {
    return `Hier`;
  }
  if (inter('hours') >= 1) {
    return `Il y a ${inter('hours')} heure${inter('hours') !== 1 ? 's' : ''}`;
  }
  if (inter('minutes') >= 1) {
    return `Il y a ${inter('minutes')} minute${inter('minutes') !== 1 ? 's' : ''}`;
  }
  if (inter('seconds') > 1) {
    return `Il y a ${inter('seconds')} secondes`;
  }
  return `Il y a 1 seconde`;
};

export const isFirstDateBeforeSecond = (date1, date2) => {
  return DateTime.fromISO(date1) < DateTime.fromISO(date2);
};
