import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconContainer from 'components/icon-container';

const StyledWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  cursor: ${({ editable }) => (editable ? 'pointer' : 'auto')};
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding: ${({ theme, hover }) => (hover ? `${theme.margin.xs} 0` : '0')};

  :hover {
    background: ${({ theme, hover }) => (hover ? theme.color.veryLightBlue : 'inherit')};
  }
  width: 100%;
`;

const StyledCheckboxContainer = styled.div`
  flex: 0 0 16px;
  justify-content: center;
  align-items: baseline;
  display: flex;
  min-width: 0;
  min-height: 0;
`;

const StyledCheckbox = styled.div.attrs(({ selected, editable }) => ({
  'data-selected': selected,
  'data-editable': editable,
}))`
  width: ${({ theme, width }) => width || theme.width.l};
  height: ${({ theme, width }) => width || theme.width.l};
  border-radius: ${({ theme, className }) => (className && className.includes('round') ? '100%' : theme.radius.xs)};
  background-color: ${({ theme, selected, backgroundColor, editable }) => {
    const background = selected ? backgroundColor : theme.color.white;
    return editable ? background : theme.color.buttonDisabled;
  }};
  border: solid 1px
    ${({ theme, noBorder, selected, backgroundColor, editable }) => {
      const border = noBorder ? theme.color.white : theme.color.black;
      const background = selected ? backgroundColor : border;
      return editable ? background : theme.color.gray;
    }};
`;

const StyledLabel = styled.div`
  margin-left: ${({ theme }) => theme.padding.s};
  font-size: ${({ theme }) => theme.fontSize.l};
  display: flex;
  flex: 1 0 calc(100% - 26px);
  align-items: center;
  overflow: hidden;
`;

const Checkbox = ({ children, name, className, backgroundColor, selected, noBorder, editable, onClick, hover }) => {
  const onCheckClick = (e) => {
    if (editable) {
      onClick(e);
    }
  };

  return (
    <StyledWrapper hover={hover} onClick={onCheckClick} editable={editable}>
      <StyledCheckboxContainer>
        <StyledCheckbox
          aria-label={name ? `checkbox ${name}` : 'checkbox'}
          noBorder={noBorder}
          className={className}
          backgroundColor={backgroundColor}
          selected={selected}
          editable={editable}>
          {selected && <IconContainer icon="new-check" color="white" noPointer={!editable} />}
        </StyledCheckbox>
      </StyledCheckboxContainer>
      {children && <StyledLabel className={className}>{children}</StyledLabel>}
    </StyledWrapper>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  backgroundColor: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  editable: PropTypes.bool,
  noBorder: PropTypes.bool,
  hover: PropTypes.bool,
};

Checkbox.defaultProps = {
  className: '',
  name: null,
  backgroundColor: '#3ac596',
  selected: false,
  onClick: () => {},
  editable: true,
  noBorder: false,
  hover: false,
};

export default Checkbox;
