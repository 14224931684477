import lazyWithRetry from 'utils/common/lazy-with-retry';

const LoginPage = lazyWithRetry(() => import('pages/login'));
const ResetPasswordPage = lazyWithRetry(() => import('pages/reset-password'));
const CguPage = lazyWithRetry(() => import('pages/cgu'));

const publicRoutes = [
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: '/cgu',
    element: <CguPage />,
  },
];

export default publicRoutes;
