import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IconContainer from 'components/icon-container';
import { LinearProgressWithLabel } from '@datasoluce/ui-design-system-ts';

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.color.text};
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.fontWeight.semi};
  padding-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 0;
`;

const StyledText = styled.div`
  color: ${({ theme }) => theme.color.Text};
  font-size: ${({ theme }) => theme.fontSize.s};
`;

const ActionItem = styled.div`
  display: flex;
  justify-content: center;
`;

const DeleteIcon = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
  border-left-style: solid;
  border-width: 1px;
  border-color: ${({ theme }) => theme.color.lightGrey};
  padding: 10px;

  :hover {
    background-color: ${({ theme }) => theme.color.lightGrey};
  }
  span {
    color: ${({ theme }) => theme.color.gray};
  }
`;

const UploadProgressComponent = ({ progress, title, status, onClickRemove }) => (
  <Container>
    {status && (
      <Wrapper>
        <StyledTitle title={title}>{title}</StyledTitle>
        {status === 'loading' && <LinearProgressWithLabel value={progress} />}
        {(status === 'success' || status === 'previous') && <StyledText>Téléchargement terminé</StyledText>}
        {status === 'error' && <StyledText>Téléchargement en échec</StyledText>}
      </Wrapper>
    )}
    <ActionItem>
      {status !== 'loading' && (
        <DeleteIcon>
          <IconContainer onClick={onClickRemove} icon="cross" fontSize="20px" />
        </DeleteIcon>
      )}
    </ActionItem>
  </Container>
);

UploadProgressComponent.propTypes = {
  progress: PropTypes.number.isRequired,
  title: PropTypes.string,
  status: PropTypes.string.isRequired,
  onClickRemove: PropTypes.func.isRequired,
};

UploadProgressComponent.defaultProps = {
  title: undefined,
};

export default UploadProgressComponent;
