import { createSlice } from '@reduxjs/toolkit';

import projectApi from 'common/api/backend/project-api';

const initialState = {
  highlightProject: {},
};

const slice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    highlightProject: (state, action) => {
      return { ...state, highlightProject: action.payload };
    },
    unhighlightProject: (state) => {
      return { ...state, highlightProject: {} };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(projectApi.endpoints.updateProject.matchFulfilled, (state, { payload }) =>
      state.highlightProject.sysId === payload.sysId ? { ...state, highlightProject: payload } : state
    );
    builder.addMatcher(projectApi.endpoints.restoreProject.matchFulfilled, (state, { payload }) =>
      state.highlightProject.sysId === payload.sysId ? { ...state, highlightProject: {} } : state
    );
    builder.addMatcher(projectApi.endpoints.archiveProject.matchFulfilled, (state, { payload }) =>
      state.highlightProject.sysId === payload.sysId ? { ...state, highlightProject: {} } : state
    );
  },
});

export const { highlightProject, unhighlightProject } = slice.actions;

export default slice.reducer;
