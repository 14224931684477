import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconContainer from 'components/icon-container';

const StyledAddRoleContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ enabled }) => (enabled ? 'cursor: pointer' : '')};
`;

const StyledAddIcon = styled.div`
  background-color: ${({ enabled, theme }) => (enabled ? theme.color.blue : theme.color.gray)};
  border-radius: 4px;
  span {
    color: ${({ theme }) => theme.color.white};
  }
  user-select: none;
`;

const StyledLabel = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semi};
  color: ${({ theme }) => theme.color.black};
  margin-left: 10px;
  user-select: none;
`;

const AddButton = ({ onClick, label, size, enabled, tooltip, className }) => (
  <StyledAddRoleContainer
    className={className}
    title={tooltip}
    enabled={enabled}
    onClick={() => {
      if (enabled) {
        onClick();
      }
    }}>
    <StyledAddIcon enabled={enabled}>
      <IconContainer icon="plus" fontSize={size} />
    </StyledAddIcon>
    <StyledLabel>{label}</StyledLabel>
  </StyledAddRoleContainer>
);

AddButton.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  enabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

AddButton.defaultProps = {
  enabled: true,
  size: '18px',
  className: '',
};

export default AddButton;
