import React, { useCallback, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Snackbar } from '@datasoluce/ui-design-system-ts';
import { useNavigate } from 'react-router-dom';
import { removeMessage } from '../common/slice/message';

export const SnackbarComponent = ({ messageQueue, removeMessageAction }) => {
  const [isActive, setIsActive] = useState(false);
  const [current, setCurrent] = useState(null);

  const navigate = useNavigate();

  const processQueue = useCallback(() => {
    if (messageQueue.length > 0) {
      setCurrent(messageQueue[0]);
      setIsActive(true);
      removeMessageAction(messageQueue[0].key);
    }
  }, [messageQueue, removeMessageAction]);

  useEffect(() => {
    if (!isActive && messageQueue.length > 0) {
      processQueue();
    }
  }, [messageQueue, processQueue, isActive]);

  return (
    <Snackbar
      open={isActive}
      title={current?.options?.title}
      severity={current?.level}
      actionLabel={current?.options?.action?.label || (current?.options?.action?.path ? 'Consulter' : 'Fermer')}
      message={current?.message}
      onClose={() => {
        setIsActive(false);
        processQueue();
      }}
      onAction={() => {
        setIsActive(false);
        if (current?.options?.action?.path) {
          navigate(current.options.action.path);
        }
        if (current?.options?.action?.onClick) {
          current.options.action.onClick();
        }

        processQueue();
      }}
    />
  );
};

const mapStateToProps = ({ messageSlice: { messageQueue } }) => ({
  messageQueue,
});

export default connect(mapStateToProps, {
  removeMessageAction: removeMessage,
})(SnackbarComponent);
