import actionTypes from './actionTypes';
import { ROOM_TYPE } from '../../pages/extract/components/viewer/utils/helper';

const defaultState = {
  models: [],
};

function extractReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_VIEWER_MODELS:
      return { ...state, models: action.models };
    case actionTypes.EMPTY_MODELS:
      return { ...state, models: [] };
    case actionTypes.TOGGLE_MODEL:
      return {
        ...state,
        models: state.models.map((model) =>
          model.revisionSysId === action.revisionSysId && model.type !== ROOM_TYPE
            ? { ...model, visible: !model.visible }
            : model
        ),
      };
    default:
      return state;
  }
}

export default extractReducer;
