export default {
  SET_REF_ASSEMBLIES: 'SET_REF_ASSEMBLIES',
  SET_CURRENT_REF_ASSEMBLY_SYS_ID: 'SET_CURRENT_REF_ASSEMBLY_SYS_ID',
  SET_BUILDINGS: 'SET_BUILDINGS',
  SET_SELECTED_BUILDINGS: 'SET_SELECTED_BUILDINGS',
  ADD_SELECTED_BUILDING: 'ADD_SELECTED_BUILDING',
  REMOVE_SELECTED_BUILDING: 'REMOVE_SELECTED_BUILDING',
  SET_LEVELS: 'SET_LEVELS',
  SET_SELECTED_LEVELS: 'SET_SELECTED_LEVELS',
  ADD_SELECTED_LEVEL: 'ADD_SELECTED_LEVEL',
  REMOVE_SELECTED_LEVEL: 'REMOVE_SELECTED_LEVEL',
  SET_ROOMS: 'SET_ROOMS',
  SET_SELECTED_ROOMS: 'SET_SELECTED_ROOMS',
  ADD_SELECTED_ROOM: 'ADD_SELECTED_ROOM',
  REMOVE_SELECTED_ROOM: 'REMOVE_SELECTED_ROOM',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_SELECTED_CATEGORIES: 'SET_SELECTED_CATEGORIES',
  ADD_SELECTED_CATEGORY: 'ADD_SELECTED_CATEGORY',
  REMOVE_SELECTED_CATEGORY: 'REMOVE_SELECTED_CATEGORY',
  ADD_SELECTED_TYPE: 'ADD_SELECTED_TYPE',
  SET_SELECTED_TYPES: 'SET_SELECTED_TYPES',
  REMOVE_SELECTED_TYPE: 'REMOVE_SELECTED_TYPE',
  SET_TYPES: 'SET_TYPES',
  SET_SELECTED_LINKS: 'SET_SELECTED_LINKS',
  RESET_REF_ASSEMBLY: 'RESET_REF_ASSEMBLY',
  RESET_SELECTED_LINKS: 'RESET_SELECTED_LINKS',
};
