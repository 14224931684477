import actionTypes from './actionTypes';

const defaultState = {
  refAssemblies: [],
  currentRefAssemblySysId: '',
  buildings: [],
  totalBuildings: 0,
  selectedBuildings: [],
  levels: [],
  totalLevels: 0,
  selectedLevels: [],
  rooms: [],
  totalRooms: 0,
  selectedRooms: [],
  categories: [],
  totalCategories: 0,
  selectedCategories: [],
  types: [],
  totalTypes: 0,
  selectedTypes: [],
  scroll: 20,
};

function refAssemblyReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_REF_ASSEMBLIES:
      return { ...state, refAssemblies: action.refAssemblies };
    case actionTypes.SET_CURRENT_REF_ASSEMBLY_SYS_ID:
      return state.currentRefAssemblySysId === action.currentRefAssemblySysId
        ? {
            ...defaultState,
            currentRefAssemblySysId: action.currentRefAssemblySysId,
            refAssemblies: state.refAssemblies,
            selectedBuildings: state.selectedBuildings,
            selectedLevels: state.selectedLevels,
            selectedRooms: state.selectedRooms,
            selectedCategories: state.selectedCategories,
            selectedTypes: state.selectedTypes,
          }
        : {
            ...defaultState,
            currentRefAssemblySysId: action.currentRefAssemblySysId,
            refAssemblies: state.refAssemblies,
          };
    case actionTypes.SET_BUILDINGS:
      return { ...state, buildings: action.buildings, totalBuildings: action.totalBuildings };
    case actionTypes.ADD_SELECTED_BUILDING:
      return { ...state, selectedBuildings: [...state.selectedBuildings, action.building] };
    case actionTypes.REMOVE_SELECTED_BUILDING:
      return {
        ...state,
        selectedBuildings: state.selectedBuildings.filter((building) => building.id !== action.building.id),
      };
    case actionTypes.SET_LEVELS:
      return { ...state, levels: action.levels, totalLevels: action.totalLevels };
    case actionTypes.ADD_SELECTED_LEVEL:
      return { ...state, selectedLevels: [...state.selectedLevels, action.level] };
    case actionTypes.REMOVE_SELECTED_LEVEL:
      return {
        ...state,
        selectedLevels: state.selectedLevels.filter((level) => level.id !== action.level.id),
      };
    case actionTypes.SET_ROOMS:
      return { ...state, rooms: action.rooms, totalRooms: action.totalRooms };
    case actionTypes.ADD_SELECTED_ROOM:
      return { ...state, selectedRooms: [...state.selectedRooms, action.room] };
    case actionTypes.REMOVE_SELECTED_ROOM:
      return {
        ...state,
        selectedRooms: state.selectedRooms.filter((room) => room.id !== action.room.id),
      };
    case actionTypes.SET_CATEGORIES:
      return { ...state, categories: action.categories, totalCategories: action.totalCategories };
    case actionTypes.ADD_SELECTED_CATEGORY:
      return { ...state, selectedCategories: [...state.selectedCategories, action.category] };
    case actionTypes.REMOVE_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategories: state.selectedCategories.filter((category) => category.id !== action.category.id),
      };
    case actionTypes.SET_TYPES:
      return { ...state, types: action.types, totalTypes: action.totalTypes };
    case actionTypes.ADD_SELECTED_TYPE:
      return { ...state, selectedTypes: [...state.selectedTypes, action.eltType] };
    case actionTypes.REMOVE_SELECTED_TYPE:
      return {
        ...state,
        selectedTypes: state.selectedTypes.filter((type) => type.id !== action.eltType.id),
      };
    case actionTypes.SET_SELECTED_BUILDINGS: {
      return { ...state, selectedBuildings: action.selectedBuildings };
    }
    case actionTypes.SET_SELECTED_LEVELS: {
      return { ...state, selectedLevels: action.selectedLevels };
    }
    case actionTypes.SET_SELECTED_ROOMS: {
      return { ...state, selectedRooms: action.selectedRooms };
    }
    case actionTypes.SET_SELECTED_CATEGORIES: {
      return { ...state, selectedCategories: action.selectedCategories };
    }
    case actionTypes.SET_SELECTED_TYPES: {
      return { ...state, selectedTypes: action.selectedTypes };
    }
    case actionTypes.RESET_REF_ASSEMBLY:
      return defaultState;
    case actionTypes.RESET_SELECTED_LINKS:
      return {
        ...state,
        selectedBuildings: [],
        selectedLevels: [],
        selectedRooms: [],
        selectedCategories: [],
        selectedTypes: [],
      };
    default:
      return state;
  }
}

export default refAssemblyReducer;
