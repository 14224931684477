import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLoaderData } from 'react-router-dom';
import { loadTree } from 'common/tree/actions';

const TreeOutlet = () => {
  const tree = useLoaderData();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadTree(tree));
  }, [dispatch, tree]);

  return <Outlet />;
};

export default TreeOutlet;
