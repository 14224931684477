import api from '../apiCaller';

function createRevision(projectSysId, documentSysId, file, progFunc = undefined, successFunc = undefined) {
  return api.postResumable(
    `/api/project/${projectSysId}/document/${documentSysId}/revision/UploadChunk`,
    file,
    progFunc,
    successFunc
  );
}

export default { createRevision };
