import { css, keyframes } from 'styled-components';

export const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  0% {
    background-color: rgba(0, 0, 0, 0.5);
  }
  100% {
    transform: rgba(0, 0, 0, 0.9);
  }
`;

export const fadeInAnimation = () =>
  css`
    ${fadeIn};
  `;
