import React, { useRef } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import DragIndicator from 'components/icons/svg/drag-indicator.svg';

const StyledDragElementWrapper = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'grab')};
  cursor: ${({ disabled }) => (disabled ? 'auto' : '-webkit-grab')};

  opacity: ${({ opacity }) => opacity};
  position: relative;
  padding-left: ${({ theme }) => theme.margin.l};

  ${({ hover, disabled }) => {
    return (
      hover &&
      !disabled &&
      `&:hover {
        &:before {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 13px;
          height: 20px;
          content: '';
          background-image: url(${DragIndicator});
        }
      }`
    );
  }}
`;

const DraggableElementComponent = ({
  id,
  index,
  accept,
  disabled,
  hover,
  enableHover,
  moveElement,
  onDropElement,
  children,
  className,
}) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current || disabled) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveElement(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: accept,
    item: () => {
      enableHover(false);
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return !disabled;
    },
    end: () => {
      enableHover(true);
      onDropElement();
    },
  });

  drag(drop(ref));

  return (
    <StyledDragElementWrapper
      className={className}
      ref={ref}
      opacity={isDragging ? 0 : 1}
      disabled={disabled}
      data-handler-id={handlerId}
      hover={hover}>
      {children}
    </StyledDragElementWrapper>
  );
};

DraggableElementComponent.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  accept: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  moveElement: PropTypes.func.isRequired,
  onDropElement: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};

DraggableElementComponent.defaultProps = {
  disabled: false,
};

export default DraggableElementComponent;
