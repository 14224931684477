import { ButtonIcon, ButtonGroup } from '@datasoluce/ui-design-system-ts';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useProjectMenuEntries from 'hooks/use-project-menu-entries';
import ProjectInfoContent from 'components/project/project-info-content';
import Modal from 'components/modal';
import MultiActionButton from 'components/buttons/multi-action-button';
import { useParams } from 'react-router-dom';
import { StyledSharedBreakWordTitleTwo } from 'components/styled-template';
import useModal from 'hooks/use-modal';
import { copyLinkAddress } from 'utils/common/utils';

const StyledModalButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.padding.xs};
`;

const ProjectInfoModal = ({ project }) => {
  const { organizationSysId } = useParams();
  const { hideModalMutation } = useModal();
  const dispatch = useDispatch();

  const modalButtons = [
    {
      title: 'Copier le lien du projet',
      icon: 'link',
      onClick: () =>
        copyLinkAddress(
          `organization/${organizationSysId}/project/${project.sysId}`,
          'projet',
          window.location,
          dispatch
        ),
      hasAuthorization: true,
    },
    {
      title: 'Masquer les informations du projet',
      icon: 'cross',
      onClick: () => hideModalMutation(),
      hasAuthorization: true,
    },
  ];

  const [menuEntries] = useProjectMenuEntries({ organizationSysId, project });

  return (
    <Modal size="sm" onRequestClose={() => hideModalMutation()} isOpen>
      <StyledModalButtonGroup>
        {menuEntries.length > 0 && (
          <MultiActionButton
            title="Autres actions"
            alignment="bottom-right"
            actions={menuEntries.map((menuEntry) => ({
              ...menuEntry,
              onClick: () => {
                hideModalMutation();
                menuEntry.onClick();
              },
            }))}
            size="medium"
            variant="action"
          />
        )}
        {modalButtons
          .filter((item) => item.hasAuthorization)
          .map((button, idx) => (
            <ButtonIcon
              key={idx}
              title={button.title}
              icon={button.icon}
              className={button.className}
              variant="action"
              size="medium"
              onClick={(e) => button.onClick(e)}
            />
          ))}
      </StyledModalButtonGroup>
      <StyledSharedBreakWordTitleTwo>{project.name}</StyledSharedBreakWordTitleTwo>
      <ProjectInfoContent projectDetails={project} />
    </Modal>
  );
};

ProjectInfoModal.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ProjectInfoModal;
