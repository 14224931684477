export const DEFAULT_COLOR = '#e7387a';

export const CUSTOM_PROPAGATE = 'CUSTOM_PROPAGATE';
export const CUSTOM = 'CUSTOM';
export const DEFAULT = 'DEFAULT';
export const INHERITED = 'INHERITED';

export default {
  DEFAULT_COLOR,
  CUSTOM_PROPAGATE,
  CUSTOM,
  DEFAULT,
  INHERITED,
};
