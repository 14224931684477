import { isRejectedWithValue } from '@reduxjs/toolkit';
import { backendServiceApi } from 'common/api/backend-service-api';
import { revokeUserSession } from 'common/slice/session';

const unauthorizedQueryErrorHandler = ({ dispatch, getState }) => (next) => (action) => {
  const state = getState();
  if (
    isRejectedWithValue(action) &&
    action?.payload?.originalStatus === 401 &&
    state?.sessionSlice?.session?.state === 'CONNECTED'
  ) {
    dispatch(revokeUserSession());
    dispatch(backendServiceApi.util.resetApiState());
  }
  return next(action);
};

export default unauthorizedQueryErrorHandler;
