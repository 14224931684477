const errorLogger = () => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    console.trace('Caught an exception!', err);
    throw err;
  }
};

export default errorLogger;
