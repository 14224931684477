import { BE_TYPE, backendServiceApi } from 'common/api/backend-service-api';
import {
  createMetadataFailedMessage,
  deleteMetadataFailedMessage,
  fetchFormatedMetadataFailedMessage,
  reorderMetadataFailedMessage,
  updateMetadataFailedMessage,
} from 'common/message/error-messages';
import { dispatchMessageConfiguration } from 'common/api/utils';

const metadataApi = backendServiceApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getMetadataDefinition: build.query({
      query: ({ projectSysId }) => ({
        url: `/project/${projectSysId}/metadataDefinitions`,
      }),
      transformResponse: (response) => {
        return {
          metadataDefinition: response,
          metadataDisplayNames: response.map((definition) => ({
            metadataPath: definition.code,
            displayName: `${definition.value} (${definition.code.split('-').pop()})`,
          })),
        };
      },
    }),
    getMetadata: build.query({
      query: ({ projectSysId }) => ({
        url: `/project/${projectSysId}/metadatas`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchFormatedMetadataFailedMessage }) }),
      providesTags: () => [{ type: BE_TYPE.METADATA, id: 'LIST' }],
    }),
    // MUTATION
    createMetadata: build.mutation({
      query: ({ projectSysId, path, data }) => {
        return {
          url: `/project/${projectSysId}/metadata/${path}`,
          method: 'POST',
          body: data,
        };
      },
      ...dispatchMessageConfiguration({
        error: ({ path, err }) => ({ message: createMetadataFailedMessage(err.error.data.code, path) }),
      }),
      invalidatesTags: () => [{ type: BE_TYPE.METADATA, id: 'LIST' }],
    }),
    updateMetadata: build.mutation({
      query: ({ projectSysId, path, data }) => {
        return {
          url: `/project/${projectSysId}/metadata/${path}`,
          method: 'PUT',
          body: data,
        };
      },
      ...dispatchMessageConfiguration({ error: () => ({ message: updateMetadataFailedMessage }) }),
      invalidatesTags: (result, error) =>
        error
          ? []
          : [
              { type: BE_TYPE.METADATA, id: 'LIST' },
              { type: BE_TYPE.DOCUMENT, id: 'LIST' },
              { type: BE_TYPE.EVENT, id: 'LIST' },
            ],
    }),
    reorderMetadata: build.mutation({
      query: ({ projectSysId, path, metadata }) => {
        return {
          url: `/project/${projectSysId}/metadatas/${path}`,
          method: 'PUT',
          body: metadata,
        };
      },
      ...dispatchMessageConfiguration({ error: () => ({ message: reorderMetadataFailedMessage }) }),
      invalidatesTags: () => [{ type: BE_TYPE.METADATA, id: 'LIST' }],
    }),
    deleteMetadata: build.mutation({
      query: ({ projectSysId, path }) => {
        return {
          url: `/project/${projectSysId}/metadata/${path}`,
          method: 'DELETE',
        };
      },
      ...dispatchMessageConfiguration({
        error: ({ path, err }) => ({ message: deleteMetadataFailedMessage(err.error.data.code, path) }),
      }),
      invalidatesTags: () => [{ type: BE_TYPE.METADATA, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetMetadataDefinitionQuery,
  useGetMetadataQuery,
  useCreateMetadataMutation,
  useUpdateMetadataMutation,
  useReorderMetadataMutation,
  useDeleteMetadataMutation,
} = metadataApi;

export default metadataApi;
