import { Layout } from '@datasoluce/ui-design-system-ts';
import { Outlet } from 'react-router-dom';
import Header from '../components/header';

const UserActivationLayoutComponent = () => {
  return (
    <Layout menuExpanded={false} headerSlot={<Header titleSlot="Activation de votre compte" />}>
      <Outlet />
    </Layout>
  );
};

export default UserActivationLayoutComponent;
