import { useLocation, useNavigate } from 'react-router-dom';
import useUserSession from 'hooks/use-user-session';
import LayoutComponent from '../components/layout';

const UserLayoutComponent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isSuccess, data: user } = useUserSession();
  return (
    isSuccess && (
      <LayoutComponent
        navEntries={[
          {
            label: 'Accueil',
            icon: 'gallery',
            path: '/projects',
            hasAuthorization: user.capabilities.canReadProjects,
          },
          {
            label: 'Organisations',
            icon: 'building',
            path: '/organizations',
            hasAuthorization: user.capabilities.canReadOrganizations,
          },
        ]
          .filter((item) => item.hasAuthorization)
          .map((entry) => {
            const { path, ...rest } = entry;
            return {
              ...rest,
              active: pathname.includes(path),
              onNavigate: () => navigate(path),
            };
          })}
      />
    )
  );
};

export default UserLayoutComponent;
