import { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { showModal, hideModal } from 'common/modal/actions';

const useModal = () => {
  const dispatch = useDispatch();

  const modalType = useSelector((state) => state.modal.modalType, shallowEqual);
  const modalProps = useSelector((state) => state.modal.modalProps, shallowEqual);

  const showModalMutation = useCallback(
    (type, props) => {
      dispatch(showModal(type, props));
    },
    [dispatch]
  );

  const hideModalMutation = useCallback(() => {
    dispatch(hideModal());
  }, [dispatch]);

  return {
    modalType,
    modalProps,
    showModalMutation,
    hideModalMutation,
  };
};

export default useModal;
