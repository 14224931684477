import { backendServiceApi, BE_TYPE } from 'common/api/backend-service-api';
import {
  updateProfileSuccessMessage,
  updateProfileFailedMessage,
  updatePasswordSuccessMessage,
  updatePasswordFailedMessage,
} from 'common/message/error-messages';
import { dispatchMessageConfiguration } from '../utils';

const sessionApi = backendServiceApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    login: build.mutation({
      query: ({ data }) => ({
        url: '/login',
        method: 'POST',
        body: data,
      }),
    }),
    logout: build.mutation({
      query: () => ({
        url: '/login/out',
        method: 'POST',
        responseHandler: 'text',
      }),
    }),
    currentSession: build.query({
      query: () => ({
        url: '/user/current',
      }),
      transformResponse: (response) => ({
        ...response,
        fullName: `${response.firstName && response.lastName ? `${response.firstName} ${response.lastName}` : ''}`,
      }),
      providesTags: () => [{ type: BE_TYPE.CURRENT_USER }],
      keepUnusedDataFor: 0,
    }),
    resetPassword: build.mutation({
      query: ({ data }) => ({
        url: '/login/resetpassword',
        method: 'POST',
        body: data,
      }),
    }),
    activate: build.mutation({
      query: ({ userSysId, data }) => ({
        url: `/user/${userSysId}/activate`,
        method: 'POST',
        body: data,
      }),
      ...dispatchMessageConfiguration({
        error: () => ({ message: updatePasswordFailedMessage }),
        success: () => ({ message: updatePasswordSuccessMessage }),
      }),
    }),
    updateProfile: build.mutation({
      query: ({ data }) => ({
        url: '/user/current',
        method: 'PATCH',
        body: data,
      }),
      ...dispatchMessageConfiguration({
        error: () => ({ message: updateProfileFailedMessage }),
        success: () => ({ message: updateProfileSuccessMessage }),
      }),
      invalidatesTags: (response) =>
        response
          ? [
              { type: BE_TYPE.ORGANIZATION_USERS, id: response.sysId },
              { type: BE_TYPE.PROJECT_USERS, id: response.sysId },
            ]
          : [],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useCurrentSessionQuery,
  useResetPasswordMutation,
  useActivateMutation,
  useUpdateProfileMutation,
} = sessionApi;

export default sessionApi;
