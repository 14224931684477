import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledFakeInput = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.md};
`;

export const StyledFakeLabel = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.color.grey};
  line-height: 18px;
  min-height: 18px;
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledFakeValue = styled.div`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.fontSize.m};
  line-height: 17px;
  overflow: ${({ wordBreak }) => !wordBreak && 'hidden'};
  text-overflow: ${({ wordBreak }) => !wordBreak && 'ellipsis'};
  white-space: ${({ wordBreak }) => !wordBreak && 'nowrap;'};
`;

const FakeInput = ({ label, value, wordBreak }) => (
  <StyledFakeInput>
    <StyledFakeLabel>{label}</StyledFakeLabel>
    <StyledFakeValue title={value} wordBreak={wordBreak}>
      {value}
    </StyledFakeValue>
  </StyledFakeInput>
);

FakeInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  wordBreak: PropTypes.bool,
};

FakeInput.defaultProps = {
  wordBreak: false,
};

export default FakeInput;
