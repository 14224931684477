import { useCallback, useState } from 'react';
import useDebounce from './use-debounce';

const useSearch = () => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);
  const resetSearch = useCallback(() => setSearch(''), []);
  const onSearch = useCallback((value) => setSearch(value), []);
  return { search, debouncedSearch, onSearch, resetSearch };
};

export default useSearch;
