import { ButtonIcon } from '@datasoluce/ui-design-system-ts';
import PdfViewerComponent from 'components/explorer/pdf-viewer';
import { ESC_KEY } from 'hooks/use-key-press';
import useModal from 'hooks/use-modal';
import PropTypes from 'prop-types';
import { StyledSharedScrollPanel } from 'components/styled-template';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledFullModal = styled.div`
  display: flex;
  position: absolute;
  background: ${({ theme }) => theme.colors.lightGreen};
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 50;
`;

const StyledPanel = styled.div`
  display: flex;
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 30px;
  gap: 10px;
`;

const StyledScrollPanel = styled(StyledSharedScrollPanel)`
  flex: 0 0 100%;
`;

const PreviewModal = ({ fileUri, pdfFileUri, download }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hideModalMutation } = useModal();

  const onCloseModal = useCallback(() => {
    hideModalMutation();
    navigate('#');
  }, [navigate, hideModalMutation]);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.keyCode === ESC_KEY) {
        e.preventDefault();
        onCloseModal();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onCloseModal, navigate, location]);

  const onDownload = () => {
    const a = document.createElement('a');
    a.href = fileUri;
    a.download = download;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <StyledFullModal>
      <StyledScrollPanel>
        <PdfViewerComponent uri={pdfFileUri} />
      </StyledScrollPanel>
      <StyledPanel>
        {download && (
          <ButtonIcon title="Télécharger" icon="download" size="large" variant="outlined" onClick={onDownload} />
        )}
        <ButtonIcon title="Fermer" icon="cross" size="large" variant="outlined" onClick={onCloseModal} />
      </StyledPanel>
    </StyledFullModal>
  );
};

PreviewModal.propTypes = {
  fileUri: PropTypes.string.isRequired,
  pdfFileUri: PropTypes.string.isRequired,
  download: PropTypes.string,
};

PreviewModal.defaultProps = {
  download: undefined,
};

export default PreviewModal;
