import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ColoredUnderline, ErrorMsg, GrayUnderline, Label, TextContainer } from 'components/styled-form-templates';

const StyledLabel = styled(Label)`
  top: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const StyledInput = styled.input`
  padding: 0;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.color.black};
  opacity: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 30px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: ${({ theme, compact }) => (compact ? '0' : `${theme.margin.l} 0 0 0`)};
  ::placeholder {
    user-select: none;
    color: ${({ theme, compact }) => (compact ? theme.color.darkGreyIcon : 'transparent')};
  }
`;

const StyledErrorMsg = styled(ErrorMsg)`
  position: absolute;
  bottom: 0;
  white-space: nowrap;
`;

const StyledGrayUnderline = styled(GrayUnderline)`
  bottom: ${({ readOnly, theme }) => (readOnly ? '0' : theme.margin.l)};
`;

const StyledColoredUnderline = styled(ColoredUnderline)`
  bottom: 20px;
`;

const StyledReadOnly = styled.div`
  margin: ${({ theme }) => `${theme.margin.l} 0 0 0`};
  cursor: default;
  color: ${({ theme }) => theme.color.darkGreyIcon};
  font-size: ${({ theme }) => theme.fontSize.l};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TextField = ({
  className,
  id,
  name,
  value,
  title,
  placeholder,
  error,
  touched,
  focus,
  fullWidth,
  readOnly,
  onChange,
  onBlur,
  onFocus,
  autofilled,
  autoComplete,
  autoFocus,
  maxLength,
  type,
  width,
  compact,
  fitContent,
  ...props
}) => {
  return (
    <TextContainer
      className={className}
      paddingRight="none"
      fullWidth={fullWidth}
      fitContent={fitContent}
      compact={compact}
      height={readOnly || compact ? '50px' : '70px'}
      lineHeight={compact ? '55px' : '24px'}
      readOnly={readOnly}>
      {!compact && placeholder && (
        <StyledLabel
          readOnly={readOnly}
          htmlFor={name}
          error={touched && error}
          filled={(value !== undefined && value !== null && value !== '') || (autofilled && !touched)}
          focus={focus}>
          {placeholder}
        </StyledLabel>
      )}
      {readOnly ? (
        <StyledReadOnly>{value}</StyledReadOnly>
      ) : (
        <StyledInput
          name={name}
          id={id}
          placeholder={placeholder || name}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          maxLength={maxLength}
          type={type}
          value={value}
          autoFocus={autoFocus}
          title={title}
          autoComplete={autoComplete}
          width={width}
          compact={compact}
          {...props}
        />
      )}
      {!compact && <StyledGrayUnderline aria-hidden="true" readOnly={readOnly} />}
      {!compact && (
        <StyledColoredUnderline error={touched && error} focus={focus} aria-hidden="true" readOnly={readOnly} />
      )}
      {touched && error && <StyledErrorMsg>{error}</StyledErrorMsg>}
    </TextContainer>
  );
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool.isRequired,
  focus: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  autoFocus: PropTypes.bool,
  autofilled: PropTypes.bool,
  autoComplete: PropTypes.string,
  maxLength: PropTypes.number,
  width: PropTypes.number,
  compact: PropTypes.bool,
  fitContent: PropTypes.string,
};

TextField.defaultProps = {
  className: undefined,
  value: undefined,
  title: undefined,
  placeholder: undefined,
  maxLength: undefined,
  id: undefined,
  error: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  autofilled: false,
  autoFocus: false,
  fullWidth: false,
  readOnly: false,
  autoComplete: undefined,
  width: undefined,
  compact: false,
  fitContent: '',
};

export default TextField;
