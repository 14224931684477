import localforage from 'localforage';
import { persistReducer } from 'redux-persist';
import assemblyReducer from './assembly/reducer';
import controlPanelReducer from './controlPanel/reducer';
import extractReducer from './extract/reducer';
import loadingReducer from './loading/reducer';
import modalReducer from './modal/reducer';
import refAssemblyReducer from './refAssembly/reducer';
import documentHolderSliceReducer from './slice/document-holder';
import edmSliceReducer from './slice/edm';
import projectSliceReducer from './slice/project';
import sessionSliceReducer from './slice/session';
import uploadSliceReducer from './slice/upload';
import userSliceReducer from './slice/user';
import messageSliceReducer from './slice/message';
import treeReducer from './tree/reducer';
import uploadReducer from './upload/reducer';
import userPreferencesReducer from './userPreferences/reducer';
import viewerReducer from './viewer/reducer';

const userPreferencesPersistConfig = {
  key: 'userPreferences',
  storage: localforage,
  debug: false,
};

export const appReducer = () => ({
  assembly: assemblyReducer,
  controlPanel: controlPanelReducer,
  edmSlice: edmSliceReducer,
  documentHolderSlice: documentHolderSliceReducer,
  messageSlice: messageSliceReducer,
  extract: extractReducer,
  loading: loadingReducer,
  projectSlice: projectSliceReducer,
  tree: treeReducer,
  refAssembly: refAssemblyReducer,
  sessionSlice: sessionSliceReducer,
  upload: uploadReducer,
  uploadSlice: uploadSliceReducer,
  viewer: viewerReducer,
  userPreferences: persistReducer(userPreferencesPersistConfig, userPreferencesReducer),
  modal: modalReducer,
  userSlice: userSliceReducer,
});

export default appReducer;
