import React from 'react';
import FakeInput from 'components/fake-input';
import config from 'app-config';
import styled from 'styled-components';
import ScrollablePanelComponent from 'components/scrollable-panel';
import PropTypes from 'prop-types';

const {
  PROJECTS: { TYPOLOGIES, NATURES, USES, OTHER },
} = config;

const StyledInfoContainer = styled.div`
  ${({ fullHeight }) => !fullHeight && 'max-height: 60vh'};
`;

const StyledStatusButton = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${({ theme }) => `${theme.margin.md}`};
`;

const StyledBreakWordTitleThree = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeight.semi};
  font-size: ${({ theme }) => theme.fontSize.h3};
  word-break: break-word;
  color: ${({ theme }) => theme.color.blue};
  margin: ${({ theme }) => `${theme.margin.sm} 0 ${theme.margin.m} 0`};
`;

const ProjectInfoContent = ({ projectDetails, statusButton, fullHeight }) => (
  <ScrollablePanelComponent>
    <StyledInfoContainer fullHeight={fullHeight}>
      <StyledStatusButton>{statusButton}</StyledStatusButton>
      <StyledBreakWordTitleThree>Information du projet</StyledBreakWordTitleThree>
      <FakeInput wordBreak label="Description" value={`${projectDetails.description}`} />
      <FakeInput wordBreak label="Adresse" value={`${projectDetails.address || 'Non spécifiée'}`} />
      {projectDetails.structure && Object.keys(projectDetails.structure).length !== 0 && (
        <>
          <StyledBreakWordTitleThree>Nature de l&apos;ouvrage</StyledBreakWordTitleThree>
          <FakeInput
            wordBreak
            label="Typologie"
            value={`${
              projectDetails.structure.typology.code === OTHER
                ? projectDetails.structure.typology.label
                : TYPOLOGIES[projectDetails.structure.typology.code]
            }`}
          />
          <FakeInput wordBreak label="Nombre d'étage" value={`${projectDetails.structure.levels.floorNumber}`} />
          <FakeInput
            wordBreak
            label="Nombre de sous-sols accessibles"
            value={`${projectDetails.structure.levels.basementNumber}`}
          />
          {projectDetails.structure.levels.parkingNumber !== undefined && (
            <FakeInput
              wordBreak
              label="Nombre de places de parking"
              value={`${projectDetails.structure.levels.parkingNumber}`}
            />
          )}
        </>
      )}
      {projectDetails.site && Object.keys(projectDetails.site).length !== 0 && (
        <>
          <StyledBreakWordTitleThree>Nature du chantier</StyledBreakWordTitleThree>
          <FakeInput
            wordBreak
            label="Nature"
            value={`${
              projectDetails.site.qualification.nature.code === OTHER
                ? projectDetails.site.qualification.nature.label
                : NATURES[projectDetails.site.qualification.nature.code]
            }`}
          />
          <FakeInput
            wordBreak
            label="Usage"
            value={`${
              projectDetails.site.qualification.usage.code === OTHER
                ? projectDetails.site.qualification.usage.label
                : USES[projectDetails.site.qualification.usage.code]
            }`}
          />
        </>
      )}
    </StyledInfoContainer>
  </ScrollablePanelComponent>
);

ProjectInfoContent.propTypes = {
  projectDetails: PropTypes.object.isRequired,
  statusButton: PropTypes.node,
  fullHeight: PropTypes.bool,
};

ProjectInfoContent.defaultProps = {
  statusButton: null,
  fullHeight: false,
};

export default ProjectInfoContent;
