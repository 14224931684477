import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledSharedScrollPanel } from 'components/styled-template';
import useInfiniteScroll from '../hooks/use-infinite-scroll';
import LoaderEllipsis from './icons/loader-ellipsis';

export const StyledContainer = styled.article`
  display: flex;
  min-height: 0;
  min-width: 0;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
`;

export const StyledLoader = styled.div.attrs(({ visible }) => ({
  'data-visible': visible,
}))`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const ScrollablePanelNextGenComponent = ({
  total,
  children,
  onInfiniteScroll,
  scrollReset,
  scrollLength,
  isLoadingScroll,
  lastChild,
  bottomMargin,
}) => {
  const [hasMore, setHasMore] = useState(false);

  const [page, loaderRef, scrollerRef] = useInfiniteScroll({ hasMore, reset: scrollReset });

  const onInfiniteScrollCb = useCallback(
    (size) => {
      if (onInfiniteScroll) {
        onInfiniteScroll(size);
      }
    },
    [onInfiniteScroll]
  );

  useEffect(() => {
    if (total) {
      setHasMore((page + 1) * scrollLength < total);
    } else {
      setHasMore(false);
    }
  }, [page, total, onInfiniteScroll, scrollLength]);

  useEffect(() => {
    onInfiniteScrollCb(page * scrollLength);
  }, [onInfiniteScrollCb, page, scrollLength]);

  return (
    <StyledContainer>
      <StyledSharedScrollPanel ref={scrollerRef} bottomMargin={bottomMargin}>
        {children}
        {!!onInfiniteScroll && (
          <>
            <StyledLoader aria-label="scroll loader" visible={isLoadingScroll}>
              <LoaderEllipsis height="10" width="40" />
            </StyledLoader>
            <StyledLoader aria-label="ref scroll loader" ref={loaderRef} visible={hasMore && !isLoadingScroll}>
              <LoaderEllipsis height="10" width="40" />
            </StyledLoader>
          </>
        )}
        {!hasMore && lastChild}
      </StyledSharedScrollPanel>
    </StyledContainer>
  );
};

ScrollablePanelNextGenComponent.propTypes = {
  total: PropTypes.number,
  onInfiniteScroll: PropTypes.func,
  isLoadingScroll: PropTypes.bool,
  scrollReset: PropTypes.bool,
  scrollLength: PropTypes.number,
  lastChild: PropTypes.node,
  bottomMargin: PropTypes.bool,
};

ScrollablePanelNextGenComponent.defaultProps = {
  total: undefined,
  onInfiniteScroll: undefined,
  isLoadingScroll: false,
  scrollReset: false,
  scrollLength: 20,
  lastChild: null,
  bottomMargin: false,
};

export default ScrollablePanelNextGenComponent;
