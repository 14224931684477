import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DropDownMenu, DotStatus } from '@datasoluce/ui-design-system-ts';
import styled from 'styled-components';
import config from 'app-config';
import { useUpdateProjectMutation } from 'common/api/backend/project-api';

const { PROJECT_STATUS } = config;

const StyledStatus = styled.div`
  width: ${({ variant }) => variant === 'outline' && '40%'};
`;

const ProjectStatus = ({ project, variant }) => {
  const [updateProject] = useUpdateProjectMutation();
  const { label, color } = project.status !== undefined && PROJECT_STATUS.find((el) => el.value === project.status);

  const menuEntries = useMemo(
    () =>
      PROJECT_STATUS.reduce((acc, curr) => {
        return [
          ...acc,
          ...(!project.status || curr.value
            ? [
                {
                  children: curr.value === project.status ? <b>{curr.label}</b> : curr.label,
                  disabled: !curr.value && !!project.status,
                  selected: curr.value === project.status,
                  onClick: () => {
                    if (project.status !== curr.value) {
                      updateProject({ projectSysId: project.sysId, data: { status: curr.value } });
                    }
                  },
                },
              ]
            : []),
        ];
      }, []),
    [project.status, project.sysId, updateProject]
  );

  return (
    <StyledStatus variant={project.capabilities.canUpdate ? variant : 'selfhold'}>
      <DropDownMenu
        name="currentStatus"
        disabled={!project.capabilities.canUpdate}
        severity={color}
        variant={variant}
        menuEntries={menuEntries}>
        <DotStatus label={label} severity={color} />
      </DropDownMenu>
    </StyledStatus>
  );
};

ProjectStatus.propTypes = {
  project: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

ProjectStatus.defaultProps = {
  variant: 'selfhold',
};

export default ProjectStatus;
