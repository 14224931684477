import React from 'react';
import { DEFAULT_COLOR } from '../../utils/common/style-utils';

const LoaderEllipsis = ({ height, width, color }) => (
  <svg viewBox="0 0 17.709 4.54" height={height || 17} width={width || 66} aria-label="loader ellipsis icon">
    <g fill={color || DEFAULT_COLOR} fillRule="evenodd" transform="translate(.253 .28)">
      <circle r="1.984" cx="1.996" cy="2.012" fillOpacity=".5" />
      <circle r="1.984" cy="1.97" cx="8.601" fillOpacity=".75" />
      <circle r="1.984" cx="15.207" cy="1.991" />
    </g>
  </svg>
);

export default LoaderEllipsis;
