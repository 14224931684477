import actionTypes from './actionTypes';

const defaultState = {
  files: {},
  isLoading: [],
};

function uploadReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.START_UPLOAD:
      return {
        ...state,
        files: {
          ...state.files,
          [action.projectSysId]: {
            ...state.files[action.projectSysId],
            [action.fileSysId]: { ...state.files[action.fileSysId], fileName: action.fileName, status: 'loading' },
          },
        },
      };
    case actionTypes.SET_UPLOAD_PROGRESS:
      if (state.files[action.projectSysId][action.fileSysId] === undefined) {
        return state;
      }

      return {
        ...state,
        files: {
          ...state.files,
          [action.projectSysId]: {
            ...state.files[action.projectSysId],
            [action.fileSysId]: {
              ...state.files[action.projectSysId][action.fileSysId],
              progress: action.progress,
            },
          },
        },
      };
    case actionTypes.SET_UPLOAD_SUCCESS:
      return {
        ...state,
        files: {
          ...state.files,
          [action.projectSysId]: {
            ...state.files[action.projectSysId],
            [action.fileSysId]: { ...state.files[action.projectSysId][action.fileSysId], status: 'success' },
          },
        },
      };
    case actionTypes.SET_UPLOAD_PREVIOUS:
      return {
        ...state,
        files: {
          ...state.files,
          [action.projectSysId]: Object.fromEntries(
            Object.entries(state.files[action.projectSysId]).map(([fileId, file]) => [
              fileId,
              { ...file, status: file.status === 'success' ? 'previous' : file.status },
            ])
          ),
        },
      };
    case actionTypes.SET_UPLOAD_ERROR:
      return {
        ...state,
        files: {
          ...state.files,
          [action.projectSysId]: {
            ...state.files[action.projectSysId],
            [action.fileSysId]: { ...state.files[action.projectSysId][action.fileSysId], status: 'error' },
          },
        },
      };
    case actionTypes.UPDATE_UPLOAD_LOADING:
      return {
        ...state,
        isLoading: [...state.isLoading, action.projectSysId],
      };
    case actionTypes.SET_UPLOAD_LOADING:
      return {
        ...state,
        isLoading: action.isLoadingList,
      };
    case actionTypes.SET_UPLOAD_FILES:
      return { ...state, files: { ...state.files, [action.projectSysId]: { ...action.files } } };
    default:
      return state;
  }
}

export default uploadReducer;
