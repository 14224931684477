import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate, useRouteError } from 'react-router-dom';
import { Button, Logo } from '@datasoluce/ui-design-system-ts';
import { StyledSharedTitleError, StyledSharedWrapperError, StyledSharedListError } from 'components/styled-template';
import { addError } from 'common/slice/message';

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => `${theme.margin.xl}`};
  gap: ${({ theme }) => `${theme.margin.xl}`};
`;

const StyledLogoWrapper = styled.div`
  width: 240px;
`;

const ErrorPage = ({ errorMessage }) => {
  const navigate = useNavigate();
  const loaderError = useRouteError();

  const dispatch = useDispatch();
  const addErrorAction = useCallback((e, m) => dispatch(addError({ error: e, message: m })), [dispatch]);

  useEffect(() => {
    // TODO(SBT) => revoir avec contextualisation
    if (loaderError || errorMessage) {
      addErrorAction(loaderError || errorMessage, errorMessage);
    }
  }, [addErrorAction, loaderError, errorMessage]);

  return (
    <StyledSharedWrapperError>
      <StyledSharedTitleError>Désolé, votre requête ne peut pas aboutir</StyledSharedTitleError>
      <StyledLogoWrapper>
        <Logo variant="pixel-title-vertical" grayscale />
      </StyledLogoWrapper>
      <StyledSharedListError>
        <li>Les utilisateurs doivent être autorisés à accéder à certains contenus</li>
        <li>
          Le contenu auquel vous essayez d&apos;accéder est peut-être en construction, non disponible ou a été supprimé
        </li>
      </StyledSharedListError>
      <StyledButtonsWrapper>
        <Button onClick={() => navigate('/')}>Accueil patrimoine</Button>
        <Button
          onClick={() => {
            window.location.href = 'mailto:support@datasoluce.com';
          }}>
          Contacter la plateforme
        </Button>
      </StyledButtonsWrapper>
    </StyledSharedWrapperError>
  );
};

export default ErrorPage;
