export const DEFAULT_COLOR = '#7d9cf5';
export const VALID_COLOR = 'red';
export const ERROR_COLOR = '#d54051';

export function truncate(width) {
  return `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

export function scrollbar(trackColor, thumbColor = DEFAULT_COLOR, margin = '0') {
  return `
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${trackColor};
      border: 9px solid transparent;
      margin: ${margin};
    }
    &::-webkit-scrollbar-thumb {
      background: ${thumbColor};
      margin: ${margin};
    }
  `;
}

export default {
  truncate,
  scrollbar,
};
