import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from '@datasoluce/ui-design-system-ts';

const StyledContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ noPointer }) => (noPointer ? 'auto' : 'pointer')};
  color: ${({ theme, color }) => color || theme.color.primary};
  font-size: ${({ fontSize }) => fontSize};
  margin: ${({ margin }) => margin};
`;

const IconContainer = ({ icon, color, margin, fontSize, onClick, tooltip, noPointer }) => {
  return (
    <StyledContainer
      onClick={onClick}
      title={tooltip}
      color={color}
      fontSize={fontSize}
      margin={margin}
      noPointer={noPointer}>
      <Icon icon={icon} />
    </StyledContainer>
  );
};

IconContainer.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  margin: PropTypes.string,
  fontSize: PropTypes.string,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  noPointer: PropTypes.bool,
};

IconContainer.defaultProps = {
  color: null,
  margin: null,
  fontSize: null,
  onClick: () => {},
  tooltip: null,
  noPointer: false,
};

export default IconContainer;
