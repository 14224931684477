import actionTypes from './actionTypes';

export const updateColumnsSizes = (tableName, columnsSizes) => ({
  type: actionTypes.UPDATE_COLUMNS_SIZES,
  tableName,
  columnsSizes,
});

export const toggleSideMenu = () => ({ type: actionTypes.TOGGLE_MENU });

export const changeGridView = (gridView) => ({ gridView, type: actionTypes.CHANGE_GRID_VIEW });
