import { setLoading } from 'common/loading/actions';
import assemblyService from 'services/api/assembly';
import api from 'services/apiCaller';
import { DEFAULT_TYPE, MAIN_TYPE } from 'pages/extract/components/viewer/utils/helper';
import { DEFAULT, DEFAULT_COLOR } from './colors';
import actionTypes from './actionTypes';
import { addError } from '../slice/message';
import { fetchElevationsFailedMessage, fetchTreeNodeFailedMessage } from '../message/error-messages';

export const DEFAULT_STATE = {
  '#': {
    id: '#',
    children: null,
    data: {},
    bimType: null,
    levelId: null,
    childRoute: '',
    color: {
      value: DEFAULT_COLOR,
      status: DEFAULT,
    },
  },
};

const setTree = (view, parentID, result, selectedItems) => ({
  type: actionTypes.SET_TREE,
  view,
  parentID,
  result,
  selectedItems,
});

const setElevations = (elevations) => ({
  type: actionTypes.SET_ELEVATIONS,
  elevations,
});

export const setCurrentView = (view) => ({
  type: actionTypes.SET_CURRENT_VIEW,
  view,
});

export const updateIsolateTreeNode = (id, isolated) => ({
  type: actionTypes.UPDATE_ISOLATED_TREE_NODE,
  id,
  isolated,
});

const setSelectTreeNode = (ids, selected, selectedNodes) => ({
  type: actionTypes.TOGGLE_SELECT_TREE_NODE,
  ids,
  selected,
  selectedNodes,
});

export const unselectAllNodes = () => ({
  type: actionTypes.UNSELECT_ALL,
});

const updateTreeColors = (view, tree) => ({
  type: actionTypes.SET_COLOR_PROPAGATE,
  view,
  tree,
});

export const setCustomColor = (id, value, status) => ({
  type: actionTypes.SET_COLOR,
  id,
  color: { value, status },
});

export const updateTreeNode = (id, view, folded) => ({
  type: actionTypes.UPDATE_TREE_NODE,
  id,
  view,
  folded,
});

const initTrees = (trees, selectedNodes, forgeIdMapping) => ({
  type: actionTypes.INIT_TREES,
  trees,
  selectedNodes,
  forgeIdMapping,
});

const setViewsConfigs = (views, configs) => ({
  type: actionTypes.SET_VIEWS,
  views,
  configs,
});

export const setselectedTreeNodeId = (nodeSysId) => ({
  type: actionTypes.SET_SELECTED_NODE_ID,
  nodeSysId,
});

export const fetchElevations = (projectSysId, assemblySysId) => (dispatch) => {
  dispatch(setLoading(true));
  return assemblyService
    .getElevations(projectSysId, assemblySysId)
    .then((result) => dispatch(setElevations(result)))
    .catch((err) => dispatch(addError({ error: err, message: fetchElevationsFailedMessage })))
    .finally(() => dispatch(setLoading(false)));
};

function setColorRecursive(tree, id, color) {
  const currentTree = tree;

  if (currentTree[id] !== undefined) {
    currentTree[id] = { ...currentTree[id], color };
    if (currentTree[id].children !== null) {
      currentTree[id].children.forEach((idChild) => {
        setColorRecursive(currentTree, idChild, color);
      });
    }
  }
}

export const setCustomColorPropagate = (id, value, status) => (dispatch, getState) => {
  const {
    tree: { trees, currentView },
  } = getState();
  const tree = { ...trees[currentView] };
  setColorRecursive(tree, id, { value, status });
  dispatch(updateTreeColors(currentView, tree));
};

export const toggleSelectTreeNode = ({ forgeIds, type }, selected, reset = false) => (dispatch, getState) => {
  if (reset) {
    dispatch(unselectAllNodes());
  }
  const {
    tree: { selectedNodes, currentView, forgeIdMapping },
  } = getState();
  const currentViewForgeId = forgeIdMapping[currentView];

  const ids = forgeIds.reduce((nodeIds, forgeId) => {
    const nodeSysId = currentViewForgeId?.[`${type === MAIN_TYPE ? DEFAULT_TYPE : type}s`][forgeId];

    return nodeSysId ? [...nodeIds, nodeSysId] : nodeIds;
  }, []);

  let newSelectedNodes = selectedNodes && selectedNodes[currentView] ? selectedNodes[currentView] : [];
  if (selectedNodes && selectedNodes[currentView]) {
    newSelectedNodes = selected
      ? [...selectedNodes[currentView], ...ids]
      : selectedNodes[currentView].filter((nodeSysId) => ids.indexOf(nodeSysId) === -1);
  } else if (selected) {
    newSelectedNodes = [...ids];
  }
  return dispatch(setSelectTreeNode(ids, selected, newSelectedNodes));
};

export const loadTree = (res) => (dispatch) => {
  const initialIndex = 0;

  const trees = {};
  const selectedNodes = {};
  const forgeIdMapping = {};
  const configs = {};
  res.forEach((view) => {
    configs[view.route] = view.config;
    trees[view.route] = DEFAULT_STATE;
    selectedNodes[view.route] = [];
    forgeIdMapping[view.route] = { rooms: [], defaults: [] };
  });
  const views = res.map((view) => ({
    label: view.name,
    value: view.route,
    tooltip: view.tooltip,
  }));
  if (res[initialIndex].route) {
    dispatch(setCurrentView(res[initialIndex].route));
  }
  dispatch(setViewsConfigs(views, configs));
  dispatch(initTrees(trees, selectedNodes, forgeIdMapping));
  dispatch(setselectedTreeNodeId(null));
};

export const fetchTreeNode = (route, parentID = '#', view) => (dispatch, getState) => {
  const {
    viewer: { selectedItems },
  } = getState();

  dispatch(setLoading(true));
  return api
    .get(route, {})
    .then((result) => dispatch(setTree(view, parentID, result, selectedItems)))
    .catch((err) => dispatch(addError({ error: err, message: fetchTreeNodeFailedMessage })))
    .finally(() => dispatch(setLoading(false)));
};
