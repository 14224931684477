import lazyWithRetry from 'utils/common/lazy-with-retry';
import { Outlet } from 'react-router-dom';
import { Loader } from 'components/styled-form-templates';

const ErrorPage = lazyWithRetry(() => import('pages/error'));

const CommonOutlet = ({ isLoading, isError, isSuccess, errorMessage }) => {
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErrorPage errorMessage={errorMessage} />;
  }

  return isSuccess && <Outlet />;
};

export default CommonOutlet;
