import { backendServiceApi, BE_TYPE } from 'common/api/backend-service-api';
import { dispatchMessageConfiguration, mapSysIdAsTags } from 'common/api/utils';
import { addError, addSuccess } from 'common/slice/message';
import {
  createProjectFailedMessage,
  createProjectSuccessMessage,
  fetchOrganizationConfigFailedMessage,
  fetchOrganizationFailedMessage,
  fetchOrganizationsFailedMessage,
  fetchOrganizationStatusTypesFailedMessage,
  fetchUsersFailedMessage,
  inviteUserFailedMessage,
  inviteUserSuccessMessage,
  removeUserFailedMessage,
  removeUsersFailedMessage,
  updateUserRoleFailedMessage,
  updateUsersRoleFailedMessage,
} from 'common/message/error-messages';

const organizationApi = backendServiceApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    getOrganizationStatusTypes: build.query({
      query: ({ organizationSysId }) => ({
        url: `/platform/${organizationSysId}/statusTypes`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchOrganizationStatusTypesFailedMessage }) }),
    }),
    getOrganizations: build.query({
      query: ({ from, size, order, sort }) => ({
        url: 'platforms',
        params: { from, size, order, sort },
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchOrganizationsFailedMessage }) }),
    }),
    getOrganization: build.query({
      query: ({ organizationSysId }) => ({
        url: `platform/${organizationSysId}`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchOrganizationFailedMessage }) }),
    }),
    getOrganizationConfig: build.query({
      query: ({ organizationSysId }) => ({
        url: `platform/${organizationSysId}/config`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchOrganizationConfigFailedMessage }) }),
    }),
    createProject: build.mutation({
      query: ({ organizationSysId, data }) => ({
        url: `/platform/${organizationSysId}/projects`,
        method: 'POST',
        body: data,
      }),
      ...dispatchMessageConfiguration({
        error: () => ({ message: createProjectFailedMessage }),
        success: ({ data, organizationSysId, res }) => ({
          message: createProjectSuccessMessage(data.name),
          option: {
            action: {
              label: 'Accéder au projet',
              path: `/organization/${organizationSysId}/project/${res.sysId}`,
            },
          },
        }),
      }),
      invalidatesTags: [{ type: BE_TYPE.PROJECT }, { type: BE_TYPE.CURRENT_USER }],
    }),
    getOrganizationUsers: build.query({
      query: ({ organizationSysId, from, size, order, sort, search }) => ({
        url: `/platform/${organizationSysId}/users`,
        params: { from, size, order, sort, search: search || undefined },
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchUsersFailedMessage }) }),
      providesTags: (response) => mapSysIdAsTags(response?.users, BE_TYPE.ORGANIZATION_USERS),
    }),
    getOrganizationRoles: build.query({
      query: ({ organizationSysId }) => ({
        url: `/platform/${organizationSysId}/roles`,
      }),
      ...dispatchMessageConfiguration({ error: () => ({ message: fetchUsersFailedMessage }) }),
    }),
    createOrganizationUsers: build.mutation({
      query: ({ organizationSysId, data }) => ({
        url: `/platform/${organizationSysId}/users`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data: res } = await queryFulfilled;
          const nbError = res.createdUser.fail.length + res.updatedUser.fail.length;
          // TODO LLT: add warning message in case of multiple invitation with some errors
          if (nbError === 0) {
            dispatch(addSuccess({ message: inviteUserSuccessMessage }));
          } else {
            dispatch(addError({ message: inviteUserFailedMessage }));
          }
        } catch (err) {
          // `onError` side-effect
          dispatch(addError({ message: inviteUserFailedMessage }));
        }
      },
      invalidatesTags: () => [BE_TYPE.ORGANIZATION_USERS],
    }),
    deleteOrganizationUsers: build.mutation({
      query: ({ organizationSysId, data }) => ({
        url: `platform/${organizationSysId}/users`,
        method: 'DELETE',
        body: data,
      }),
      ...dispatchMessageConfiguration({
        error: (arg) => ({
          message: arg.data.length === 1 ? removeUserFailedMessage : removeUsersFailedMessage,
        }),
      }),
      invalidatesTags: () => [BE_TYPE.ORGANIZATION_USERS],
    }),
    updateOrganizationUsersRole: build.mutation({
      query: ({ organizationSysId, data }) => ({
        url: `platform/${organizationSysId}/users/role`,
        method: 'PUT',
        body: data,
        responseHandler: (response) => response.text(),
      }),
      ...dispatchMessageConfiguration({
        error: (arg) => ({
          message: arg.data.length === 1 ? updateUserRoleFailedMessage : updateUsersRoleFailedMessage,
        }),
      }),
      invalidatesTags: () => [BE_TYPE.ORGANIZATION_USERS],
    }),
  }),
});

export const {
  useGetOrganizationStatusTypesQuery,
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useGetOrganizationConfigQuery,
  useCreateProjectMutation,
  useGetOrganizationUsersQuery,
  useGetOrganizationRolesQuery,
  useCreateOrganizationUsersMutation,
  useDeleteOrganizationUsersMutation,
  useUpdateOrganizationUsersRoleMutation,
} = organizationApi;

export default organizationApi;
