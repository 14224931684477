import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import config from 'app-config';
import sessionApi from 'common/api/backend/session-api';

const { SESSION_STATES } = config;

const slice = createSlice({
  name: 'sessionSlice',
  initialState: { session: { id: undefined, state: SESSION_STATES.IDLE } },
  reducers: {
    revokeUserSession(state) {
      return { ...state, session: { id: undefined, state: SESSION_STATES.REVOKED } };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(sessionApi.endpoints.login.matchFulfilled, sessionApi.endpoints.currentSession.matchFulfilled),
      (state, { payload: { sysId } }) => ({
        ...state,
        session: { id: sysId, state: SESSION_STATES.CONNECTED },
      })
    );
    builder.addMatcher(
      isAnyOf(sessionApi.endpoints.logout.matchFulfilled, sessionApi.endpoints.logout.matchRejected),
      (state) => ({
        ...state,
        session: { id: undefined, state: SESSION_STATES.DISCONNECTED },
      })
    );
    builder.addMatcher(sessionApi.endpoints.resetPassword.matchFulfilled, (state) => ({
      ...state,
      session: { id: undefined, state: SESSION_STATES.RESET },
    }));
    builder.addMatcher(
      sessionApi.endpoints.activate.matchFulfilled,
      (
        state,
        {
          payload: {
            userDetails: { sysId },
          },
        }
      ) => ({ ...state, session: { id: sysId, state: SESSION_STATES.ACTIVATED } })
    );
    builder.addMatcher(sessionApi.endpoints.updateProfile.matchFulfilled, (state, { payload: { sysId } }) => ({
      ...state,
      session: { id: sysId, state: SESSION_STATES.COMPLETED },
    }));
  },
});

export const { revokeUserSession } = slice.actions;
export default slice.reducer;
