export const MAIN_TYPE = 'main';
export const ROOM_TYPE = 'room';
export const DEFAULT_TYPE = 'default';

// Helper method: returns all leaf node dbIds for
// given input dbIds. A leaf node is a node that
// doesn't have any children
export function getLeafNodes(model, dbIds) {
  let currentDbIds = dbIds;

  return new Promise((resolve, reject) => {
    try {
      const instanceTree = model.getData().instanceTree;
      currentDbIds = currentDbIds || instanceTree.getRootId();
      const dbIdArray = Array.isArray(currentDbIds) ? currentDbIds : [currentDbIds];
      const leafIds = [];
      const getLeafNodesRec = (id) => {
        let childCount = 0;
        instanceTree.enumNodeChildren(id, (childId) => {
          getLeafNodesRec(childId);
          childCount += 1;
        });
        if (childCount === 0) {
          leafIds.push(id);
        }
      };
      for (let i = 0; i < dbIdArray.length; i += 1) {
        getLeafNodesRec(dbIdArray[i]);
      }
      resolve(leafIds);
    } catch (ex) {
      reject(ex);
    }
  });
}

export const getFullForgeIds = (viewerModelIds, models, selections) =>
  selections.reduce(
    (forgeIdsMap, { model, dbIdArray }) => {
      const modelIndex = viewerModelIds.findIndex((id) => id === model.id);
      if (modelIndex !== -1) {
        const { type, revisionSysId, jobVersion } = models[modelIndex];
        // Legacy JobVersion (pre Forge rooms extraction)
        forgeIdsMap[jobVersion === 1 && type === ROOM_TYPE ? ROOM_TYPE : MAIN_TYPE].push({
          jobVersion,
          revisionSysId,
          forgeIds: dbIdArray,
        });
      }
      return forgeIdsMap;
    },
    {
      main: [],
      room: [],
    }
  );

export const getModelIndex = (models, revisionSysId, modelType) =>
  models.findIndex((model) => model.visible && revisionSysId === model.revisionSysId && model.type === modelType);
