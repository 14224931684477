// DOCUMENT
export const updateDocumentSuccessMessage = 'Document modifié avec succès';
export const updateVisibilitySuccessMessage = 'Visibilité du document modifiée avec succès';
export const updateAllMetadataSuccessMessage = 'Métadonnées des documents modifiées avec succès';
export const shareDocumentSuccessMessage = (name) => `Le document ${name} a été envoyé par mail avec succès`;
export const updateAllVisibilitySuccessMessage = 'Visibilité des documents modifiée avec succès';

export const deleteFolderFromDocumentSuccessMessage = (document, folder) =>
  `Le document ${document} a été dissocié du dossier ${folder} avec succès`;
export const addFolderToDocumentsSuccessMessage = (folder) => `Association au dossier ${folder} validée`;
export const deleteFolderFromDocumentsSuccessMessage = (folder) => `Dissociation du dossier ${folder} validée`;

// GROUPS
export const createGroupSuccessMessage = 'Ajout du groupe avec succès.';
export const deleteGroupSuccessMessage = 'Suppression du groupe avec succès.';

// PROJECT/DOCUMENT
export const copyLinkAddressSuccessMessage = (item) => `Le lien du ${item} a été copié dans le presse-papier`;

// UPLOAD
export const uploadStartSuccessMessage = 'Vos documents sont en cours de téléversement.';
