import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import config from 'app-config';
import { loadUserSessionFailedMessage } from '../message/error-messages';

const { MESSAGE_LEVELS, SNACKBAR_TITLE } = config;

const initialState = {
  messageQueue: [],
};

const slice = createSlice({
  name: 'messageSlice',
  initialState,
  reducers: {
    addError: (state, action) => {
      console.log('addError', action.payload.message);
      let errMessage;

      if (action.payload?.error?.originalStatus === 401) {
        errMessage = loadUserSessionFailedMessage;
      } else if (action.payload.message) {
        errMessage = action.payload.message;
      } else {
        errMessage = action.payload.error.message;
      }

      return {
        ...state,
        messageQueue: [
          ...state.messageQueue,
          {
            key: nanoid(),
            message: errMessage,
            level: MESSAGE_LEVELS.LEVEL_ERROR,
            options: { ...action.payload.options, title: SNACKBAR_TITLE.ERROR },
          },
        ],
      };
    },
    addSuccess: (state, action) => {
      return {
        ...state,
        messageQueue: [
          ...state.messageQueue,
          {
            key: nanoid(),
            message: action.payload.message,
            level: MESSAGE_LEVELS.LEVEL_SUCCESS,
            options: { ...action.payload.options, title: SNACKBAR_TITLE.SUCCESS },
          },
        ],
      };
    },
    addWarning: (state, action) => {
      return {
        ...state,
        messageQueue: [
          ...state.messageQueue,
          {
            key: nanoid(),
            message: action.payload.message,
            level: MESSAGE_LEVELS.LEVEL_WARNING,
            options: { ...action.payload.options, title: SNACKBAR_TITLE.WARNING },
          },
        ],
      };
    },
    addInfo: (state, action) => {
      return {
        ...state,
        messageQueue: [
          ...state.messageQueue,
          {
            key: nanoid(),
            message: action.payload.message,
            level: MESSAGE_LEVELS.LEVEL_INFO,
            options: { ...action.payload.options, title: SNACKBAR_TITLE.INFO },
          },
        ],
      };
    },
    removeMessage: (state, action) => {
      return { ...state, messageQueue: state.messageQueue.filter((msg) => msg.key !== action.payload) };
    },
  },
});

const { actions, reducer } = slice;

export const { addError, addSuccess, addWarning, addInfo, removeMessage } = actions;

export default reducer;
