import config from 'app-config';
import { useCurrentSessionQuery } from 'common/api/backend/session-api';
import { useSelector, shallowEqual } from 'react-redux';

const { SESSION_STATES } = config;

const useUserSession = () => {
  const session = useSelector((state) => state.sessionSlice.session, shallowEqual);

  const userQuery = useCurrentSessionQuery(session.id, {
    skip: session.state === SESSION_STATES.DISCONNECTED,
  });

  return userQuery;
};

export default useUserSession;
