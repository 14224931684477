import { createSlice } from '@reduxjs/toolkit';
import documentApi from 'common/api/backend/document-api';
import config from 'app-config';

const { ORDER } = config.METADATA;

const slice = createSlice({
  name: 'uploadSlice',
  initialState: { files: {} },
  reducers: {
    selectFiles: (state, action) => {
      if (
        action.payload.selectedExtractedFiles.length === 0 ||
        (action.payload.selectedExtractedFiles.length === state.files[action.payload.projectSysId].length &&
          action.payload.selectedExtractedFiles.length !== 1)
      ) {
        return {
          ...state,
          files: {
            ...state.files,
            [action.payload.projectSysId]: state.files[action.payload.projectSysId].map((file) => {
              return {
                ...file,
                selected: action.payload.selectedExtractedFiles.length > 0,
              };
            }),
          },
        };
      }
      const previousSelection = state.files[action.payload.projectSysId]
        .filter((file) => file.selected)
        .map((file) => file.key);
      const newSelection = action.payload.selectedExtractedFiles.reduce((acc, current) => {
        return acc.find((item) => item === current) === undefined
          ? [...acc, current]
          : acc.filter((d) => current !== d);
      }, previousSelection);

      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.projectSysId]: state.files[action.payload.projectSysId].map((file) => {
            return { ...file, selected: newSelection.includes(file.key) };
          }),
        },
      };
    },
    addFiles: (state, action) => {
      if (state.files[action.payload.projectSysId] === undefined) {
        return {
          ...state,
          files: { ...state.files, [action.payload.projectSysId]: action.payload.files },
        };
      }
      const newFiles = action.payload.files.filter(
        (file) => !state.files[action.payload.projectSysId].find((f) => f.key === file.key)
      );
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.projectSysId]: state.files[action.payload.projectSysId].concat(newFiles),
        },
      };
    },
    removeFiles: (state, action) => {
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.projectSysId]: state.files[action.payload.projectSysId].filter(
            (file) => !action.payload.fileKeys.find((f) => f.key === file.key)
          ),
        },
      };
    },
    resetFiles: (state, action) => {
      return { ...state, files: { ...state.files, [action.payload.projectSysId]: undefined } };
    },
    updateMetadatas: (state, action) => {
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.projectSysId]: state.files[action.payload.projectSysId].map((file) =>
            action.payload.fileKeys.find((f) => f === file.key)
              ? {
                  ...file,
                  isValid: ORDER.every(
                    (category) =>
                      file[category].code ||
                      (action.payload.categories.some((c) => c === category) &&
                        action.payload.updatedMetadata[category].code)
                  ),
                  ...action.payload.updatedMetadata,
                }
              : file
          ),
        },
      };
    },
    updateVisibility: (state, action) => {
      return {
        ...state,
        files: {
          ...state.files,
          [action.payload.projectSysId]: state.files[action.payload.projectSysId].map((file) =>
            action.payload.fileKeys.find((f) => f === file.key)
              ? {
                  ...file,
                  visibility: action.payload.updatedVisibility,
                }
              : file
          ),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(documentApi.endpoints.getDetectMetadata.matchFulfilled, (state, { meta, payload }) => {
      const { projectSysId } = meta.arg.originalArgs;

      const storedFiles = state.files[projectSysId].map((file) => {
        const alreadyFetchedFile = payload.extractedFiles.find((ef) => ef.name === file.name);

        if (!alreadyFetchedFile) {
          throw new Error('alreadyFetchedFile does not exist');
        }
        if ('isValid' in file) {
          return file;
        }
        return { ...alreadyFetchedFile, key: file.key };
      });

      return { ...state, files: { ...state.files, [projectSysId]: storedFiles } };
    });
  },
});

export const { selectFiles, addFiles, removeFiles, resetFiles, updateMetadatas, updateVisibility } = slice.actions;

export default slice.reducer;
