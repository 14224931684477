import React from 'react';
import PropTypes from 'prop-types';
import useModal from 'hooks/use-modal';
import { useParams } from 'react-router-dom';
import config from 'app-config';
import { useGetProjectQuery } from 'common/api/backend/project-api';
import MetadataList from './components/metadata-list';
import Header from './components/header';
import ScrollablePanelComponent from '../../../../../../components/scrollable-panel';

const { METADATA_MODALS } = config;

const TabContentItemComponent = ({ metadata, onSearch, searchValue, resetSearch, currentCategory }) => {
  const { projectSysId } = useParams();
  const { data: project, isSuccess: isSuccessProject } = useGetProjectQuery({ projectSysId });

  const { showModalMutation } = useModal();

  return (
    isSuccessProject && (
      <>
        <Header
          path={currentCategory}
          onCreate={
            project.capabilities.canCreateMetadata
              ? () => showModalMutation(METADATA_MODALS.METADATA_CREATE, { projectSysId, path: currentCategory })
              : undefined
          }
          onSearch={onSearch}
          searchValue={searchValue}
          resetSearch={resetSearch}
          resetAutoFocus={currentCategory}
        />
        <ScrollablePanelComponent>
          <MetadataList searchValue={searchValue} currentCategory={currentCategory} metadata={metadata} />
        </ScrollablePanelComponent>
      </>
    )
  );
};

TabContentItemComponent.defaultProps = {
  metadata: [],
};

TabContentItemComponent.propTypes = {
  currentCategory: PropTypes.string.isRequired,
  metadata: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  onSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

export default TabContentItemComponent;
