import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from 'components/loader';
import lazyWithRetry from 'utils/common/lazy-with-retry';
import config from 'app-config';
import OrganizationLayoutComponent from 'components/layout/organization-layout';

const EstatePage = lazyWithRetry(() => import('pages/estate'));
const OrganizationProjectList = lazyWithRetry(() => import('pages/estate/components/organization-project-list'));
const CreateProjectPage = lazyWithRetry(() => import('pages/create-project'));
const ManageOrganizationUsersPage = lazyWithRetry(() => import('pages/manage-organization-users'));

const { HIDE_CRUMBS } = config;

const organizationRoutes = [
  {
    element: <OrganizationLayoutComponent />,
    children: [
      { index: true, element: <Navigate to="estate" /> },
      {
        path: 'estate',
        element: (
          <Suspense fallback={<Loader />}>
            <EstatePage />
          </Suspense>
        ),
        handle: { hideCrumbs: HIDE_CRUMBS.ALL },
        children: [
          { index: true, element: <Navigate to="current" /> },
          {
            path: 'current',
            element: (
              <Suspense fallback={<Loader />}>
                <OrganizationProjectList />
              </Suspense>
            ),
          },
          {
            path: 'archive',
            element: (
              <Suspense fallback={<Loader />}>
                <OrganizationProjectList isArchiveView />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'create-project',
        element: (
          <Suspense fallback={<Loader />}>
            <CreateProjectPage />
          </Suspense>
        ),
        handle: {
          hideCrumbs: HIDE_CRUMBS.ALL,
        },
      },
      {
        path: 'manage-organization-users',
        element: (
          <Suspense fallback={<Loader />}>
            <ManageOrganizationUsersPage />
          </Suspense>
        ),
        handle: {
          hideCrumbs: HIDE_CRUMBS.ALL,
        },
      },
    ],
  },
];

export default organizationRoutes;
