import { addError, addSuccess } from 'common/slice/message';

export const dispatchMessageConfiguration = ({ error, success }) => ({
  // error and success must be arrow function returning object { message?: string, option?: object }
  async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    try {
      const { data: res } = await queryFulfilled;
      // `onSuccess` side-effect
      if (success) {
        const successInfo = success({ ...arg, res });
        const message = successInfo?.message ?? undefined;
        const option = successInfo?.option ?? undefined;
        if (message || option) {
          dispatch(addSuccess({ message, option }));
        }
      }
    } catch (err) {
      // `onError` side-effect
      if (error) {
        const errorInfo = error({ ...arg, err });
        const message = errorInfo?.message ?? undefined;
        const option = errorInfo?.option ?? undefined;
        if (message || option) {
          dispatch(addError({ error: err.error, message, option, response: err.response }));
        }
      }
    }
  },
});

export const mapSysIdAsTags = (results, type) => {
  const mappedSysId = results ? results.map(({ sysId }) => ({ type, id: sysId })) : [];
  return [...mappedSysId, { type, id: 'LIST' }];
};
