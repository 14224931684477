import documentsService from 'services/api/documents';
import { BE_TYPE, backendServiceApi } from 'common/api/backend-service-api';
import { addError } from 'common/slice/message';
import { omitObjectKey, removeItemFromArray } from 'utils/edm/utils';
import { uploadFailedMessage } from 'common/message/error-messages';
import actionTypes from './actionTypes';

function startUpload(projectSysId, fileSysId, fileName) {
  return {
    type: actionTypes.START_UPLOAD,
    projectSysId,
    fileSysId,
    fileName,
  };
}

export function setUploadProgress(projectSysId, fileSysId, progress) {
  return {
    type: actionTypes.SET_UPLOAD_PROGRESS,
    projectSysId,
    fileSysId,
    progress,
  };
}

export function setUploadSuccess(projectSysId, fileSysId) {
  return {
    type: actionTypes.SET_UPLOAD_SUCCESS,
    projectSysId,
    fileSysId,
  };
}

export function setUploadError(projectSysId, fileSysId) {
  return {
    type: actionTypes.SET_UPLOAD_ERROR,
    projectSysId,
    fileSysId,
  };
}

function updateUploadLoading(projectSysId) {
  return {
    type: actionTypes.UPDATE_UPLOAD_LOADING,
    projectSysId,
  };
}

function setUploadLoading(isLoadingList) {
  return {
    type: actionTypes.SET_UPLOAD_LOADING,
    isLoadingList,
  };
}

function setUploadFiles(files, projectSysId) {
  return {
    type: actionTypes.SET_UPLOAD_FILES,
    files,
    projectSysId,
  };
}

export function setUploadPrevious(projectSysId) {
  return {
    type: actionTypes.SET_UPLOAD_PREVIOUS,
    projectSysId,
  };
}

export function removeUpload(projectSysId, fileSysId) {
  return (dispatch, getState) => {
    const {
      upload: { files },
    } = getState();
    dispatch(setUploadFiles(omitObjectKey(files[projectSysId], fileSysId), projectSysId));
  };
}

export function clearUploads(projectSysId) {
  return (dispatch) => {
    const emptyList = {};
    dispatch(setUploadFiles(emptyList, projectSysId));
  };
}

export function setStartUpload(projectSysId, fileSysId, fileName) {
  return (dispatch, getState) => {
    const {
      upload: { isLoading },
    } = getState();
    const isAlreadyLoading = isLoading.some((projects) => projects === projectSysId);
    dispatch(startUpload(projectSysId, fileSysId, fileName));
    if (!isAlreadyLoading) {
      dispatch(updateUploadLoading(projectSysId));
    }
  };
}

export function removeUploadLoading(projectSysId) {
  return (dispatch, getState) => {
    const {
      upload: { isLoading },
    } = getState();
    dispatch(setUploadLoading(removeItemFromArray(isLoading, projectSysId)));
    dispatch(setUploadPrevious(projectSysId));
  };
}

export const uploadRevisionFile = (projectSysId, documentSysId, file, singleMode = false) => async (dispatch) => {
  return documentsService
    .createRevision(
      projectSysId,
      documentSysId,
      file,
      (fSysId, progress) => dispatch(setUploadProgress(projectSysId, fSysId, progress)),
      (fSysId) => dispatch(setUploadSuccess(projectSysId, fSysId))
    )
    .then(() => true)
    .catch((err) => {
      dispatch(setUploadError(projectSysId, file.key));
      if (singleMode) {
        dispatch(addError({ error: err, message: uploadFailedMessage(file.file.name) }));
      }
      dispatch(removeUploadLoading(projectSysId));
      return false;
    });
};

export const onUploadRevisionSuccess = (projectSysId, documentSysId) => (dispatch) => {
  dispatch(
    backendServiceApi.util.invalidateTags([
      { type: BE_TYPE.DOCUMENT, id: 'LIST' },
      { type: BE_TYPE.DOCUMENT, id: documentSysId },
    ])
  );
};
