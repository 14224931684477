import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { updateMetadataOrderKey } from 'utils/metadata/metadata';
import DraggableElement from 'components/draggable-element';
import { useReorderMetadataMutation } from 'common/api/backend/metadata-api';
import MetadataItem from './components/metadata-item';

const MetadataTreeComponent = ({
  activeMetadataCount,
  currentCategory,
  disableDrop,
  enableParentHover,
  metadata,
  parent,
}) => {
  const [hover, enableHover] = useState(true);
  const [orderedMetadata, setOrderedMetadata] = useState([]);

  const { projectSysId } = useParams();

  const [reorderMetadata] = useReorderMetadataMutation();

  useEffect(() => {
    setOrderedMetadata(metadata);
  }, [metadata]);

  const moveMetadata = useCallback(
    (dragIndex, hoverIndex) => {
      const dragMetadata = orderedMetadata[dragIndex];
      const mdArray = [...orderedMetadata];
      mdArray.splice(dragIndex, 1);
      mdArray.splice(hoverIndex, 0, dragMetadata);
      setOrderedMetadata(mdArray);
    },
    [orderedMetadata]
  );

  return (
    <div
      onMouseOver={() => {
        if (enableParentHover) {
          enableParentHover(false);
        }
      }}
      onMouseOut={() => {
        if (enableParentHover) {
          enableParentHover(true);
        }
      }}>
      <DndProvider backend={HTML5Backend}>
        {orderedMetadata.map((md, index) => {
          return (
            <DraggableElement
              key={md.path}
              id={md.path}
              index={index}
              moveElement={moveMetadata}
              accept={`draggable-${parent || 'parent'}`}
              disabled={disableDrop}
              onDropElement={() =>
                reorderMetadata({
                  projectSysId,
                  path: currentCategory,
                  metadata: updateMetadataOrderKey(orderedMetadata),
                })
              }
              hover={hover}
              enableHover={enableHover}>
              <MetadataItem
                activeMetadataCount={activeMetadataCount}
                currentCategory={currentCategory}
                disableDrop={disableDrop}
                metadata={md}
                enableHover={enableHover}
              />
            </DraggableElement>
          );
        })}
      </DndProvider>
    </div>
  );
};

MetadataTreeComponent.propTypes = {
  activeMetadataCount: PropTypes.number.isRequired,
  currentCategory: PropTypes.string.isRequired,
  disableDrop: PropTypes.bool.isRequired,
  metadata: PropTypes.array.isRequired,
  parent: PropTypes.string,
};

MetadataTreeComponent.defaultProps = {
  parent: undefined,
};

export default MetadataTreeComponent;
