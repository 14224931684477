import { theme as libTheme } from '@datasoluce/ui-design-system-ts';

const theme = {
  ...libTheme,
  palette: {
    ...libTheme.palette,
    action: {
      ...libTheme.palette.action,
      selectedOpacity: 0.2,
    },
  },
  buttonStyle: {
    ...libTheme.buttonStyle,
    low: {},
    medium: {
      back: {
        bg: '#ffffff',
        color: '#ffffff',
        border: '2px solid #292929',
        hoverBg: '#7d7d7d',
        hoverFont: '#292929',
        active: '#585858',
        font: '#292929',
      },
      action: {
        bg: 'transparent',
        color: '#7d9cf5',
        border: '1px solid #7d9cf5',
        hoverBg: '#a6c8ff',
        hoverFont: '#ffffff',
        active: '#324a8e',
        font: '#7d9cf5',
      },
    },
    high: {
      action: {
        bg: '#7d9cf5',
        color: '#7d9cf5',
        border: 'none',
        hoverBg: '#496bcc',
        hoverFont: '#ffffff',
        active: '#324a8e',
        font: '#ffffff',
      },
      secondaryAction: {
        bg: '#a6c8ff',
        color: '#a6c8ff',
        border: 'none',
        hoverBg: '#496bcc',
        hoverFont: '#ffffff',
        active: '#324a8e',
        font: '#ffffff',
      },
      delete: {
        bg: '#fb4c60',
        color: '#fb4c60',
        border: 'none',
        hoverBg: '#d54051',
        hoverFont: '#ffffff',
        active: '#af3543',
        font: '#ffffff',
      },
      validation: {
        bg: '#6ad1a0',
        color: '#6ad1a0',
        border: 'none',
        hoverBg: '#5ab188',
        hoverFont: '#ffffff',
        active: '#3e7b5e',
        font: '#ffffff',
      },
    },
  },

  color: {
    ...libTheme.colors,
    black: '#292929',
    primary: '#567ef0',
    blue: '#7d9cf5',
    lightBlue: '#a6c8ff',
    veryLightBlue: '#e2e7f4',
    green: '#6ad1a0',
    secondaryGreen: '#2e9e78',
    secondaryGreenHover: '#65B097',
    red: '#fb4c60',
    orange: '#f9a74e',
    paleGreen: '#d9e5e1',
    submenuGreen: '#3ac596',
    text: '#656565',
    white: '#ffffff',
    greyBorder: '#DEDEDE',
    darkGrey: '#878787',
    filledGrey: '#666666',
    darkGreyIcon: '#707070',
    gray: '#939393',
    lightGrey: '#eaeaea',
    borderGrey: '#cccccc',
    headerGrey: '#ededed',
    tooltipText: '#292929',
    filters: '#5278e4',
    profileImageBlue: '#a6c8ff',
    buttonDisabled: '#d4d4d4',
    iconUploadHover: '#496bcc',
  },
  height: {
    l: '34px',
    xl: '39px',
    xxl: '50px',
    projectImage: 95,
    header: 50,
    panelHeader: 101,
    page: 'calc(100vh - 50px)',
    pageContent: 'calc(100vh - 50px - 76px)', // header + title + margin bottom
    filter: 'calc(100vh - 50px - 78px - 40px)', // header + title + padding
  },
  width: {
    m: '14px',
    l: '16px',
    xxl: '200px',
    projectImage: '95',
    pageContainer: 1714,
    informationPanel: 500,
  },
  radius: {
    ...libTheme.radius,
    xxs: '2px',
    xs: '3px',
    m: '15px',
    l: '20px',
  },
  // Overwrite font size
  fontSize: {
    ...libTheme.fontSize,
    s: '13px',
    m: '14px',
    l: '16px',
  },
  // Overwrite theme margin
  margin: {
    ...libTheme.margin,
    xs: '3px',
    s: '5px',
    m: '13px',
    l: '20px',
    xl: '28px',
    xxl: '38px',
    xxxxxl: '123px',
  },
  // Overwrite theme padding
  padding: {
    ...libTheme.padding,
    xs: '5px',
    s: '10px',
    m: '15px',
    l: '30px',
    xl: '50px',
  },
  // Remove when unused
  table: {
    id: {
      minWidth: 220,
    },
    checkbox: {
      width: 37,
    },
    visibility: {
      minWidth: 250,
      width: 300,
    },
    code: {
      width: 90,
    },
    date: {
      width: 90,
    },
    boolean: {
      width: 50,
    },
    common: {
      minWidth: 115,
      width: 100,
      maxWidth: 1000,
    },
  },
  treePalette: [
    '#FFFFFF',
    '#FFFF99',
    '#FFC899',
    '#FF999F',
    '#FF99EC',
    '#AD99FF',
    '#99C3FF',
    '#99F6FF',
    '#99FFCE',
    '#9FF9A8',
  ],
  treePaletteLight: [
    '#e2e7f4',
    '#ffffc2',
    '#FFDEC2',
    '#FFC2C5',
    '#FFC2F4',
    '#CEC2FF',
    '#C2DBFF',
    '#C2FAFF',
    '#C2FFE2',
    '#C5FBCB',
  ],
};

export default theme;
