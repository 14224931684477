import Loader from 'components/loader';
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import lazyWithRetry from 'utils/common/lazy-with-retry';

const OrganizationsPage = lazyWithRetry(() => import('pages/organizations'));
const UserSettinsPage = lazyWithRetry(() => import('pages/user-settings'));
const ProfileTab = lazyWithRetry(() => import('pages/user-settings/components/profile'));
const PasswordTab = lazyWithRetry(() => import('pages/user-settings/components/password'));
const ProjectsPage = lazyWithRetry(() => import('pages/projects'));
const UserProjectList = lazyWithRetry(() => import('pages/projects/components/user-project-list'));

const userRoutes = [
  { index: true, element: <Navigate to="projects" /> },
  {
    path: '/user-settings',
    element: (
      <Suspense fallback={<Loader />}>
        <UserSettinsPage />
      </Suspense>
    ),
    children: [
      { index: true, element: <Navigate to="profile" /> },
      {
        path: 'profile',
        element: (
          <Suspense fallback={<Loader />}>
            <ProfileTab />
          </Suspense>
        ),
      },
      {
        path: 'password',
        element: (
          <Suspense fallback={<Loader />}>
            <PasswordTab />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/projects',
    element: (
      <Suspense fallback={<Loader />}>
        <ProjectsPage />
      </Suspense>
    ),
    children: [
      { index: true, element: <Navigate to="current" /> },
      {
        path: 'current',
        element: (
          <Suspense fallback={<Loader />}>
            <UserProjectList />
          </Suspense>
        ),
      },
      {
        path: 'archive',
        element: (
          <Suspense fallback={<Loader />}>
            <UserProjectList isArchiveView />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: '/organizations',
    element: (
      <Suspense fallback={<Loader />}>
        <OrganizationsPage />
      </Suspense>
    ),
  },
];

export default userRoutes;
