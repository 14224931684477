// DOCUMENT
export const uploadFailedMessage = (title) => `L'ajout du document ${title} a échoué`;
export const uploadDocHolderFailedMessage = `L'ajout des emplacements de documents a échoué`;
export const uploadMultiDocHolderFailedMessage = (title) => `L'ajout de l'emplacement pour ${title} a échoué`;
export const shareDocumentFailedMessage = 'Le partage du document a échoué';
export const updateRevisionFailedMessage = 'La modification du statut de la révision a échoué';
export const fetchDocumentsFailedMessage = 'La récupération des documents a échoué';
export const fetchDocumentFailedMessage = 'La récupération du document a échoué';
export const setLinksFailedMessage = (title) => `La liaison du document ${title} a échoué`;
export const deleteDocumentFailedMessage = 'La suppression du document a échoué';
export const deleteDocumentsFailedMessage = 'La suppression des documents a échoué';
export const updateDocumentFailedMessage = 'La mise à jour du document a échoué';
export const updateDocumentsFailedMessage = 'La mise à jour des documents a échoué';
export const updateVisibilityFailedMessage = 'La modification de la visibilité du document a échoué';
export const updateAllMetadataFailedMessage = 'La modification des métadonnées a échoué';
export const updateAllVisibilityFailedMessage = 'La modification de la visibilité des documents a échoué';
export const createExtractedFilesFailedMessage = 'La détection automatique des métadonnées a échoué';
export const exportDocumentListFailedMessage = "L'export de la liste des documents a échoué";
export const linkSingleDocumentToFolderFailedMessage = (document, folder) =>
  `L'association du document "${document}" au dossier "${folder}" a échoué`;
export const getRevisionsFailedMessage = 'La récupération des révisions a échoué';
export const documentDownloadFailedMessage = 'Le téléchargement du document a échoué';
export const documentsDownloadFailedMessage = 'Le téléchargement des documents a échoué';
export const documentDisplayFailedMessage = "L'affichage du document a échoué";
export const getStatisticsFailedMessage = 'La récupération des indicateurs a échoué';
export const getEventFailedMessage = 'La récupération des événements a échoué'; // Duplication de getEventsFailedMessage plus bas
export const linkManyDocumentToFolderFailedMessage = (folder) =>
  `L'association des documents au dossier "${folder}" a échoué`;
export const deleteFolderFromDocumentFailedMessage = (folder) =>
  `La dissocation de document au dossier "${folder}" a échoué`;

// FOLDER
export const getFolderFailedMessage = 'La récupération du dossier a échoué';
export const fetchFoldersFailedMessage = 'La récupération des dossiers a échoué';
export const createFolderDuplicateFailedMessage = (folder) =>
  `L'ajout du dossier a échoué. Un dossier avec le nom ${folder} existe déjà.`;
export const createFolderSuccessMessage = (folder) => `Le dossier ${folder} a été créé avec succès.`;
export const createFolderFailedMessage = 'La création du dossier a échoué';
export const deleteFolderFailedMessage = 'La suppression du dossier a échoué';
export const updateFolderDuplicateFailedMessage = (folder) =>
  `Le renommage a échoué. Un dossier avec le nom ${folder} existe déjà.`;
export const updateFolderFailedMessage = 'La modification du dossier a échoué';

// REF ASSEMBLY
export const loadRefAssPhasesFailedMessage = 'Le chargement des phases a échoué';
export const loadRefAssBuildingsFailedMessage = 'Le chargement des bâtiments a échoué';
export const loadRefAssLevelsFailedMessage = 'Le chargement des niveaux a échoué';
export const loadRefAssRoomsFailedMessage = 'Le chargement des locaux a échoué';
export const loadRefAssCategoriesFailedMessage = 'Le chargement des catégories a échoué';
export const loadRefAssTypesFailedMessage = 'Le chargement des types a échoué';

// ASSEMBLY
export const fetchAssembliesFailedMessage = 'Le chargement des assemblages a échoué';
export const deleteAssemblyFailedMessage = "La suppression de l'assemblage a échoué";
export const refreshAssemblyFailedMessage = "Le rechargement de l'assemblage a échoué";
export const addModelToAssemblyFailedMessage = "La liaison à l'assemblage a échoué";
export const createAssemblySuccessMessage = (assembly) => `L’assemblage ${assembly} a été créé avec succès.`;
export const createAssemblyFailedMessage = "La création de l'assemblage a échoué";
export const fetchModelsFailedMessage = 'Le chargement des modèles a échoué';
export const deleteModelFailedMessage = 'La suppression de la maquette a échoué';
export const updateAssemblyFailedMessage = "La modification de l'assemblage a échoué";

// PROJECT
export const fetchProjectsFailedMessage = 'La récupération des projets a échoué';
export const fetchProjectFailedMessage = 'La récupération du projet a échoué';
export const createProjectSuccessMessage = (project) => `Le projet ${project} a été créé avec succès.`;
export const createProjectFailedMessage = 'La création du projet a échoué';
export const updateProjectFailedMessage = 'La modification du projet a échoué';
export const updateProjectArchiveFailedMessage = "L'archivage du projet a échoué";
export const updateProjectRestoreFailedMessage = 'La restauration du projet a échoué';
export const updateLinkConfigurationFailedMessage = 'La modification du lien a échoué';
export const getProjectStructureFailedMessage = "La récupération de la nature de l'ouvrage a échoué";
export const getProjectSiteFailedMessage = 'La récupération de la nature du chantier a échoué';
export const getProjectContributorsFailedMessage = 'La récupération des intervenants du chantier a échoué';
export const fetchProjectConfigurationFailedMessage = 'La récupération de la configuration du projet a échoué';
export const getProjectArticleExtractionsFailedMessage =
  "La récupération des extractions d'article par groupement a échoué";
export const addUserToGroupFailedMessage = "L'affectation de l'utilisateur au groupe a échoué";
export const removeUserFromGroupFailedMessage = "La suppression de l'utilisateur du groupe a échoué";
export const configUsersToGroupFailedMessage = 'La configuration des utilisateurs a échoué.';

// PROJECT/DOCUMENT
export const copyLinkAddressFailedMessage = (item) => `Le lien du ${item} n'a pas été copié dans le presse-papier`;

// PROJECT/ORGANISATION USER
export const fetchUsersFailedMessage = 'Le chargement des utilisateurs a échoué';
export const inviteUserFailedMessage = "L'invitation a échoué";
export const inviteUserSuccessMessage = "L'invitation a été envoyée";
export const removeUserFailedMessage = "Le retrait de l'utilisateur a échoué";
export const removeUsersFailedMessage = 'Le retrait des utilisateurs a échoué';
export const updateUserRoleFailedMessage = "Le changement de rôle de l'utilisateur a échoué";
export const updateUsersRoleFailedMessage = 'Le changement de rôle des utilisateurs a échoué';

// SESSION
export const loginFailedMessage = 'Les informations de connexion ne sont pas valides';
export const activationFailedMessage = "Une erreur est survenue lors de l'activation";
export const updatePasswordFailedMessage = 'Une erreur est survenue lors de la modification du mot de passe';
export const updatePasswordSuccessMessage = 'Le mot de passe à été mis à jour avec succès';
export const resetPwdFailedMessage = 'La réinitialisation du mot de passe a échoué';
export const loadUserSessionFailedMessage = "La session de l'utilisateur a expiré";
export const updateProfileSuccessMessage = 'Le profil a été mis à jour avec succès';
export const updateProfileFailedMessage = 'Une erreur est survenue lors de la mise du profil';

// METADATA
export const updateMetadataFailedMessage = 'La modification de la métadonnée a échoué';
export const fetchFormatedMetadataFailedMessage = 'La récupération des métadonnées a échoué';
export const fetchMetadataDefinitionsFailedMessage = 'La récupération des définitions des métadonnées a échoué';
export const deleteMetadataFailedMessage = (errorType, label) => {
  switch (errorType) {
    case 'ERR10001':
      return 'La métadonnée est référencée dans un document';
    case 'ERR10002':
      return 'La métadonnée possède des enfants';
    default:
      return `La suppression de la métadonnée ${label} a échoué`;
  }
};
export const createMetadataFailedMessage = (errorType, label) => {
  switch (errorType) {
    case 'ERR10000':
      return `La métadonnée existe déjà dans la catégorie ${label}`;
    default:
      return "L'ajout de la métadonnée a échoué";
  }
};
export const reorderMetadataFailedMessage = 'La modification de la métadonnée a échoué';

// DATA
export const failedBimElementPropertiesMessage = "La récupération des données de l'élément a échoué";

// GROUPS
export const getGroupsFailedMessage = (projectSysId) =>
  `La récupération des groupes du projet '${projectSysId}' a échoué`;
export const deleteUsersGroupFailedMessage = (group) => `La suppression des utilisateurs du groupe '${group}' a échoué`;
export const addGroupFailedMessage = (group) => `L'ajout du groupe '${group}' a échoué`;
export const removeGroupFailedMessage = (group) => `La suppression du groupe '${group}' a échoué`;
export const createGroupDuplicateFailedMessage = (group) =>
  `L'ajout du groupe a échoué. Un groupe avec le nom ${group} existe déjà.`;
export const createGroupFailedMessage = "L'ajout du groupe a échoué. Une erreur est survenue.";
export const deleteGroupReferencedMessage = 'La suppression du groupe a échoué. Un document mentionne le groupe.';
export const deleteGroupFailedMessage = 'La suppression du groupe a échoué. Une erreur est survenue.';
export const updateGroupDuplicateFailedMessage = (group) =>
  `Le renommage a échoué. Un groupe avec le nom ${group} existe déjà.`;
export const updateGroupFailedMessage = 'La modification du groupe a échoué. Une erreur est survenue.';

// ARTICLE
export const fetchArticleFailedMessage = "La récupération de l'article a échoué";

// ORGANIZATION
export const fetchOrganizationConfigFailedMessage = 'La récupération de la configuration plateforme a échoué';
export const getCompaniesFailedMessage = 'La récupération de la liste des sociétés a échoué';
export const fetchOrganizationStatusTypesFailedMessage = 'La récupération de la liste des status a échoué';
export const fetchOrganizationFailedMessage = 'La récupération de la plateforme a échoué';
export const fetchOrganizationsFailedMessage = 'La récupération des plateformes a échoué';

// VIEWER
export const fetchElevationsFailedMessage = 'La récupération des élévations a échoué';
export const getRoutesFailedMessage = 'La récupération des routes a échoué';
export const fetchTreeNodeFailedMessage = "La récupération du noeud de l'arbre a échoué";

// CONFIGURATION
export const getFileExtensionsFailedMessage = 'La récupération des extensions de fichiers a échoué';
export const getDocumentHistoryFailedMessage =
  "La récupération de la date d'implementation de l'historique des documents a échoué";

// SERVER
export const getInternalServerError = 'Une erreur technique est survenue';
