import config from 'app-config';

const { ORDER } = config.METADATA;

function formatForRequest(filters) {
  return filters
    ? Object.values(filters)
        .reduce((result, filter) => {
          const splitedPath = filter.split('-');
          const filterCategory = splitedPath.shift();
          const filterPath = splitedPath.join('-');
          const resultCategory = result.find((element) => element.type === filterCategory);
          if (!resultCategory) {
            result.push({ type: filterCategory, elements: [filterPath] });
          } else {
            resultCategory.elements.push(filterPath);
          }
          return result;
        }, [])
        .map((item) => `${item.type},${item.elements.join(',')}`)
    : undefined;
}

function formatForUpload(metadata) {
  return Object.keys(metadata).map((key) => ({
    mdType: key,
    mdValue: [metadata[key]],
  }));
}

function formatDoc(doc) {
  return ORDER.map((key) => ({
    mdType: key,
    mdValue: [doc[key].path],
  }));
}

function activeList(list) {
  return list.reduce((acc, cal) => {
    if (cal.isActive) {
      acc.push(cal);
    }
    if (cal.children.length) {
      acc.push(...activeList(cal.children));
    }
    return acc;
  }, []);
}

function searchMetadata(term, metadata) {
  if (!term || metadata.length === 0) {
    return metadata;
  }

  const lowerCaseTerm = term.toLowerCase();
  return metadata.reduce((acc, item) => {
    const { type, value, children } = item;

    if (type.toLowerCase().includes(lowerCaseTerm) || value.toLowerCase().includes(lowerCaseTerm)) {
      acc.push({ ...item, children: [] });
    }
    return acc.concat(searchMetadata(term, children));
  }, []);
}

function updateMetadataOrderKey(metadataList) {
  return metadataList.map((md, index) => ({
    ...md,
    order: index,
    children: md.children.length > 0 ? updateMetadataOrderKey(md.children) : [],
  }));
}

function countMetadata(metadata) {
  const childrenPaths = Object.keys(metadata.children);
  return childrenPaths.reduce((result, path) => {
    if (metadata.children[path].isActive) {
      if (metadata.children[path].children && Object.keys(metadata.children[path].children).length > 0) {
        return result + 1 + countMetadata(metadata.children[path]);
      }
      return result + 1;
    }
    if (metadata.children[path].children && Object.keys(metadata.children[path].children).length > 0) {
      return result + countMetadata(metadata.children[path]);
    }
    return result;
  }, 0);
}

const settleMetadata = (metadata, currentPath) => ({
  ...metadata,
  isEditable: metadata.path === currentPath,
  children: metadata.children.length ? metadata.children.map((child) => settleMetadata(child, currentPath)) : [],
});

const concatTypToMetadata = (md) => ({
  ...md,
  path: `TYP-${md.path}`,
  children: md.children ? md.children.map((child) => concatTypToMetadata(child)) : [],
});

function searchMetadataDisplayValue(metadataPath, metadata) {
  if (!metadataPath || metadata.length === 0) {
    return metadata;
  }
  return metadata.reduce((acc, item) => {
    const { type, path, value, children } = item;

    if (path === metadataPath) {
      acc.push(`${value} (${type})`);
    }
    return acc.concat(searchMetadataDisplayValue(metadataPath, children));
  }, []);
}

function countSelectedMetadata(selectedMetadata) {
  return selectedMetadata.reduce((acc, mdPath) => {
    const filterCategory = mdPath.split('-').shift();
    if (!acc[filterCategory]) {
      acc[filterCategory] = 1;
    } else {
      acc[filterCategory] += 1;
    }
    return acc;
  }, {});
}

function shallowCompareMetadata(m1, m2) {
  return (
    ORDER.every((mKey) => m1[mKey] !== undefined) &&
    Object.entries(m1).every(([key, val]) => val === m2[key]) &&
    Object.entries(m2).every(([key, val]) => val === m1[key])
  );
}

export {
  countMetadata,
  formatDoc,
  formatForRequest,
  formatForUpload,
  searchMetadata,
  settleMetadata,
  updateMetadataOrderKey,
  concatTypToMetadata,
  searchMetadataDisplayValue,
  countSelectedMetadata,
  shallowCompareMetadata,
  activeList,
};
