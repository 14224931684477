import config from 'app-config';
import theme from 'theme';
import actionTypes from './actionTypes';

const defaultState = config.VIEWER_CONTROL_PANELS.map((panel) => ({
  ...panel,
  isOpen: false,
  width: 76,
  height: 39,
}));

function controlPanelReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_OPEN: {
      let index = null;
      const newState = state.map((panel, i) => {
        if (action.payload !== panel.key) {
          return panel;
        }
        index = i;
        return {
          ...panel,
          isOpen: !panel.isOpen,
          width: panel.isOpen ? 76 : 470,
          height: panel.isOpen ? 39 : 340,
        };
      });
      // swap the panel element with the first index
      if (index) {
        newState.splice(0, 0, newState.splice(index, 1)[0]);
      }
      return newState;
    }
    case actionTypes.RESIZE_PANEL: {
      let index = null;
      const newState = state.map((panel, i) => {
        if (action.payload.key !== panel.key) {
          return panel;
        }
        index = i;
        return {
          ...panel,
          width: action.payload.width,
          height: action.payload.height,
          xPosition: action.payload.xPosition,
          // FIXME [ABZ] cf commit
          yPosition: action.payload.yPosition - theme.height.header,
        };
      });
      if (index) {
        newState.splice(0, 0, newState.splice(index, 1)[0]);
      }
      return newState;
    }
    case actionTypes.UPDATE_POSITION: {
      let index = null;
      const newState = state.map((panel, i) => {
        if (action.payload.key !== panel.key) {
          return panel;
        }
        index = i;
        return {
          ...panel,
          xPosition: action.payload.xPosition,
          yPosition: action.payload.yPosition,
        };
      });
      if (index) {
        newState.splice(0, 0, newState.splice(index, 1)[0]);
      }
      return newState;
    }
    case actionTypes.UPDATE_DEPTH: {
      const index = state.findIndex((panel) => panel.key === action.payload.key);
      const newState = [...state];
      if (index !== -1 && index) {
        newState.splice(0, 0, newState.splice(index, 1)[0]);
      }
      return newState;
    }
    case actionTypes.CLOSE_ALL:
      return defaultState.map((panel) => {
        const statePanel = state.find(({ key }) => panel.key === key);
        return { ...statePanel, isOpen: false };
      });
    default:
      return state;
  }
}

export default controlPanelReducer;
