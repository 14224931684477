import * as Yup from 'yup';
import config from '../../app-config';

const invalidField = 'Champ invalide';
const invalidCode = 'Code invalide';
const invalidKey = 'Clé invalide';
const invalidPassword =
  'Votre mot de passe doit contenir au moins 12 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial';
const newPasswordShallBeNewOne = 'Le nouveau mot de passe doit être différent du précédent';
const invalidPasswordConfirmation = 'Veuillez entrer 2 mots de passe identiques';
const requiredField = 'Champ requis';
const dateRegex = /^(?:31\/(?:0[13578]|1[02])|(?:29|30)\/(?:0[13-9]|1[0-2])|(?:0[1-9]|1\d|2[0-8])\/(?:0[1-9]|1[0-2]))\/(?:1[6-9]|[2-9]\d)\d{2}$|^29\/02\/(?:1[6-9]|[2-9]\d)(?:[02468][048]|[13579][26])$/;
const codeRegex = /^[a-zA-Z0-9]{3,9}$/;
const keyRegex = /^[A-Za-z0-9]{1,3}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{12,}$/;
const labelRegex = /^[^*\s/?\\:[\]][^*/?\\:[\]]*$/;

const string = () => Yup.string().typeError(invalidField);
const number = () => Yup.number().typeError(invalidField);
const integer = () => number().integer(invalidField);
const positiveNumber = () => Yup.number().typeError(invalidField).min(0, invalidField);
const positiveInteger = () => positiveNumber().integer(invalidField);
const object = () => Yup.object();
const array = () => Yup.array();
const email = () => string().email(invalidField);
const stringifiedDate = () => Yup.string().matches(dateRegex, invalidField);
const date = () => Yup.date().typeError(invalidField);
const code = () => Yup.string().matches(codeRegex, invalidCode);
const password = () => Yup.string().matches(passwordRegex, invalidPassword);
const passwordConfirmation = (passwordRef) => Yup.string().oneOf([Yup.ref(passwordRef)], invalidPasswordConfirmation);
const label = () => Yup.string().max(config.FORM.MAX_LENGTH_255, invalidField).matches(labelRegex, invalidField);
const key = () => Yup.string().matches(keyRegex, invalidKey);
const mixed = () => Yup.mixed();
const boolean = () => Yup.boolean();

const fromDate = () => {
  return Yup.date().test('custom-fromDate', invalidField, function customFromDate(value) {
    if (this.parent.date !== 'custom' || value === undefined) {
      return true;
    }

    return this.parent.toDate === undefined || value <= this.parent.toDate;
  });
};

const toDate = () => {
  return Yup.date().test('custom-toDate', invalidField, function customToDate(value) {
    if (this.parent.date !== 'custom' || value === undefined) {
      return true;
    }

    return this.parent.fromDate === undefined || this.parent.fromDate <= value;
  });
};

const numberNoSpace = () => number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value));
const integerNoSpace = () => integer().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value));

const requiredString = () => string().required(requiredField);
const requiredLabel = () => label().required(requiredField);
const requiredBoolean = () => boolean().required(requiredField);
const requiredPositiveNumber = () => positiveNumber().required(requiredField);
const requiredPositiveInteger = () => positiveInteger().required(requiredField);
const requiredObject = () => object().required(requiredField);
const requiredEmail = () => email().required(requiredField);
const requiredDate = () => stringifiedDate().required(requiredField);
const requiredCode = () => code().required(requiredField);
const requiredPassword = (oldPassword) =>
  password()
    .required(requiredField)
    .notOneOf([Yup.ref(oldPassword)], newPasswordShallBeNewOne);
const requiredPasswordConfirmation = (passwordRef) => passwordConfirmation(passwordRef).required(requiredField);
const requiredKey = () => key().required(requiredField);
const requiredArray = () => array().min(1, requiredField);
const requiredMixed = () => mixed().required(requiredField);

const validate = (schema, value) => {
  try {
    schema().validateSync(value);
  } catch (err) {
    return err.message;
  }
  return '';
};

export default {
  array,
  stringifiedDate,
  date,
  fromDate,
  toDate,
  integer,
  integerNoSpace,
  number,
  numberNoSpace,
  object,
  string,
  label,
  email,
  code,
  password,
  passwordConfirmation,
  requiredString,
  requiredLabel,
  requiredBoolean,
  requiredPositiveNumber,
  requiredPositiveInteger,
  requiredObject,
  requiredEmail,
  requiredDate,
  requiredCode,
  requiredPassword,
  requiredPasswordConfirmation,
  requiredKey,
  invalidField,
  requiredField,
  requiredArray,
  validate,
  mixed,
  requiredMixed,
  boolean,
};
