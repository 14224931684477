import actionTypes from './actionTypes';

const defaultState = {
  isLoading: false,
  loadingCount: 0,
};

function loadingReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      if (action.isLoading) {
        return { ...state, isLoading: action.isLoading, loadingCount: state.loadingCount + 1 };
      }
      return state.loadingCount <= 1
        ? { ...state, isLoading: false, loadingCount: 0 }
        : { ...state, loadingCount: state.loadingCount - 1 };

    default:
      return state;
  }
}

export default loadingReducer;
