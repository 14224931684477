import { configureStore } from '@reduxjs/toolkit';
import { backendServiceApi } from 'common/api/backend-service-api';
import { persistStore } from 'redux-persist';
import errorLogger from './middleware/error-logger';
import unauthorizedQueryErrorHandler from './middleware/unauthorized-query-error-handler';
import appReducerCreator from './reducers';

const configureAppStore = () => {
  const root = appReducerCreator();
  const store = configureStore({
    reducer: {
      [backendServiceApi.reducerPath]: backendServiceApi.reducer,
      ...root,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(errorLogger)
        .concat(unauthorizedQueryErrorHandler)
        .concat(backendServiceApi.middleware),
    devTools: process.env.NODE_ENV !== 'production',
  });
  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureAppStore;
