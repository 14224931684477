import config from 'app-config';

const {
  SYSTEM_GROUPS,
  METADATA: { ORDER, NAME },
} = config;

export const shallowArrayCompare = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

export function omitObjectKey(obj, key) {
  const target = {};
  if (!obj) {
    return obj;
  }
  if (!key) {
    return obj;
  }
  const keys = Object.keys(obj);
  for (let i = keys.length - 1; i >= 0; i -= 1) {
    if (Object.prototype.hasOwnProperty.call(obj, keys[i]) && keys[i] !== key) {
      target[keys[i]] = obj[keys[i]];
    }
  }
  return target;
}

export function removeItemFromArray(array, targetItem) {
  return array.filter((item) => item !== targetItem);
}

export function isIfc(document) {
  return document?.ext?.toUpperCase() === '.IFC';
}

export function formatVisibility(groupsFilter) {
  if (!groupsFilter || !Array.isArray(groupsFilter)) {
    return undefined;
  }
  const visibility = { isPrivate: false, groups: [] };
  if (groupsFilter.includes(SYSTEM_GROUPS.SELF.name)) {
    visibility.isPrivate = true;
  }

  visibility.groups = groupsFilter.filter((g) => g !== SYSTEM_GROUPS.SELF.name);
  return visibility;
}

export function formatEvent(event, statusTypes) {
  const splitType = event.type.split('.');
  const commonData = {
    user: `${event.creationOperation.firstName} ${event.creationOperation.lastName}`,
    date: event.creationOperation.date,
  };
  const noValue = 'Aucune valeur';
  switch (splitType[0]) {
    case 'code':
      return [{ ...commonData, action: `a créé le document "${event.value}"` }];
    case 'title':
      return [
        {
          ...commonData,
          action: `a mis à jour "Nom du document"`,
          oldValue: event.previousValue || noValue,
          newValue: event.value,
        },
      ];
    case 'ext':
      return [
        {
          ...commonData,
          action: `a mis à jour "Extension"`,
          oldValue: event.previousValue || noValue,
          newValue: event.value,
        },
      ];
    case 'isArchived':
      if (event.previousValue === null) {
        return [];
      }

      return [
        {
          ...commonData,
          action: event.previousValue ? 'a restauré le document' : 'a archivé le document',
        },
      ];
    case 'nbRevisions':
      return [
        {
          ...commonData,
          action: `a mis à jour "Nombre de révision"`,
          oldValue: event.previousValue?.toString() || noValue,
          newValue: event.value.toString(),
        },
      ];
    case 'status':
      return [
        {
          ...commonData,
          action: `a modifié le statut`,
          oldValue: statusTypes[event.previousValue]?.label || noValue,
          newValue: statusTypes[event.value]?.label || noValue,
        },
      ];
    case 'metadata':
      return [...ORDER].reverse().reduce(
        (acc, curr) => [
          ...acc,
          ...(event.value.find((v) => v.mdType === curr).mdValue[0] !==
          event.previousValue?.find((v) => v.mdType === curr).mdValue[0]
            ? [
                {
                  ...commonData,
                  action: `a mis à jour "${NAME[curr]}"`,
                  oldValue: event.previousValue?.find((v) => v.mdType === curr).mdValue[0] || noValue,
                  newValue: event.value.find((v) => v.mdType === curr).mdValue[0],
                },
              ]
            : []),
        ],
        []
      );
    default:
      return [];
  }
}

export function getFlatSort(sort) {
  let currentSort = ['lastUpdateOperation.date,desc'];

  if (Object.keys(sort).length) {
    currentSort = [`${sort.sort},${sort.order}`];
    if (sort.sort === 'links.creationDate') {
      currentSort.push(`lastUpdateOperation.date,${sort.order}`);
    }
  }

  return currentSort;
}
