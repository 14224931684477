import React from 'react';
import { Link, useParams } from 'react-router-dom';

const OrganizationCrumb = () => {
  const { organizationSysId } = useParams();

  return <Link to={`/organization/${organizationSysId}/estate`}>Accueil patrimoine</Link>;
};

export default OrganizationCrumb;
