import React from 'react';
import { StyledSharedTitleError, StyledSharedWrapperError, StyledSharedListError } from 'components/styled-template';
import { Logo } from '@datasoluce/ui-design-system-ts';
import styled from 'styled-components';

const StyledLogoWrapper = styled.div`
  width: 240px;
`;

const ErrorCookie = () => (
  <StyledSharedWrapperError>
    <StyledSharedTitleError>Cookies désactivés</StyledSharedTitleError>
    <StyledLogoWrapper>
      <Logo variant="pixel-title-vertical" grayscale />
    </StyledLogoWrapper>
    <StyledSharedListError>
      <li>
        Votre navigateur est actuellement configuré pour bloquer les cookies. Vous devez autoriser les cookies pour
        utiliser ce service. Veillez à ce qu&apos;ils soient activés et réessayez.
      </li>
      <li>
        Data Soluce utilise les cookies à des fins de sécurité, et n&apos;a pas d&apos;usage commercial des données
        récoltées.
      </li>
      <li>Pour autoriser les cookies, consulter l&apos;aide en ligne de votre navigateur Web</li>
    </StyledSharedListError>
  </StyledSharedWrapperError>
);

export default ErrorCookie;
