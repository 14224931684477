import React from 'react';
import styled from 'styled-components';
import SearchField from 'components/fields/search-field';
import AddButton from 'components/buttons/add-button';

const Wrapper = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.s};
  margin-bottom: ${({ theme }) => theme.margin.l};
`;

const SearchFieldWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const Header = ({ onCreate, onSearch, searchValue, resetSearch, resetAutoFocus }) => {
  return (
    <Wrapper>
      {onCreate && <AddButton label="Nouvelle métadonnée à la racine" onClick={onCreate} />}
      <SearchFieldWrapper>
        <SearchField
          onSearch={onSearch}
          placeholder="Code ou mot-clef"
          value={searchValue}
          resetSearch={resetSearch}
          resetAutoFocus={resetAutoFocus}
        />
      </SearchFieldWrapper>
    </Wrapper>
  );
};
export default Header;
