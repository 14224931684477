import { useCallback, useEffect } from 'react';

export const ESC_KEY = 27;

export default function useKeyPress(targetKeyCode, cb) {
  const downHandler = useCallback(
    (e) => {
      if (e.keyCode === targetKeyCode) {
        cb();
      }
    },
    [cb, targetKeyCode]
  );

  const upHandler = useCallback(
    (e) => {
      if (e.keyCode === targetKeyCode) {
        cb();
      }
    },
    [cb, targetKeyCode]
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);
}
