import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from 'components/checkbox';
import { extractActiveList } from 'utils/common/utils';
import { useParams } from 'react-router-dom';
import useModal from 'hooks/use-modal';
import config from 'app-config';
import { useUpdateMetadataMutation } from 'common/api/backend/metadata-api';
import { useGetProjectQuery } from 'common/api/backend/project-api';
import { ButtonIcon } from '@datasoluce/ui-design-system-ts';
import MetadataTree from '../index';

const { METADATA_MODALS } = config;

const StyledMetadataWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  color: ${({ theme }) => theme.color.black};
  margin: ${({ theme }) => theme.margin.sm} 0;

  > div:first-of-type {
    margin-right: 10px;
    width: auto;
  }
`;

const MetadataValue = styled.div`
  font-size: ${({ theme }) => theme.fontSize.m};
  flex: 0 0 400px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MetadataItemComponent = ({
  enableHover,
  disableDrop,
  metadata,
  metadata: { value, type, path, children, isActive },
  activeMetadataCount,
  currentCategory,
}) => {
  const { projectSysId } = useParams();
  const { data: project, isSuccess: isSuccessProject } = useGetProjectQuery({ projectSysId });
  const canInteract = useMemo(
    () => activeMetadataCount > 1 && activeMetadataCount - 1 > extractActiveList(children).length,
    [activeMetadataCount, children]
  );

  const isDeleteEnabled = useMemo(() => !children.length && (canInteract || (!isActive && !canInteract)), [
    canInteract,
    children,
    isActive,
  ]);

  const [updateMetadata] = useUpdateMetadataMutation();

  const { showModalMutation } = useModal();

  const toggleActive = (mdIsActive) =>
    updateMetadata({ projectSysId, path: `${currentCategory}-${path}`, data: { isActive: mdIsActive } });

  return (
    isSuccessProject && (
      <>
        <StyledMetadataWrapper>
          <Checkbox
            backgroundColor="#3ac596"
            selected={isActive}
            onClick={() => toggleActive(!isActive)}
            editable={project.capabilities.canCreateMetadata && (canInteract || !isActive)}
            name={value}
          />
          <MetadataValue>{`${value} (${type})`}</MetadataValue>
          {project.capabilities.canCreateMetadata && (
            <ButtonIcon
              icon="plus"
              size="small"
              onClick={() =>
                showModalMutation(METADATA_MODALS.METADATA_CREATE, {
                  path: `${currentCategory}-${metadata.path}`,
                  projectSysId,
                })
              }
              title="Créer un enfant"
            />
          )}
          {project.capabilities.canUpdateMetadata && (
            <ButtonIcon
              icon="pencil"
              size="small"
              onClick={() => {
                showModalMutation(METADATA_MODALS.METADATA_UPDATE, {
                  currentMetadata: metadata,
                  currentCategory,
                  projectSysId,
                });
              }}
              title="Renommer"
            />
          )}
          {project.capabilities.canDeleteMetadata && (
            <ButtonIcon
              icon="trashcan"
              size="small"
              disabled={!isDeleteEnabled}
              onClick={(e) => {
                e.preventDefault();
                if (isDeleteEnabled) {
                  showModalMutation(METADATA_MODALS.METADATA_DELETE, { projectSysId, metadata, currentCategory });
                }
              }}
              title="Supprimer"
            />
          )}
        </StyledMetadataWrapper>
        {children?.length > 0 && (
          <MetadataTree
            metadata={children}
            parent={path}
            enableParentHover={enableHover}
            activeMetadataCount={activeMetadataCount}
            currentCategory={currentCategory}
            disableDrop={disableDrop || !project.capabilities.canUpdateMetadata}
          />
        )}
      </>
    )
  );
};

MetadataItemComponent.propTypes = {
  activeMetadataCount: PropTypes.number.isRequired,
  currentCategory: PropTypes.string.isRequired,
  disableDrop: PropTypes.bool.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default MetadataItemComponent;
