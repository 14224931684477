import { useGetProjectQuery } from 'common/api/backend/project-api';
import { fetchProjectFailedMessage } from 'common/message/error-messages';
import UploadMenu from 'components/upload-menu/index';
import ErrorPage from 'pages/error/index';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LayoutComponent from '../components/layout';
import ProjectHeader from './components/project-header';

const ProjectLayoutComponent = () => {
  const { pathname } = useLocation();
  const { organizationSysId, projectSysId } = useParams();
  const navigate = useNavigate();
  const { data: projectDetails, isSuccess, isError } = useGetProjectQuery({ projectSysId });
  const entries = isSuccess
    ? [
        {
          label: 'Tableau de bord',
          icon: 'union',
          path: `/organization/${organizationSysId}/project/${projectSysId}/dashboard`,
          hasAuthorization: projectDetails.capabilities.canReadDashboard,
        },
        {
          label: 'Documents',
          icon: 'new-document',
          path: `/organization/${organizationSysId}/project/${projectSysId}/edm`,
          hasAuthorization: projectDetails.capabilities.canReadDocuments,
        },
        {
          label: 'Dossiers',
          icon: 'folder',
          path: `/organization/${organizationSysId}/project/${projectSysId}/folder`,
          hasAuthorization: projectDetails.capabilities.canReadFolders,
        },
        {
          label: 'Assemblages BIM',
          icon: 'assemble',
          path: `/organization/${organizationSysId}/project/${projectSysId}/bim/assembly`,
          hasAuthorization: projectDetails.capabilities.canReadAssemblies,
        },
        {
          label: 'Nomenclatures',
          icon: 'bookmark',
          path: `/organization/${organizationSysId}/project/${projectSysId}/manage-metadata`,
          hasAuthorization: projectDetails.capabilities.canReadMetadata,
        },
        {
          label: 'Intervenants',
          icon: 'user',
          path: `/organization/${organizationSysId}/project/${projectSysId}/manage-users/members`,
          hasAuthorization: projectDetails.capabilities.canReadUsers,
        },
        {
          label: 'Administration',
          icon: 'user-permission',
          path: `/organization/${organizationSysId}/project/${projectSysId}/manage-project/settings-metadata`,
          hasAuthorization: projectDetails.capabilities.canUpdateConfiguration,
        },
      ]
        .filter((item) => item.hasAuthorization)
        .map((entry) => {
          return {
            ...entry,
            active: pathname.includes(entry.path),
            onNavigate: () => navigate(entry.path),
          };
        })
    : [];

  if (isError) {
    return <ErrorPage errorMessage={fetchProjectFailedMessage} />;
  }
  return (
    isSuccess && (
      <LayoutComponent
        navEntries={entries}
        headerTitleSlot={<ProjectHeader project={projectDetails} />}
        headerRightSlot={<UploadMenu projectSysId={projectDetails.sysId} />}
        entryPath={entries.length > 0 && entries[0].path}
      />
    )
  );
};

export default ProjectLayoutComponent;
