import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Loader from 'components/loader';
import useUserSession from 'hooks/use-user-session';

const AuthenticatedUserOutlet = () => {
  const { pathname } = useLocation();

  const { isFetching, isError, data: user } = useUserSession();

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return <Navigate to="/login" state={{ from: pathname }} />;
  }

  if (pathname !== '/user-activate' && (user?.status !== 'ACTIVE' || user?.isComplete === false)) {
    return <Navigate to="/user-activate" />;
  }

  if (pathname === '/user-activate' && user?.status === 'ACTIVE' && user?.isComplete) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default AuthenticatedUserOutlet;
