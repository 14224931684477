import UserLayoutComponent from 'components/layout/user-layout';
import UserActivationLayoutComponent from 'components/layout/user-activation-layout';
import Loader from 'components/loader';
import AuthenticatedUserOutlet from 'components/outlet/authenticated-user-outlet';
import PageContainer from 'components/page-container';
import Snackbar from 'components/snackbar';
import ErrorCookie from 'pages/errorCookie/index';
import { Suspense } from 'react';
import { Outlet, createBrowserRouter } from 'react-router-dom';
import lazyWithRetry from 'utils/common/lazy-with-retry';
import OrganizationCrumb from 'components/organization-crumb';
import publicRoutes from './public';
import organizationRoutes from './organization';
import projectRoutes from './project';
import userRoutes from './user';

const ErrorPage = lazyWithRetry(() => import('pages/error'));
const UserActivatePage = lazyWithRetry(() => import('pages/user-activate'));

const ErrorBoundary = ({ errorMessage }) => {
  return (
    <PageContainer>
      <ErrorPage {...{ errorMessage }} />
    </PageContainer>
  );
};

export const routes = () => [
  {
    element: (
      <>
        <Outlet />
        <Snackbar />
      </>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        children: publicRoutes,
        errorElement: <ErrorBoundary />,
      },
      {
        element: <AuthenticatedUserOutlet />,
        children: [
          {
            element: <UserActivationLayoutComponent />,
            children: [
              {
                path: '/user-activate',
                element: (
                  <Suspense fallback={<Loader />}>
                    <UserActivatePage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            element: <UserLayoutComponent />,
            children: userRoutes,
          },
          {
            path: '/organization/:organizationSysId',
            children: [...organizationRoutes, ...projectRoutes],
            handle: { crumb: () => <OrganizationCrumb /> },
          },
        ],
      },
      {
        path: '*',
        element: <ErrorBoundary />,
      },
    ],
  },
];

const noCompatibilityRouter = createBrowserRouter([
  {
    path: '*',
    element: (
      <>
        <ErrorBoundary />
        <ErrorCookie />
      </>
    ),
  },
]);

const router = createBrowserRouter(routes());

export default navigator.cookieEnabled ? router : noCompatibilityRouter;
