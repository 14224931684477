import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmDeleteModal from 'components/confirm-delete-modal';
import useModal from 'hooks/use-modal';
import { useArchiveProjectMutation } from 'common/api/backend/project-api';

const ArchiveProjectModal = ({ project }) => {
  const [modalContext, setModalContext] = useState({
    message: '',
    details: [],
  });

  const { projectSysId } = useParams();
  const navigate = useNavigate();

  const [archiveProject] = useArchiveProjectMutation();
  const { hideModalMutation } = useModal();

  useEffect(() => {
    setModalContext({
      message: (
        <div>
          Archiver <b>{project.name}</b> ? Toutes les opérations en cours seront annulées et les accès aux données du
          projet seront suspendus.
        </div>
      ),
      details: [],
    });
  }, [project.name]);

  const onConfirmArchiveProject = () => {
    archiveProject({ projectSysId: project.sysId });
    hideModalMutation();
    if (projectSysId) {
      navigate(`/organization/${project.scope.platformSysId}/estate/current`);
    }
  };

  return (
    <ConfirmDeleteModal
      isOpen
      buttonName="Archiver"
      message={modalContext.message}
      onCancel={() => hideModalMutation()}
      onDelete={onConfirmArchiveProject}
    />
  );
};

ArchiveProjectModal.propTypes = {
  project: PropTypes.object.isRequired,
};

export default ArchiveProjectModal;
