import api from '../apiCaller';

function getAssembly(projectSysId, assemblySysId) {
  return api.get(`/api/project/${projectSysId}/assembly/${assemblySysId}`, {});
}

function getAssemblies(projectSysId, params) {
  return api.get(`/api/project/${projectSysId}/assemblies`, params);
}

function getModels(projectSysId, assemblySysId) {
  return api.get(`/api/project/${projectSysId}/assembly/${assemblySysId}/models`);
}

function createAssembly(projectSysId, data) {
  return api.post(`/api/project/${projectSysId}/assembly`, data);
}

function deleteAssembly(projectSysId, assemblySysId) {
  return api.del(`/api/project/${projectSysId}/assembly/${assemblySysId}`, {});
}

function addModel(projectSysId, assemblySysId, documentSysId) {
  return api.post(`/api/project/${projectSysId}/assembly/${assemblySysId}/model/${documentSysId}`);
}

function deleteModel(projectSysId, assemblySysId, documentSysId) {
  return api.del(`/api/project/${projectSysId}/assembly/${assemblySysId}/model/${documentSysId}`, {});
}

function amendAssembly(projectSysId, assemblySysId, data) {
  return api.patch(`/api/project/${projectSysId}/assembly/${assemblySysId}`, data);
}

function getElevations(projectSysId, assemblySysId) {
  return api.get(`/api/project/${projectSysId}/assembly/${assemblySysId}/data/elevations`, {});
}

function getRoutes(projectSysId, assemblySysId) {
  return api.get(`/api/project/${projectSysId}/assembly/${assemblySysId}/data/routes`, {});
}

export default {
  getAssembly,
  createAssembly,
  getAssemblies,
  addModel,
  deleteModel,
  deleteAssembly,
  amendAssembly,
  getElevations,
  getModels,
  getRoutes,
};
