import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useGetOrganizationQuery } from 'common/api/backend/organization-api';
import lazyWithRetry from 'utils/common/lazy-with-retry';
import { fetchOrganizationFailedMessage } from 'common/message/error-messages';
import LayoutComponent from '../components/layout';

const ErrorPage = lazyWithRetry(() => import('pages/error'));

const OrganizationLayoutComponent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { organizationSysId } = useParams();
  const { data: organizationDetails, isSuccess, isError } = useGetOrganizationQuery({ organizationSysId });

  const entries = isSuccess
    ? [
        {
          label: 'Projets',
          icon: 'gallery',
          path: `/organization/${organizationSysId}/estate`,
          hasAuthorization: organizationDetails.capabilities.canReadProjects,
        },
        {
          label: 'Membres',
          icon: 'user',
          path: `/organization/${organizationSysId}/manage-organization-users`,
          hasAuthorization: organizationDetails.capabilities.canReadUsers,
        },
      ]
        .filter((item) => item.hasAuthorization)
        .map((entry) => {
          const { path, ...rest } = entry;
          return {
            ...rest,
            active: pathname.includes(path),
            onNavigate: () => navigate(path),
          };
        })
    : [];

  if (isError) {
    return <ErrorPage errorMessage={fetchOrganizationFailedMessage} />;
  }

  return isSuccess && <LayoutComponent navEntries={entries} headerTitleSlot={organizationDetails.name} />;
};

export default OrganizationLayoutComponent;
