import { lazy } from 'react';

export default (componentImport) =>
  lazy(async () => {
    try {
      const component = await componentImport();
      window.sessionStorage.removeItem('lazy-with-retry');
      return component;
    } catch (e) {
      if (!window.sessionStorage.getItem('lazy-with-retry')) {
        window.sessionStorage.setItem('lazy-with-retry', 'done');
        return window.location.reload();
      }
      throw e;
    }
  });
