import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import organizationApi from 'common/api/backend/organization-api';
import projectApi from 'common/api/backend/project-api';

const slice = createSlice({
  name: 'userSlice',
  initialState: { selectedUsers: {} },
  reducers: {
    selectUsers: (state, action) => {
      if (action.payload.users.length === 0) {
        return { ...state, selectedUsers: { ...state.selectedUsers, [action.payload.sysId]: [] } };
      }
      return {
        ...state,
        selectedUsers: {
          ...state.selectedUsers,
          [action.payload.sysId]: action.payload.users.reduce(
            (acc, current) =>
              acc.find((sysId) => sysId === current) === undefined
                ? [...acc, current]
                : acc.filter((sysId) => current !== sysId),
            [...(state.selectedUsers[action.payload.sysId] || [])],
            []
          ),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        organizationApi.endpoints.getOrganizationUsers.matchFulfilled,
        projectApi.endpoints.getProjectUsers.matchFulfilled
      ),
      (state, { meta, payload: { users } }) => {
        const sysId = meta.arg.originalArgs.organizationSysId || meta.arg.originalArgs.projectSysId;
        if (state.selectedUsers[sysId]) {
          return {
            ...state,
            selectedUsers: {
              ...state.selectedUsers,
              [sysId]: state.selectedUsers[sysId].map((selectedUser) => {
                const toUpdate = users.find((user) => user.sysId === selectedUser.sysId);
                return toUpdate || selectedUser;
              }),
            },
          };
        }
        return state;
      }
    );
    builder.addMatcher(
      isAnyOf(
        organizationApi.endpoints.deleteOrganizationUsers.matchFulfilled,
        projectApi.endpoints.deleteProjectUsers.matchFulfilled
      ),
      (state, { meta }) => {
        const sysId = meta.arg.originalArgs.organizationSysId || meta.arg.originalArgs.projectSysId;
        if (state.selectedUsers[sysId]) {
          return {
            ...state,
            selectedUsers: {
              ...state.selectedUsers,
              [sysId]: meta.arg.originalArgs.data.reduce(
                (acc, currentSysId) => acc.filter((u) => currentSysId !== u.sysId),
                [...state.selectedUsers[sysId]],
                []
              ),
            },
          };
        }
        return state;
      }
    );
  },
});

export const { selectUsers, setSelectedUsers } = slice.actions;
export default slice.reducer;
