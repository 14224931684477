import actionTypes from './actionTypes';

const defaultState = {
  isLoading: true,
  isolatedItems: {},
  cutPlanes: [],
  selectedItems: {},
  selectedItemsFromTree: {},
  options: {
    ghosting: true,
    reverseZoomDirection: false,
  },
  selectedElementData: {
    name: '',
    properties: [],
    documents: [],
    articleTrees: [],
    articles: [],
  },
};

function viewerReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.SET_VIEWER_LOADING:
      return { ...state, isLoading: action.loading };
    case actionTypes.ISOLATE_ITEMS:
      return { ...state, isolatedItems: { ...action.payload } };
    case actionTypes.EMPTY_ITEMS:
      return { ...state, isolatedItems: {} };
    case actionTypes.SET_CUT_PLANES:
      return { ...state, cutPlanes: action.payload };
    case actionTypes.SET_SELECTED_ITEMS:
      return { ...state, selectedItems: action.selectedItems };
    case actionTypes.SET_SELECTED_ITEMS_FROM_TREE:
      return { ...state, selectedItemsFromTree: action.selectedItems };
    case actionTypes.RESET_SELECTED_ITEMS:
      return { ...state, selectedItems: {}, selectedItemsFromTree: {} };
    case actionTypes.SET_SELECTED_IDS_BY_MODEL:
      return { ...state, selectedIdsByModel: action.selectedIdsByModel };
    case actionTypes.SET_HIDDEN_IDS_BY_MODEL:
      return { ...state, hiddenIdsByModel: action.hiddenIdsByModel };
    case actionTypes.RESET_HIDDEN_IDS_BY_MODEL:
      return { ...state, hiddenIdsByModel: {} };
    case actionTypes.EMPTY_CUT_PLANES:
      return { ...state, cutPlanes: [] };
    case actionTypes.RESET_BIM_ELEMENT_DATA:
      return {
        ...state,
        selectedElementData: {
          name: '',
          properties: [],
          documents: [],
          articleTrees: [],
          articles: [],
        },
      };
    case actionTypes.TOGGLE_GHOSTING:
      return {
        ...state,
        options: { ...state.options, ghosting: !state.options.ghosting },
      };
    case actionTypes.TOGGLE_REVERSE_ZOOM:
      return {
        ...state,
        options: {
          ...state.options,
          reverseZoomDirection: !state.options.reverseZoomDirection,
        },
      };
    case actionTypes.SET_BIM_ELEMENT_DATA:
      return { ...state, selectedElementData: action.elementData };
    case actionTypes.ADD_ARTICLE:
      return {
        ...state,
        selectedElementData: {
          ...state.selectedElementData,
          articles: [...state.selectedElementData.articles, action.article],
        },
      };
    default:
      return state;
  }
}

export default viewerReducer;
