export default {
  SET_TREE: 'SET_TREE',
  SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
  UPDATE_TREE_NODE: 'UPDATE_TREE_NODE',
  UPDATE_ISOLATED_TREE_NODE: 'UPDATE_ISOLATED_TREE_NODE',
  INIT_TREES: 'INIT_TREES',
  SET_VIEWS: 'SET_VIEWS',
  SET_ELEVATIONS: 'SET_TREE_LEVELS_ELEVATION',
  SET_COLOR: 'SET_COLOR',
  SET_COLOR_PROPAGATE: 'SET_COLOR_PROPAGATE',
  SET_SELECTED_NODE_ID: 'SET_SELECTED_NODE_ID',
  TOGGLE_SELECT_TREE_NODE: 'TOGGLE_SELECT_TREE_NODE',
  UNSELECT_ALL: 'UNSELECT_ALL',
};
