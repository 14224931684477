import axios from 'axios';
import qs from 'qs';
import Resumable from 'resumablejs';

function get(url, params) {
  const requestConf = {
    method: 'get',
    url,
    params,
    paramsSerializer: function paramsSerializer(ps) {
      return qs.stringify(ps, { arrayFormat: 'repeat' });
    },
  };

  return axios(requestConf)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function del(url, data, params) {
  const requestConf = {
    method: 'delete',
    url,
    data,
    params,
    paramsSerializer: function paramsSerializer(ps) {
      return qs.stringify(ps, { arrayFormat: 'repeat' });
    },
  };

  return axios(requestConf)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function post(url, data, options = {}) {
  const requestConf = {
    method: 'post',
    url,
    data,
    ...options,
  };

  return axios(requestConf)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function put(url, data, params) {
  const requestConf = {
    method: 'put',
    url,
    data,
    params,
    paramsSerializer: function paramsSerializer(ps) {
      return qs.stringify(ps, { arrayFormat: 'repeat' });
    },
  };

  return axios(requestConf)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function patch(url, data, options = {}) {
  const requestConf = {
    method: 'patch',
    url,
    data,
    ...options,
  };

  return axios(requestConf)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function upload(url, data, method = 'post') {
  const formData = Object.keys(data).reduce((fd, key) => {
    fd.append(key, data[key]);
    return fd;
  }, new FormData());
  const requestConf = {
    method,
    url,
    data: formData,
    // headers: {
    //   'Content-Length': formData.get('file').size,
    // },
  };

  return axios(requestConf)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      throw err;
    });
}

function postResumable(url, data, progFunc, successFunc) {
  return new Promise((resolve, reject) => {
    const chunkSize = 2 * 1024 * 1024;
    const r = new Resumable({
      target: url,
      chunkSize,
      forceChunkSize: true,
      simultaneousUploads: 3,
      maxChunkRetries: 1,
      chunkRetryInterval: 1000,
      testChunks: true,
      throttleProgressCallbacks: 1,
      generateUniqueIdentifier: () => data.key,
    });

    r.on('fileAdded', () => {
      r.upload();
    });
    r.on('fileSuccess', (file) => {
      const completeUrl = `${url}Complete`;
      const postData = {
        resumableChunkSize: chunkSize,
        resumableTotalSize: file.size,
        resumableIdentifier: file.uniqueIdentifier,
        resumableFilename: file.fileName,
      };

      post(completeUrl, postData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });

      if (successFunc) {
        successFunc(file.uniqueIdentifier);
      }
    });
    r.on('fileError', (file, message) => {
      console.log('fileError');
      console.log(`error on resumable-file-${file.uniqueIdentifier}.resumable-file-progress`);
      reject(message);
    });
    r.on('fileProgress', (file) => {
      if (progFunc) {
        progFunc(file.uniqueIdentifier, Math.floor(file.progress() * 100));
      }
    });
    r.on('pause', (file) => {
      console.log(`pause of the resumable-file-${file.uniqueIdentifier}.resumable-file-progress`);
    });
    r.on('cancel', () => {
      console.log(url, 'canceled');
      reject();
    });
    r.addFile(data.file);
  }).catch((err) => {
    console.log(err);
    throw err;
  });
}

export default {
  get,
  post,
  put,
  patch,
  upload,
  del,
  postResumable,
};
