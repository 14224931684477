import { useGetProjectQuery } from 'common/api/backend/project-api';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const ProjectCrumb = () => {
  const { organizationSysId, projectSysId } = useParams();
  const { data: project, isSuccess } = useGetProjectQuery({ projectSysId });
  return isSuccess && <Link to={`/organization/${organizationSysId}/project/${projectSysId}`}>{project.name}</Link>;
};

export default ProjectCrumb;
