import { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { changeGridView, toggleSideMenu, updateColumnsSizes } from 'common/userPreferences/actions';

const useUserPreferences = () => {
  const dispatch = useDispatch();

  const projectsTable = useSelector((state) => state.userPreferences.projectsTable, shallowEqual);
  const edmTable = useSelector((state) => state.userPreferences.edmTable, shallowEqual);
  const uploadTable = useSelector((state) => state.userPreferences.uploadTable, shallowEqual);
  const reqDefTable = useSelector((state) => state.userPreferences.reqDefTable, shallowEqual);

  const menuExpanded = useSelector((state) => state.userPreferences.menuExpanded, shallowEqual);

  const gridView = useSelector((state) => state.userPreferences.gridView, shallowEqual);

  const updateColumnsSizesMutation = useCallback(
    (tableName, columnSizes) => {
      dispatch(updateColumnsSizes(tableName, columnSizes));
    },
    [dispatch]
  );

  const toggleSideMenuMutation = useCallback(() => dispatch(toggleSideMenu()), [dispatch]);

  const changeViewMutation = useCallback((v) => dispatch(changeGridView(v)), [dispatch]);

  return {
    gridView,
    menuExpanded,
    projectsTable,
    edmTable,
    uploadTable,
    reqDefTable,
    updateColumnsSizesMutation,
    toggleSideMenuMutation,
    changeViewMutation,
  };
};

export default useUserPreferences;
