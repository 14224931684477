import { createSlice } from '@reduxjs/toolkit';
import documentApi from 'common/api/backend/document-api';
import { toEndOf, toStartOf } from 'utils/common/date';

const initialState = {
  selectedDocuments: [],
  highlightDocument: {},
  filters: {
    page: 1,
    size: 20,
    sort: { sort: 'lastUpdateOperation.date', order: 'desc' },
    search: undefined,
    advanced: { date: 'all' },
  },
};

const slice = createSlice({
  name: 'edmSlice',
  initialState,
  reducers: {
    cleanupEdm: () => {
      return { ...initialState };
    },
    selectDocuments: (state, action) => {
      if (
        action.payload.selectedDocuments.length === 0 ||
        action.payload.selectedDocuments.length === state.filters.size
      ) {
        return { ...state, selectedDocuments: action.payload.selectedDocuments };
      }
      const newSelection = action.payload.selectedDocuments.reduce(
        (acc, current) =>
          acc.find((sysId) => sysId === current) === undefined ? [...acc, current] : acc.filter((d) => current !== d),
        [...state.selectedDocuments]
      );
      return { ...state, selectedDocuments: newSelection };
    },
    selectDocument: (state, action) => {
      return { ...state, highlightDocument: action.payload };
    },
    changeDocumentsPage: (state, action) => {
      return {
        ...state,
        selectedDocuments: [],
        filters: {
          ...state.filters,
          page: action.payload,
        },
      };
    },
    changeDocumentsPageSize: (state, action) => {
      return {
        ...state,
        selectedDocuments: [],
        filters: {
          ...state.filters,
          size: action.payload,
          page: 1,
        },
      };
    },
    changeDocumentsSort: (state, action) => {
      return {
        ...state,
        selectedDocuments: [],
        filters: {
          ...state.filters,
          sort: action.payload,
        },
      };
    },
    searchDocuments: (state, action) => {
      return {
        ...state,
        selectedDocuments: [],
        filters: {
          ...state.filters,
          search: action.payload,
          page: 1,
        },
      };
    },
    updateAdvancedFilters: (state, action) => {
      const {
        date,
        fromDate,
        toDate,
        extensions,
        emitters,
        approvers,
        contributors,
        statuses,
        groups,
        metadata,
        folders,
      } = action.payload;
      const advancedFilters = {
        fromDate: {
          today: toStartOf().toISO(),
          month: toStartOf('month').toISO(),
          year: toStartOf('year').toISO(),
          custom: fromDate ? toStartOf('day', fromDate).toISO() : undefined,
        }[date],
        toDate: {
          today: toEndOf().toISO(),
          month: toEndOf('month').toISO(),
          year: toEndOf('year').toISO(),
          custom: toDate ? toEndOf('day', toDate).toISO() : undefined,
        }[date],
        date: date ?? undefined,
        extensions: extensions?.length ? extensions : undefined,
        emitters: emitters?.length ? emitters : undefined,
        approvers: approvers?.length ? approvers : undefined,
        contributors: contributors?.length ? contributors : undefined,
        statuses: statuses?.length ? statuses : undefined,
        groups: groups?.length ? groups : undefined,
        folders: folders?.length ? folders : undefined,
        metadata: metadata?.length ? metadata : undefined,
      };
      return {
        ...state,
        selectedDocuments: [],
        filters: {
          ...state.filters,
          advanced: advancedFilters,
          page: 1,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(documentApi.endpoints.updateDocuments.matchFulfilled, (state) => {
        return { ...state, selectedDocuments: [] };
      })
      .addMatcher(documentApi.endpoints.deleteDocuments.matchFulfilled, (state) => {
        return { ...state, selectedDocuments: [] };
      });
  },
});

export const {
  cleanupEdm,
  selectDocuments,
  selectDocument,
  changeDocumentsPage,
  changeDocumentsPageSize,
  changeDocumentsSort,
  searchDocuments,
  updateAdvancedFilters,
} = slice.actions;

export default slice.reducer;
