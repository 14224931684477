import { addError, addSuccess } from 'common/slice/message';
import { copyLinkAddressSuccessMessage } from 'common/message/success-messages';
import { copyLinkAddressFailedMessage } from 'common/message/error-messages';

export const shallowCompare = (o, v) => {
  if (!o && v) {
    return false;
  }
  if (o && !v) {
    return false;
  }
  if (!o && !v) {
    return true;
  }
  return (
    Object.entries(o).every(([key, val]) => val === v[key]) && Object.entries(v).every(([key, val]) => val === o[key])
  );
};

export const extractActiveList = (metadataItems) => {
  if (!metadataItems) {
    return [];
  }
  return metadataItems.reduce((res, item) => {
    if (item.isActive) {
      res.push(item);
    }
    if (item.children && item.children.length > 0) {
      res.push(...extractActiveList(item.children));
    }
    return res;
  }, []);
};

const getProperty = (obj, property) => {
  const properties = property.split('.');
  let value = obj;

  for (const prop of properties) {
    value = value[prop];
    if (value === undefined) {
      break;
    }
  }

  return value;
};

export const compareValues = (a, b, property, order) => {
  const valueA = getProperty(a, property);
  const valueB = getProperty(b, property);

  if (valueA < valueB || valueB === undefined) {
    return -order;
  }
  if (valueA > valueB || valueA === undefined) {
    return order;
  }

  return 0;
};

export const copyLinkAddress = (path, item, location, dispatch) => {
  const { host, protocol } = location;
  navigator.clipboard
    .writeText(`${protocol}//${host}/${path}`)
    .then(() => dispatch(addSuccess({ message: copyLinkAddressSuccessMessage(item) })))
    .catch(() =>
      dispatch(addError({ error: new Error('writeText blocked'), message: copyLinkAddressFailedMessage(item) }))
    );
};
