const ACTIONS = {
  ADMINISTRATE: 'ADMINISTRATE',
  DEVELOP: 'DEVELOP',
};

const COMMON_EXTENSIONS = ['.docx', '.dwg', '.ifc', '.pdf', '.rvt', '.xlsx'];

const ARTICLE_EXTRACTION_STATES = ['Terminée', 'Alerte', 'Erreur', 'En cours'];

const MESSAGE_LEVELS = {
  LEVEL_SUCCESS: 'success',
  LEVEL_ERROR: 'error',
  LEVEL_WARNING: 'warning',
  LEVEL_INFO: 'info',
};

const SNACKBAR_TITLE = {
  WARNING: 'Attention !',
  INFO: 'Le saviez-vous ?',
  SUCCESS: 'Félicitations',
  ERROR: 'Quelque chose ne va pas…',
};

const UPLOAD_LIMIT = 100;
const MAX_UPLOAD_FILESIZE = 2 * 1024 ** 3;

const PROJECTS = {
  OTHER: 'OTHER',
  TYPOLOGIES: {
    HDJ: 'Santé - HDJ',
    SSR: 'Santé - SSR',
    EHPAD: 'Santé - EHPAD',
    RSS: 'Santé - RSS',
    MIXTE: 'Mixte',
    LOGE: 'Logement',
    COM: 'Commerce',
    ENS: 'Enseignement',
    ICDS: 'Infrastructures collectives destinées aux sports',
    REST: 'Restaurant',
    HOT: 'Hôtel',
    LOGI: 'Logistique',
    RAIL: 'Rail',
    OTHER: 'Autre',
  },
  SURFACES: {
    SHOB: 'Surface Hors Œuvre Brut',
    SHON: 'Surface Hors Œuvre Nette',
    SDO: "Surface dans l'œuvre",
    SU: 'Surface utile',
    SHA: 'Surface habitable',
    SPP: 'Surfaces des parties privatives',
    SC: 'Surface couverte',
    SDP: 'Surface de plancher',
    STC: 'Superficie du terrain concerné',
    SPC: 'Superficie de plancher créé',
    SPA: 'Superficie de plancher aménagé',
    OTHER: 'Autre',
  },
  NATURES: {
    CN: 'Construction neuve',
    CE: 'Construction en extension',
    REH: 'Réhabilitation',
    AI: 'Aménagements et installations',
    REN: 'Rénovation',
    RES: 'Restauration',
    CDD: 'Changement de destination',
    OTHER: 'Autre',
  },
  USES: {
    PRO: 'Propre',
    LOC: 'Locatif',
    VSA: 'Vente à un seul acquéreur',
    VC: 'Vente en copropriété',
    OTHER: 'Autre',
  },
  TEMPORALITIES: {
    DDC: 'Date de début de la conception',
    DPC: 'Date de dépôt du permis de construire',
    DCE: 'Date de remise du DCE',
    DOC: "Date d'ouverture du chantier",
    RPT: 'Date de la réception provisoire des travaux',
    DLB: 'Date de livraison du bâtiment',
    RDT: 'Date de réception définitive des travaux',
    DGPA: 'Date de fin de la garantie de parfait achèvement',
    DGD: 'Date de fin de la garantie décennale',
    OTHER: 'Autre',
  },
  COSTS: {
    CTP: 'Coût total du projet (€HT)',
    CTC: 'Coût total de la conception',
    CTT: 'Coût total des travaux',
    OTHER: 'Autre',
  },
  CONTRIBUTOR_CODE_LABEL: {
    CA: "Cabinet d'Architecture",
    BESC: "Bureau d'étude spécialisé en conception",
    EC: 'Entreprise contractante',
    EST: 'Entreprise sous-traitante',
    CDC: 'Cabinet de conseil',
    EDC: 'Entreprise de construction',
    CGA: 'Cabinet de gestion d’actif',
    SDP: 'Société de promotion',
    FDB: 'Foncière de bâtiment',
    SDM: 'Société de maintenance',
    ULE: 'Usager/Locataire/Exploitant',
    SDC: 'Société de conseil',
    OPC: 'Société de conduite de projet',
    OTHER: 'Autre',
  },
};

const SEPARATOR_OPTIONS = [
  {
    label: 'Tiret bas ( _ )',
    key: '_',
  },
  {
    label: 'Trait d’union ( - )',
    key: '-',
  },
];

const UPLOAD_STEPS = {
  SELECT_DOCUMENTS_STEP: 0,
  SELECT_VISIBILITY_AND_METADATA: 1,
  VALIDATE_SUMMARY_STEP: 2,
};

const VIEWER_CONTROL_PANELS = [
  {
    key: 'models',
    title: 'Maquettes agrégées',
    xPosition: 50,
    yPosition: 50,
    icon: 'assemble',
    iconViewport: 1090,
    iconSize: 32,
  },
  {
    key: 'extractedData',
    title: 'Données extraites',
    xPosition: 50,
    yPosition: 100,
    icon: 'hierarchy',
    iconSize: 26,
  },
  {
    key: 'property',
    title: 'Propriétés',
    xPosition: 50,
    yPosition: 150,
    icon: 'label',
    iconSize: 26,
  },
  {
    key: 'options',
    title: 'options',
    xPosition: 50,
    yPosition: 200,
    icon: 'gear',
    iconSize: 26,
  },
];

const METADATA = {
  TYPE: {
    TYPE: 'TYP',
    BATCH: 'LOT',
    LEVEL: 'NIV',
    ZONE: 'BZO',
    SPECIALITY: 'SPE',
    EMITTER: 'EMT',
    PHASE: 'PHS',
  },
  NAME: {
    TYP: 'Type',
    LOT: 'Lot',
    NIV: 'Niveau',
    BZO: 'Bâtiment-Zone',
    SPE: 'Spécialité',
    EMT: 'Emetteur',
    PHS: 'Phase',
  },
  ORDER: ['PHS', 'EMT', 'SPE', 'BZO', 'NIV', 'LOT', 'TYP'],
  UPLOAD: ['TYP', 'EMT', 'SPE', 'LOT', 'BZO', 'NIV', 'PHS'],
};

const FORM = {
  MIN_LENGTH_2: 2,
  MAX_LENGTH_3: 3,
  MAX_LENGTH_9: 9,
  MAX_LENGTH_10: 10,
  MAX_LENGTH_28: 28,
  MAX_LENGTH_50: 50,
  MAX_LENGTH_100: 100,
  MAX_LENGTH_255: 255,
  MAX_LENGTH_500: 500,
  MAX_LENGTH_3000: 3000,
  MAX_LENGTH_10000: 10000,
};

const SYSTEM_GROUPS = {
  SELF: { name: 'Uniquement moi-même' },
};

const VISIBILITY_LABELS = {
  PUBLIC: 'Tous les utilisateurs du projet',
  SELF: 'Uniquement le propriétaire du document',
};

const MAX_FOLDERS_VISIBILITY = 20;

const PAGE_SIZE_OPTIONS = [
  {
    label: '20 éléments',
    key: 20,
  },
  {
    label: '30 éléments',
    key: 30,
  },
  {
    label: '50 éléments',
    key: 50,
  },
  {
    label: '100 éléments',
    key: 100,
  },
];

const PROJECT_STATUS = [
  {
    label: 'Non défini',
    value: 0,
    color: 'default',
  },
  {
    label: 'Non démarré',
    value: 1,
    color: 'default',
  },
  {
    label: 'Dans les délais',
    value: 2,
    color: 'success',
  },
  {
    label: 'A risque',
    value: 3,
    color: 'warning',
  },
  {
    label: 'En retard',
    value: 4,
    color: 'error',
  },
  {
    label: 'En avance',
    value: 5,
    color: 'success',
  },
  {
    label: 'En attente',
    value: 6,
    color: 'warning',
  },
  {
    label: 'Terminé',
    value: 7,
    color: 'success',
  },
];

const EDM_MODALS = {
  DOCUMENT_EDIT: 'DOCUMENT_EDIT',
  DOCUMENT_ARCHIVE: 'DOCUMENT_ARCHIVE',
  DOCUMENT_DELETE: 'DOCUMENT_DELETE',
  DOCUMENT_LINK_TO_FOLDER: 'DOCUMENT_LINK_TO_FOLDER',
  DOCUMENT_LINK_TO_ASSEMBLY: 'DOCUMENT_LINK_TO_ASSEMBLY',
  DOCUMENT_EDIT_REVISION: 'DOCUMENT_EDIT_REVISION',
  DOCUMENT_EDIT_VISIBILITY: 'DOCUMENT_EDIT_VISIBILITY',
  DOCUMENTS_EDIT_VISIBILITY: 'DOCUMENTS_EDIT_VISIBILITY',
  DOCUMENT_MAIL_CONTEXT: 'DOCUMENT_MAIL_CONTEXT',
  DOCUMENT_EDIT_METADATA: 'DOCUMENT_EDIT_METADATA',
  DOCUMENTS_EDIT_METADATA: 'DOCUMENTS_EDIT_METADATA',
  DOCUMENT_REVIEW: 'DOCUMENT_REVIEW',
  DOCUMENT_EXPORT_IFC_DATA: 'DOCUMENT_EXPORT_IFC_DATA',
  DOCUMENT_IMPORT_IFC_DATA: 'DOCUMENT_IMPORT_IFC_DATA',
  DOCUMENT_COPY_TO: 'DOCUMENT_COPY_TO',
};

const PROJECT_MODALS = {
  PROJECT_INFO: 'PROJECT_INFO',
  PROJECT_ARCHIVE: 'PROJECT_ARCHIVE',
  DOCUMENT_PREVIEW: 'DOCUMENT_PREVIEW',
};

const UPLOAD_MODALS = {
  UPLOAD_FILE_EDIT_VISIBILITY: 'UPLOAD_FILE_EDIT_VISIBILITY',
  UPLOAD_FILES_EDIT_VISIBILITY: 'UPLOAD_FILES_EDIT_VISIBILITY',
  UPLOAD_FILES_EDIT_METADATA: 'UPLOAD_FILES_EDIT_METADATA',
  UPLOAD_FILES_REMOVE: 'UPLOAD_FILES_REMOVE',
  UPLOAD_HOLDER_EDIT_VISIBILITY: 'UPLOAD_HOLDER_EDIT_VISIBILITY',
  UPLOAD_HOLDERS_EDIT_VISIBILITY: 'UPLOAD_HOLDERS_EDIT_VISIBILITY',
  UPLOAD_HOLDERS_EDIT_METADATA: 'UPLOAD_HOLDERS_EDIT_METADATA',
  UPLOAD_HOLDERS_REMOVE: 'UPLOAD_HOLDERS_REMOVE',
};

const FOLDER_MODALS = {
  FOLDER_RENAME: 'FOLDER_RENAME',
  FOLDER_CREATE: 'FOLDER_CREATE',
  FOLDER_DELETE: 'FOLDER_DELETE',
};

const USER_MODALS = {
  CREATE_ORGANIZATION_USER: 'CREATE_ORGANIZATION_USER',
  UPDATE_ORGANIZATION_USERS_ROLE: 'UPDATE_ORGANIZATION_USERS_ROLE',
  REMOVE_ORGANIZATION_USERS: 'REMOVE_ORGANIZATION_USERS',
  GROUP_RENAME: 'GROUP_RENAME',
  GROUP_CREATE: 'GROUP_CREATE',
  GROUP_DELETE: 'GROUP_DELETE',
  GROUP_USERLIST: 'GROUP_USERLIST',
  CREATE_PROJECT_USER: 'CREATE_PROJECT_USER',
  UPDATE_PROJECT_USERS_ROLE: 'UPDATE_PROJECT_USERS_ROLE',
  REMOVE_PROJECT_USERS: 'REMOVE_PROJECT_USERS',
};

const PROJECT_CONFIGURATION_MODALS = {
  LINK_DELETE: 'LINK_DELETE',
};

const METADATA_MODALS = {
  METADATA_UPDATE: 'METADATA_UPDATE',
  METADATA_CREATE: 'METADATA_CREATE',
  METADATA_DELETE: 'METADATA_DELETE',
};

const HIDE_CRUMBS = {
  LAST: 0,
  ALL: 1,
};

const ACTIVATION_STEPS = {
  EDIT_PASSWORD: 0,
  EDIT_PROFILE: 1,
};

const SESSION_STATES = {
  IDLE: 'IDLE',
  REVOKED: 'REVOKED',
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
  RESET: 'RESET',
  ACTIVATED: 'ACTIVATED',
  COMPLETED: 'COMPLETED',
};

const ROLES = {
  PLATFORM_ADMIN: 'Administrateur',
  PLATFORM_MEMBER: 'Membre',
  PROJECT_ADMIN: 'Administrateur',
  PROJECT_MANAGER: 'Chef de projet',
  PROJECT_CONTRIBUTOR: 'Collaborateur',
  PROJECT_VIEWER: 'Observateur',
  PROJECT_CANDIDATE: 'Candidat',
  PROJECT_MODEL_MANAGER: 'Gestionnaire maquettes',
};

const USER_STATES = {
  INVITED: 'Invité',
  INACTIVE: 'Inactif',
  ACTIVE: 'Actif',
};

const MAX_VISIBILITY_GROUPS = 20;

const MODEL_REQUEST_STATUS = {
  WIP: 0,
  DONE: 1,
  ERROR: 2,
};

export default {
  COMMON_EXTENSIONS,
  ACTIONS,
  ARTICLE_EXTRACTION_STATES,
  MESSAGE_LEVELS,
  SNACKBAR_TITLE,
  MAX_UPLOAD_FILESIZE,
  FORM,
  METADATA,
  PROJECTS,
  UPLOAD_LIMIT,
  UPLOAD_STEPS,
  SEPARATOR_OPTIONS,
  VIEWER_CONTROL_PANELS,
  LOCALE: 'fr-FR',
  TIMEZONE: 'Europe/Paris',
  SYSTEM_GROUPS,
  VISIBILITY_LABELS,
  MAX_FOLDERS_VISIBILITY,
  PAGE_SIZE_OPTIONS,
  PROJECT_STATUS,
  EDM_MODALS,
  UPLOAD_MODALS,
  PROJECT_MODALS,
  FOLDER_MODALS,
  PROJECT_CONFIGURATION_MODALS,
  USER_MODALS,
  METADATA_MODALS,
  HIDE_CRUMBS,
  ACTIVATION_STEPS,
  SESSION_STATES,
  ROLES,
  USER_STATES,
  MAX_VISIBILITY_GROUPS,
  MODEL_REQUEST_STATUS,
};
