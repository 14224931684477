import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export default function useInfiniteScroll({ hasMore, reset }) {
  const scrollContainerRef = useRef();
  const loaderRef = useRef();
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (reset) {
      setPage(0);
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [reset]);

  useLayoutEffect(() => {
    const loaderNode = loaderRef.current;
    const scrollContainerNode = scrollContainerRef.current;
    if (!scrollContainerNode || !loaderNode || !hasMore) {
      return () => {};
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(({ isIntersecting }) => {
          if (isIntersecting) {
            setPage((currentPage) => currentPage + 1);
          }
        });
      },
      {
        root: scrollContainerNode,
        rootMargin: `30px 0px`,
      }
    );
    observer.observe(loaderRef.current);

    return () => {
      observer.disconnect();
    };
  }, [hasMore, page]);

  return [page, loaderRef, scrollContainerRef];
}
